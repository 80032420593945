import React, { useEffect, useState } from "react";
import {
  GetQuoteButton,
  HeroCallStyledText,
  HeroCallText,
  HeroContainer,
  HeroLeft,
  HeroMainWorker,
  HeroMan,
  HeroPopularSearch,
  HeroPopularSearchItem,
  HeroQuoteBtnContainer,
  HeroRight,
  HeroServiceSearch,
  HeroServiceSearchBtn,
  HeroServiceSearchInput,
  HeroServiceSearchLocation,
  HeroTrustPilot,
  HeroWorkerBg,
  HeroWorkerBtn,
  HeroWorkerInfo,
  HeroWorkerInfoContainer,
  HeroWorkerRating,
  HeroWorkerTop,
  // HeroWorkersBox,
  // ScrollLeft,
  // ScrollRight,
} from "./HeroStyles";
import {
  Div,
  // Image,
  // Paragraph,
  // SearchIcon,
  heroMan,
  heroTextUnderline,
  heroWorkerBg,
  // trustPilot,
  // worker,
} from "assets";
import {
  MdArrowRightAlt,
  // MdLocationOn,
  // MdSearch,
  MdChevronRight,
  MdChevronLeft,
} from "react-icons/md";
import { ReactComponent as TrustPilotIcon } from "assets/svg/trustPilotIcon.svg";
import { RiVerifiedBadgeFill } from "react-icons/ri";
// import classNames from "classnames";
import {
  Link,
  // useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button } from "ui/button";
// import styles from "./hero.module.css";
// import { Input } from "ui/input";
import { CategoriesSort } from "ui/categoriesSort";
// import { Autocomplete, TextField } from "@mui/material";
// import { otherServices } from "../OtherServices/data";
import { debounce } from "lodash";
import axios from "axios";
import { AutoSearch } from "./AutoSearch";
import { useQuery } from "@tanstack/react-query";
import { getSkill } from "services";
import { getCurrentLocation } from "shared/getLocation";

export const HeroSection = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState("hire-a-pro");
  const [searchParams] = useSearchParams();
  const [term, setTerm] = useState("");
  const [location, setLocation] = useState({geometry: {coordinates: []}})
  const [service, setService] = useState({name: ""})
  const [searchResults, setSearchResults] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("")
  const [currentLocation, setCurrentLocation] = useState<{ latitude: any; longitude: any } | null>({
    latitude: '',
    longitude: '',
  });
  const [error, setError] = useState<{message: string}>();

  useEffect(() => {
    // Debounce the search function to reduce API calls
    const delayedSearch = debounce(() => {
      if (searchValue.trim() !== "") {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          searchValue,
        )}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}`;

        axios.get(url)
          .then((response: any) => {
            setSearchResults(response.data.features);
          })
          .catch((error: any) => {
            console?.error(error);
          });
      } else {
        setSearchResults([]);
      }
    }, 300);

    delayedSearch();

    // Clean up the debounce function on unmount
    return () => delayedSearch.cancel();
  }, [searchValue]);


  const { data, refetch } = useQuery({
    queryKey: ["service"],
    queryFn: () => getSkill(term),
    enabled: !!term,
  });

  useEffect(() => {
    const delayedSearch = debounce(() => { refetch() }, 600)
    delayedSearch()
    return () => delayedSearch.cancel()
  }, [term, refetch]);

  
  useEffect(() => {
      getCurrentLocation()
      .then((location) => { 
         setCurrentLocation({latitude: location.latitude, longitude: location.longitude})
      })
      .catch(error => setError(error));
  }, []);

  const handleSearch = () => {
    // const navigate = useNavigate();
    // const [searchParams, setSearchParams] = useSearchParams();
    const searchTerm = service && service?.name ?  service?.name : term;
    let longitude: number | undefined;
    let latitude: number | undefined;
    if (location?.geometry?.coordinates.length > 0) {
      longitude = location.geometry.coordinates[0];
      latitude = location.geometry.coordinates[1];
    } else if (currentLocation) {
      longitude = currentLocation.longitude;
      latitude = currentLocation.latitude;
    } else {
      alert('Please select location');
      longitude = undefined;
      latitude = undefined;
      return;
    }
    navigate(`/search?searchTerm=${searchTerm}&long=${longitude}&lat=${latitude}`)
  };

  const handleChange = (term: string) => {
    setTerm(term);
  };

  const handleLocationChange = (_: any, value: any) => {
    setLocation(value);
  };

  const handleServiceSelect = (_: any, value: any) => {
    setService(value)
  }

  return (
    <section
      className={`w-full h-fit bg-gradient-to-r from-[#030817] to-[#312E81] via-[#030817]`}
    >
      <div className="container w-full grid mt-[30px]  grid-cols-5 lg:mt-[60px]">
        <div className=" bg-[#030817]  pt-16 pb-8 xlg:px-0 px-6  justify-end text-white  col-span-5 lg:col-span-3 flex ">
          {/* <div className="w-full flex max-w-[715px] "> */}
          <div className="flex flex-col w-full gap-8 ">
            <HeroQuoteBtnContainer>
              <GetQuoteButton>
                <Link to="/add-listing" className="text-sm lg:text-base">
                  {" "}
                  Skip The Search and Let Providers Reach Out to You!
                </Link>
                &nbsp; <MdArrowRightAlt />{" "}
              </GetQuoteButton>
            </HeroQuoteBtnContainer>
            <div className="lg:leading-[60px]">
              <span className="md:text-[57px]  text-[30px] font-extrabold">
              Connecting You with  {" "}
              </span>
              <span className="relative ">
                <span className=" inline-flex md:text-[57px] text-[30px] font-extrabold">
                Trusted Experts {" "}
                </span>
                <img src={heroTextUnderline} className="absolute left-0 top-5" />
              </span>
              <br/>
              <span className=" inline-flex md:text-[57px] text-[30px] font-extrabold">
                {" "}
                for Every Task
              </span>
            </div>
            <div className="flex gap-4">
              <p
                className={`border-b cursor-pointer uppercase ${tab === "hire-a-pro" ? "border-blue-50 text-[#4188F9]" : "border-transparent"}  p-2`}
                onClick={() => setTab("hire-a-pro")}
              >
                Hire a pro
              </p>
              <p
                className={`border-b cursor-pointer uppercase ${tab === "find-customer" ? "border-blue-50 text-[#4188F9]" : "border-transparent"}  p-2`}
                onClick={() => setTab("find-customer")}
              >
                Find customers
              </p>
            </div>
      
            {tab === "hire-a-pro" ? (
              <React.Fragment>
              <div className="hidden lg:flex flex-col gap-3  lg:w-fit">
                <div className="group mt-2 flex h-[53px] items-center gap-2 rounded-[5px] border border-white bg-[#0F172A]  px-[10px] lg:w-full xlg:w-[600px]">
                  <div className="flex items-center h-full gap-2 w-[600px]">
                    <img className="w-6 h-6" src="/location.png" alt="" />
                    <AutoSearch labelStyles={{top: '-0.5rem'}} searchResult={searchResults || []} handleSelect={handleLocationChange} getOptionLabel={(option: any) => option?.place_name as string} handleType={setSearchValue} label={"Location"} />
                  </div>
                  <AutoSearch labelStyles={{top: '-0.5rem'}} searchResult={data?.results || []} handleSelect={handleServiceSelect} getOptionLabel={(option: any) => option.name as string} handleType={handleChange} label={"What service are you looking for today?....."} />
                  <span
                    onClick={handleSearch}
                    className="flex items-center p-4 bg-blue-600 rounded-lg cursor-pointer justify-self-end"
                  >
                    <img src="/search-normal.png" alt="" />
                  </span>
                </div>
                <div className="flex-wrap items-center hidden lg:flex">
                  <span className="mr-3 text-xs flex-wrap lg:flex-nowrap whitespace-nowrap lg:mb-1">
                    Popular searches:
                  </span>
                  <div className="hideScrollBar mx-[-25px] mt-1 lg:mx-0 h-full flex items-center overflow-x-auto  max-w-lg  ">
                    <div>
                      <CategoriesSort
                        items={[
                          {
                            name: "Plumbing",
                          },
                          {
                            name: "Domestic Service",
                          },
                          {
                            name: "Electrical Work",
                          },
                        ]}
                        filters={[]}
                        setFilters={() => ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-3 mt-8">
                  <div className="flex items-center gap-2">
                    <TrustPilotIcon />
                    <span>Trusted by thousands of users</span>
                  </div>
                  {/* <span className="sm">Verify by trust pilot</span> */}
                </div>
              </div>
            <div className="lg:hidden">
              <div className="flex items-center gap-2 border border-white pl-2 bg-[#0F172A] mb-4">
                <img className="w-6 h-6" src="/location.png" alt="" />
                <AutoSearch labelStyles={{top: '-0.5rem'}} searchResult={searchResults || []} handleSelect={handleLocationChange} getOptionLabel={(option: any) => option?.place_name as string} handleType={setSearchValue} label={"Location"} />
              </div>
              <div className="flex items-center gap-2 border border-white  bg-[#0F172A]  my-4">
              <AutoSearch labelStyles={{top: '-0.5rem'}}  searchResult={data?.results || []} handleSelect={handleServiceSelect} getOptionLabel={(option: any) => option?.name as string} handleType={handleChange} label={"What service are you looking for today?....."} />
              </div>
              <Button className="w-full mt-3" onClick={handleSearch}>Search</Button>
            </div>
              </React.Fragment>
              
            ) : (
              <div className="flex flex-col items-start gap-4 ">
                <p>Meet new customers</p>
                <p>Sign up to start growing your business</p>
                <Button onClick={() => navigate("/provider")}>
                  Get Started
                </Button>
              </div>
            )}
          </div>
          {/* </div> */}
        </div>
        <div className=" text-white bg-[#312E81]  py-8 lg:bg-inherit lg:pl-10 w-full col-span-5 lg:col-span-2">
          <div className="flex items-center justify-center w-full gap-0 px-6 h-fit lg:pt-10 lg:px-30 md:gap-10 lg:gap-6">
            <div className=" w-[clamp(30px_8vw_30px)] bg-white text-black rounded-full">
              <MdChevronLeft />
            </div>
            <div>
              <div className="relative m-auto lg:w-[380px] lg:h-[400px] w-[330px] h-[380px]">
                <img
                  className="absolute lg:w-[380px] lg:h-[400px] w-[330px] h-[380px]"
                  src={heroWorkerBg}
                  alt=""
                />
                <HeroWorkerTop>Bring me your challenges</HeroWorkerTop>
                <HeroMan src={heroMan} />
                <HeroWorkerInfoContainer>
                  <Div>
                    <HeroWorkerInfo>Usman Zafar</HeroWorkerInfo>
                    <HeroWorkerRating>
                      <RiVerifiedBadgeFill />
                      Top rated Carpenter
                    </HeroWorkerRating>
                  </Div>
                  <HeroWorkerBtn>Hire me</HeroWorkerBtn>
                </HeroWorkerInfoContainer>
              </div>
            </div>
            <div className=" w-[clamp(30px_8vw_30px)] bg-white text-black rounded-full">
              <MdChevronRight />
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </section>
  );
};
