import React from "react";
import CustomAvatar from "./CustomAvatar";

interface Sender {
  profile_picture: string;
  fullname: string;
  firstname: string;
  lastname: string;
}
const BuyerChatBox = ({
  sender,
  textContent,
  timestamp,
}: {
  timestamp: string;
  sender: Sender;
  textContent: string;
}) => {
  return (
    <div className="flex flex-col mt-2 px-4">
      <div className="flex items-start gap-2 bg-accents-100  justify-end">
        <div className="space-y-1">
        <div className="text-left flex justify-start font-medium text-xs lg:text-sm gap-2 ">
            <span className="font-bold text-xs lg:text-sm">Me</span>
            <span  className="text-[#64748B] ">{timestamp}</span>
          </div>
          <p className="  text-xs lg:text-sm">{textContent}</p>
         
        </div>
        <CustomAvatar src={sender?.profile_picture} username={sender.firstname + ' ' + sender.lastname} />
      </div>
    </div>
  );
};

export default BuyerChatBox;
