/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";
import {
  checkIfFilesAreTooBig,
  checkIfImagesAreCorrectType,
} from "utils/helper";
import { notify } from "shared/notifications/notify";
import { SliderWrapper } from "./sliderWrapper";
import { DeleteIcon, Plus, PlusCircle, PlusIcon, Trash2 } from "lucide-react";

type Props = {
  name: string;
  id: string;
  snapType: string;
  variant?: "type1" | "type2" | "type3";
  isMultiple?: boolean;
  uploadedPreviews?: string[];
  productId?: string;
  previewUrl?: string[];
  slidesToShow?: number;
  clearFiles?: boolean;
  slidesToScroll?: number
};

function FileUploader({
  name,
  id,
  snapType,
  variant = "type1",
  isMultiple = false,
  uploadedPreviews = [],
  clearFiles,
  previewUrl,
}: Props) {
  const { register, setValue } = useFormContext();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  // State to store the selected files
  const [files, setFiles] = useState<File[]>(() => []);
  // State to store the previews of selected files
  const [previews, setPreviews] = useState<string[]>(() => previewUrl ?? []);

  useEffect(() => {
    if (clearFiles) {
      setFiles([]);
      setPreviews([]);
      setValue(name, []);
    }
  }, [clearFiles, setValue, name]);

  useEffect(() => {
    // Ensure the form's default values are set up correctly
    setValue(name, []);
  }, [setValue, name]);

  // Effect to generate previews for selected files
  useEffect(() => {
    if (files.length === 0){ 
      setPreviews([])
      return
    };

    const newPreviews = files.map((file) => URL.createObjectURL(file));
    setPreviews(newPreviews);

    // Cleanup function to revoke object URLs and avoid memory leaks
    return () => {
      newPreviews.forEach((preview) => URL.revokeObjectURL(preview));
    };
  }, [files]);

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    const newFiles = event.target.files ? Array.from(event.target.files) : [];
    if (!checkIfImagesAreCorrectType(newFiles)) {
      notify.error({
        message: "Only images(png,jpg,jpeg) are allowed",
        type: "error",
      });
      return;
    }
    if (!checkIfFilesAreTooBig(newFiles, 2)) {
      notify.error({
        message: "File must be at most 2mb",
        type: "error",
      });
      return;
    }
    const combinedFiles = [...files, ...newFiles];
  if (combinedFiles.length > 5) {
    notify.error({
      message: "You can only upload up to 5 files",
      type: "error",
    });
    return;
  }
  setValue(name, combinedFiles);
  setFiles(combinedFiles);
    return;
  };

  const handleDelete = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    setValue(name, updatedFiles);
  };
  // Helper function to render the file upload UI based on the current state
  const renderUploadUI = () => {
    const renderSliderWrapper = (previews: any) => (
      <SliderWrapper
        slides={previews.map((preview: any, index:any) => (
          <div
            key={index}
            className="relative isolate flex h-full min-h-[200px] w-full items-center justify-center rounded-md"
          >
            <span className="w-fit rounded-full bg-white p-2 absolute top-[45%] transition-all duration-300 ease-in-out hover:scale-[1.2]">
              <PlusCircle />
            </span>
            <img
              src={preview}
              alt={`Preview ${index + 1}`}
              className="z-[-1] rounded-[inherit] object-cover"
            />
          </div>
        ))}
      />
    );
    const renderSliderWrapperCustom = (previews: any) => {
     return  (
      <div className="slider-wrapper-custom grid gap-3 grid-cols-2 lg:grid-cols-4">
      {previews.map((preview: any, index: any) => (
        <div
          key={index}
          className="relative isolate flex h-full w-full bg-slate-300 p-4 items-center justify-center rounded-md"
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <span className="w-fit rounded-full bg-white p-2 absolute top-[45%] transition-all duration-300 ease-in-out hover:scale-[1.2]">
            <PlusCircle />
          </span>
          <img
            src={preview}
            alt={`Preview ${index + 1}`}
            className="z-[-1] rounded-[inherit] bg-slate-300 object-cover"
          />
          {hoveredIndex === index && (
            <button
              type="button"
              className="absolute top-2 right-2 bg-white p-1 rounded-full shadow-md hover:bg-red-500 hover:text-white transition-all duration-300 ease-in-out"
              onClick={() => handleDelete(index)}
            >
              <Trash2 />
            </button>
          )}
        </div>
      ))}
    </div>
     )
      };

    const renderSinglePreview = () => (
      <div className="relative h-full w-full rounded-[inherit]">
        {previews[0] || previewUrl ? (
          <div className="h-full w-full rounded-[inherit]">
            <img
              src={(previews[0] as string) || (previewUrl as string[])[0]}
              className="rounded-[inherit] object-cover"
              alt="Captured"
            />
            <div className="absolute top-1/2 flex h-full w-full -translate-y-1/2 items-center justify-center rounded-[inherit] bg-black/40">
              <div className="flex items-center gap-2 up-btn">
                <small className="text-white">
                  Re-Upload document {snapType}
                </small>
                <span>upload icon</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex w-full rounded-[inherit]">
            <span className="text-sm text-white">{files[0]?.name}</span>
            <div className="absolute top-1/2 flex h-full w-full -translate-y-1/2 items-center justify-center rounded-[inherit] bg-black/40">
              <div className="flex items-center gap-2 up-btn">
                <small className="text-white">
                  Re-Upload document {snapType}
                </small>
                <span>upload icon</span>
              </div>
            </div>
          </div>
        )}
      </div>
    );

    const renderInitialUploadUI = () => (
      variant === "type2" || variant === "type3" ? (
        <div className="up-btn border border-dashed border-[#94A3B8] flex w-full items-center justify-center rounded-[inherit] bg-[#F1F5F9] h-full min-h-[200px]">
          <div className="flex flex-col items-center w-full gap-2 up-btn">
            <div className="w-[clamp(80px_8vw_80px)] rounded-full bg-white p-2 transition-all duration-300 ease-in-out hover:scale-[1.2]">
              <Plus />
            </div>
            <div className="flex flex-col items-center">
              <span className="text-sm font-bold">Upload Image</span>
              <small className="text-[#808080]">upto 5mb {snapType}</small>
            </div>
            <img
              src={"/Frontend/audtik-frontend/src/assets/images/blogimg.png"}
              className="z-[-1] rounded-[inherit]"
              alt="Captured"
            />
          </div>
        </div>
      ) : (
        <div className="up-btn flex h-full w-full items-center justify-center rounded-[inherit] bg-[rgba(222,242,251,0.3)]">
          <div className="flex items-center gap-2 up-btn">
            <small className="text-[#808080]">Upload document {snapType}</small>
            <span>upload icon</span>
          </div>
        </div>
      )
    );


    if (previews.length > 0) {
    if (isMultiple && variant === "type2") {
      return renderSliderWrapper(previews);
    } else if (isMultiple && variant === "type3") {
      return renderSliderWrapperCustom(previews);
    } else {
      return renderSinglePreview();
    }
  } else {
    return renderInitialUploadUI();
  }
  };

  
  // const handleDelete = async () => {
  //   if (s3Path === "") notify.error({ message: "Invalid s3path path" });
  //   setDeleting(true);
  //   console.log(uploadedPreviews.filter((p) => p !== s3Path));
  //   try {
  //     await deleteImageSingle(s3Path);
  //     await updateProduct({
  //       id: productId ?? "",
  //       data: { pictures: [...uploadedPreviews.filter((p) => p !== s3Path)] },
  //     });
  //     queryClient.invalidateQueries({
  //       queryKey: [QUERY_KEYS.PRODUCT],
  //     });
  //     notify.success({ message: "image deleted successfully" });
  //     setShowModal(false);
  //   } catch (error) {
  //     notify.error({
  //       message: "Unable to delete image",
  //     });
  //   } finally {
  //     setDeleting(false);
  //   }
  // };
  return (
    <>
      <div
        className={classNames(
          "mt-3 min-h-[96px] h-full w-full  rounded-md border border-dashed border-[#272424]",
          variant === "type2" && "border-0",
        )}
      >
        <input
          {...register(name)}
          type="file"
          accept="image/*"
          onChange={handleFileInputChange}
          className="hidden"
          multiple={isMultiple}
          id={id}
        />

        {uploadedPreviews?.length > 0 ? (
          <div className="h-full w-full rounded-[inherit]">
            <SliderWrapper
              slides={[
                ...uploadedPreviews.map((preview, index, array) => (
                  <div
                    key={Math.random()}
                    className="relative isolate flex h-[372px] w-full items-center justify-center rounded-md"
                  >
                    {array.length < 2 ? (
                      ""
                    ) : (
                      <button
                        onClick={() => {
                          // setS3Path(preview);
                          // return setShowModal(true);
                        }}
                        type="button"
                        className="  cursor-pointer w-[100px] h-[200px] rounded-full bg-red-800 p-2 transition-all duration-300 ease-in-out hover:scale-[1.2]"
                      >
                        {/* delete icon */}
                        <DeleteIcon />
                      </button>
                    )}
                    <img
                      src={preview}
                      alt={`Preview ${index + 1}`}
                      // layout="fill"
                      className="z-[-1] rounded-[inherit] object-cover"
                    />
                  </div>
                )),
                ...previews.map((preview, index) => (
                  <label
                    htmlFor={id}
                    key={preview}
                    className="relative h-[372px] w-full cursor-pointer rounded-[inherit]  "
                  >
                    <div
                      key={Math.random()}
                      className="relative isolate flex h-[372px] w-full items-center justify-center rounded-md"
                    >
                      <span className=" w-fit rounded-full bg-white p-2  transition-all duration-300 ease-in-out hover:scale-[1.2]">
                        {/* plus icon */} <PlusIcon />
                      </span>
                      <img
                        src={
                          "/Frontend/audtik-frontend/src/assets/images/blogimg.png"
                        }
                        alt={`Preview ${index + 1}`}
                        // layout="fill"
                        className="z-[-1] rounded-[inherit] object-cover"
                        // unoptimized
                      />
                    </div>
                  </label>
                )),
                ...Array(1)
                  .fill(0)
                  .map((i) => (
                    <label
                      htmlFor={id}
                      key={Math.random() * 3}
                      className=" relative h-full w-full cursor-pointer rounded-[inherit]  bg-red-600"
                    >
                      <div className="relative isolate flex h-[372px] w-full items-center justify-center  rounded-[inherit]">
                        <span className=" w-fit rounded-full bg-white p-2  transition-all duration-300 ease-in-out hover:scale-[1.2]">
                          [plus icon]
                        </span>
                        <img
                          src={
                            "/Frontend/audtik-frontend/src/assets/images/blogimg.png"
                          }
                          // fill
                          className="z-[-1] rounded-[inherit]  "
                          alt="Captured"
                          // unoptimized
                        />
                      </div>
                    </label>
                  )),
              ]}
            />
          </div>
        ) : (
          <label
            htmlFor={id}
            className="relative h-full flex items-center  w-full cursor-pointer rounded-[inherit]"
          >
            {renderUploadUI()}
          </label>
        )}
      </div>
      
    </>
  );
}

export default FileUploader;
