import dayjs from "dayjs";
export function checkIfImagesAreCorrectType(files: File[]): boolean {
  let valid = true;
  if (files[0]) {
    const file = files[0];
    if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
      valid = false;
    }
  }
  return valid;
}
export function checkIfFilesAreTooBig(files: File[], limit: number): boolean {
  let valid = true;
  if (files[0]) {
    const file = files[0];
    const sizeInMB = file.size / 1024 / 1024; // Convert size to megabytes
    if (sizeInMB > limit) {
      valid = false;
    }
  }
  return valid;
}

export const formatTime = (date: string) => {
  return dayjs(date).format("h:mm:ss A");
};

export const formatDate = (date: string) => {
  return dayjs(date).format('MMM DD, YYYY, h:mm A');
}

export const sumRatings = (ratings: { [key: string]: number }): number => {
  if (!ratings) {
    return 0;
  }
  return Object.values(ratings).reduce((sum, value) => sum + value, 0);
};


export const calculateAverageRating = (ratings: { [s: string]: number }) => {
   if (!ratings) {
    return 0;
   }
 
   let totalRatings = 0;
   let totalCount = 0;

   for (const [key, count] of Object.entries(ratings)) {
       const ratingMap: { [key: string]: number } = { one: 1, two: 2, three: 3, four: 4, five: 5 };
       const ratingValue = ratingMap[key.split('_')[0]] || 0;
       totalRatings += ratingValue * count;
       totalCount += count;
   }

   // Calculate the average
   const averageRating = totalRatings / totalCount;
    return  parseFloat(averageRating.toFixed(2));
};
