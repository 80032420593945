export const maskPhoneNumber = (phoneNumber: string) => {
  // Remove any non-digit characters from the input
  const cleaned = phoneNumber?.replace(/\D/g, '');

  // Check if the cleaned number has at least 10 digits
  if (cleaned?.length < 10) {
      throw new Error('Invalid phone number. Please provide at least 10 digits.');
  }

  // Extract the first three digits and mask the rest
  const areaCode = cleaned?.slice(0, 3);
  const maskedPart = 'XXX-XXXX';

  // Return the formatted masked phone number
  return `${areaCode}-${maskedPart}`;
}

export const convertToMiles = (meters: number) => {
  if (typeof meters !== 'number' || meters < 0) {
      throw new Error('Input must be a non-negative number');
  }
  const miles = meters / 1609.34;
  return miles;
}