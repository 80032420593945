/* eslint-disable camelcase */
import { useQueryClient } from "@tanstack/react-query";
import { SettingsLayout } from "context/settings/layout/SettingsLayout";
import dayjs from "dayjs";
import { useAuthenticatedUser } from "hooks";
import {
  useDeleteAllNotification,
  useDeleteNotification,
  useGetNotifications,
  useMarkAsRead,
} from "hooks/notifications/useNotifications";
import { Trash2 } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { notify } from "shared";

export const Notification = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { userDetails } = useAuthenticatedUser();
  // const [params, setParams] = React.useState<any>({
  //   is_read: "",
  // });
  const { data, refetch } = useGetNotifications({
    is_read: "",
  });
  const { markAsRead } = useMarkAsRead();
  const { deleteNotification } = useDeleteNotification();
  const { deleteAllNotification } = useDeleteAllNotification();

  const handleMarkNotification = async (item: any) => {
    if (item?.notification_type === "LEAD") {
      navigate(`/leads`);
    } else if (item?.notification_type === "REVIEW") {
      navigate(`/provider/${userDetails?.data?.service_profile}`);
    } else if (item?.notification_type === "CHAT") {
      navigate(`/chat`);
    } else if (item?.notification_type === "JOB_CREATION") {
      navigate(`/listings`);
    }
    const response = await markAsRead({
      id: item?.id,
    });

    if (response?.success === true) {
      await refetch();
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
      });
      // window.location.href = response?.data;
    } else if (response?.success === false) {
      notify.error({
        message: "Error ",
        subtitle: response?.error,
      });
    }
  };

  const handleDeleteNotification = async (item: any) => {
    const response = await deleteNotification({
      id: item?.id,
    });
    if (response?.status === 200 || response?.status === 204) {
      await refetch();
      notify.success({
        message: "Success ",
        subtitle: "Notification Deleted Successfully",
      });
    } else if (response?.status === 400) {
      notify.error({
        message: "Error ",
        subtitle: "Error Deleting Notification",
      });
    }
  };

  const handleDeleteAllNotification = async () => {
    const response = await deleteAllNotification();
    if (response?.success === true) {
      await refetch();
      notify.success({
        message: "Success ",
        subtitle: response?.message,
      });
    } else if (response?.success === false) {
      notify.error({
        message: "Error ",
        subtitle: response?.error,
      });
    }
  };

  return (
    <SettingsLayout>
      <div className="my-12 lg:my-6">
        <div className="flex flex-col w-full px-6 py-3">
          <div className="flex items-center w-full ">
            <span className=" font-bold">Notifications</span>
            {data?.count !== 0 && (
              <div className="  ml-auto">
                <button
                  onClick={() => handleDeleteAllNotification()}
                  type="button"
                  className="hover:bg-gray-50 p-3 rounded text-sm font-medium text-gray-600 "
                >
                  Clear all
                </button>
              </div>
            )}
          </div>
          <div className=" border border-[#E2E8F0] px-[-30px] my-6 mx-[-25px]" />
          {data?.count === 0 && (
            <div className="flex flex-col items-center justify-center w-full h-[300px]">
              <span className="text-[#64748B]">No notifications!!!</span>
            </div>
          )}
          {data?.results?.map((item: any, index: any) => (
            <div
              onClick={() => handleMarkNotification(item)}
              key={index + 1}
              className="cursor-pointer flex flex-col w-full group"
            >
              <div className="flex justify-between w-full">
                <div className="flex flex-col w-full " key={Math.random()}>
                  <span
                    className={` ${item?.is_read ? "text-[#64748B] " : "text-[#1E429F] font-bold"}`}
                  >
                    {item?.notification_message}
                  </span>
                  <span className="text-sm  text-[#64748B]">
                    {dayjs(item?.created_at).format("MMM DD, YYYY, h:mm A")}
                  </span>
                </div>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteNotification(item);
                  }}
                  className="hidden group-hover:block text-red-500"
                >
                  <Trash2 size={20} />
                </button>
                {/* <div>
                    <SwitchContainer name={"switch"} handleToggle={() => {}} />
                  </div> */}
              </div>
              <div className=" border border-[#E2E8F0] px-[-30px] my-6 mx-[-25px]" />
            </div>
          ))}
        </div>
      </div>
    </SettingsLayout>
  );
};
