import { Box, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0.3px solid #24242480',
    boxShadow: "0px 4px 4px 0px #00000040",
    pb: 3,
    borderRadius: 2
};

interface Props {
    modal: boolean
}

export const SignUpSuccessModal = ({ modal }: Props) => {
    const navigate = useNavigate()
    return (
        <Modal
            open={modal}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box className="w-[93%] lg:w-[400px] p-6 gap-3 flex flex-col items-center justify-center" sx={{ ...style }}>
                <img src="https://img.icons8.com/?size=100&id=12402&format=png&color=20C997" alt="" />
                <p className='font-semibold'>Congrats All Done</p>
                {/* <h4 className="text-center font-semibold text-[20px]">Targeting is on - now you just need one review</h4> */}
                {/* <p className="my-2 text-center">Remember, your profile needs one review before customers see you in their results.</p> */}
                <button onClick={()=> navigate("/profile")} className="w-full mt-3 h-[45px] bg-blue-500 rounded-sm text-white" type="submit">Go To Dashboard</button>
            </Box>
        </Modal>
    )
}