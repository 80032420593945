/* eslint-disable react/prop-types */
import {
  Autocomplete,
  TextField,
  Box,
  Modal,
  CircularProgress,
} from "@mui/material";
import { Modal as FlowbiteModal } from "flowbite-react";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import AvatarImage from "assets/images/avatarImage.png";
import {  MessageCircleQuestion, X } from "lucide-react";
import Api from "utils/auth/api";
import { Button } from "ui/button";
import AsyncSelectLocation from "component/common/Layout/LocationSearch";
import AsyncSelectCategory from "component/common/Layout/CategorySearch";
import dayjs from "dayjs";
import FileUploader from "ui/fileUploader";
import { FormProvider, useForm } from "react-hook-form";
import Spinner from "component/oldcomponent/spinner/spinner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "0.3px solid #24242480",
  boxShadow: "0px 4px 4px 0px #00000040",
  pb: 3,
  borderRadius: 2,
};

export const NewServiceModal: React.FC<{
  newModal: boolean;
  handleNewModalClose: any;
  handleAddNewSkill: any;
  setPrice: any;
  newSkill: any;
}> = ({
  newModal,
  newSkill,
  handleAddNewSkill,
  handleNewModalClose,
  setPrice,
}) => {
  return (
    <Modal
      open={newModal}
      onClose={handleNewModalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box className="w-[93%] lg:w-[400px]" sx={{ ...style }}>
        <div
          className="px-5 pt-4 pb-3 text-[20px] font-semibold"
          style={{ borderBottom: "0.3px solid #24242480" }}
          id="child-modal-title"
        >
          {newSkill?.name}
        </div>
        <div className="px-5 pt-6 pb-3">
          <p className="text-center">
            Pros with upfront pricing get hired more on Audtiklance.
          </p>
          <p className="my-2 text-center">
            Add a base price to help you get contacted and hired more, The price
            will include: Labor (excludes cost of parts).
          </p>
          <br />
          <label htmlFor="price">Enter your base price</label>
          <br />
          <CurrencyInput
            name="price"
            id="price"
            placeholder="$0.00"
            prefix="$"
            required
            className="w-full mt-1 rounded-sm"
            onValueChange={(value) => setPrice(value!)}
          />
          <div className="flex gap-2 mt-4">
            <button
              type="button"
              style={{ border: "1px solid grey" }}
              className="w-[50%] h-[40px] rounded-sm text-gray-700"
              onClick={handleNewModalClose}
            >
              Cancel
            </button>
            <button
              onClick={handleAddNewSkill}
              className="w-[50%] h-[40px] bg-blue-500 rounded-sm text-white"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export const SearchServiceModal: React.FC<{
  searchModal: any;
  handleSearchModalClose: any;
  data: any;
  handleServiceSelect: any;
  setTerm: any;
  setNewModal: any;
}> = ({
  searchModal,
  setNewModal,
  setTerm,
  handleSearchModalClose,
  handleServiceSelect,
  data,
}) => {
  return (
    <Modal
      open={searchModal}
      onClose={handleSearchModalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box className="w-[93%] lg:w-[400px]" sx={{ ...style }}>
        <div
          className="px-5 pt-4 pb-3 text-[20px] font-semibold"
          style={{ borderBottom: "0.3px solid #24242480" }}
          id="child-modal-title"
        >
          Select A New Skill To Add
        </div>
        <div className="px-5 pt-6 pb-3">
          <Autocomplete
            options={data?.results || []}
            // @ts-ignore
            getOptionLabel={(option) => option?.name as string}
            onChange={handleServiceSelect}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search to add a skill..."
                variant="outlined"
                onChange={(e) => setTerm(e.target.value)}
              />
            )}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "inherit",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "inherit",
                },
              },
            }}
          />
          <div className="flex gap-2 mt-4">
            <button
              type="button"
              style={{ border: "1px solid grey" }}
              className="w-[50%] text-[14px] h-[40px] rounded-sm text-gray-700"
              onClick={handleSearchModalClose}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setNewModal(true);
              }}
              className="w-[50%] text-[14px] h-[40px] bg-blue-500 rounded-sm text-white"
              type="submit"
            >
              Continue
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export const ListServiceModal: React.FC<{
  listModal: any;
  handleListModalClose: any;
  setListPayModal: any;
  setBoostModal: any;
  price: any;
  setPrice: any;
  activeSkill: any;
}> = ({
  listModal,
  handleListModalClose,
//   setBoostModal,
//   setListPayModal,
  activeSkill,
  price,
  setPrice,
}) => {
  return (
    <Modal
      open={listModal}
      onClose={handleListModalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box className="w-[93%] lg:w-[400px]" sx={{ ...style }}>
        <div
          className="px-5 pt-4 pb-3 text-[20px] font-semibold"
          style={{ borderBottom: "0.3px solid #24242480" }}
          id="child-modal-title"
        >
          {activeSkill?.sub_category}
        </div>
        <div className="px-5 pt-6 pb-3">
          <p className="text-center">
            Pros with upfront pricing get hired more on Audtiklance.
          </p>
          <p className="my-2 text-center">
            Add a base price to help you get contacted and hired more, The price
            will include: Labor (excludes cost of parts).
          </p>
          <br />
          <label htmlFor="price">Enter your base price</label>
          <br />
          <CurrencyInput
            name="price"
            id="price"
            placeholder="$0.00"
            prefix="$"
            required
            value={price}
            className="w-full mt-1 rounded-sm"
            onValueChange={(value) => setPrice(value!)}
          />
          <div className="flex gap-2 mt-4">
            <button
              type="button"
              style={{ border: "1px solid grey" }}
              className="w-[50%] text-[14px] h-[40px] rounded-sm text-gray-700"
              onClick={handleListModalClose}
            >
              Cancel
            </button>
            <button
              className="w-[50%] text-[14px] h-[40px] bg-blue-500 rounded-sm text-white"
              type="submit"
            >
              Submit
            </button>
            {/*onClick={() => { setListPayModal(true) }}  <button onClick={()=> setBoostModal(true)} className="w-[50%] text-[14px] h-[40px] bg-blue-500 rounded-sm text-white" type="submit">List & Boost</button> */}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export const DeleteServiceModal: React.FC<{
  deleteModal: any;
  handleDeleteModalClose: any;
  setDeleteModal: any;
  activeSkill: any;
  deleteService: any;
}> = ({
  deleteModal,
  deleteService,
  handleDeleteModalClose,
//   setDeleteModal,
  activeSkill,
}) => {
  return (
    <Modal
      open={deleteModal}
      onClose={handleDeleteModalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box className="w-[93%] lg:w-[400px]" sx={{ ...style }}>
        <div
          className="px-5 pt-4 pb-3 text-[20px] font-semibold"
          style={{ borderBottom: "0.3px solid #24242480" }}
          id="child-modal-title"
        >
          {activeSkill?.sub_category}
        </div>
        <div className="px-5 pt-6 pb-3">
          <p className="text-center">
            Are you sure you want to delete this service?
          </p>
          <p className="text-center">This action cannot be undone.</p>
          <div className="flex gap-2 mt-4">
            <button
              onClick={handleDeleteModalClose}
              type="button"
              style={{ border: "1px solid grey" }}
              className="w-[50%] text-[14px] h-[40px] rounded-sm text-gray-700"
            >
              Cancel
            </button>
            <button
              onClick={() => deleteService(activeSkill)}
              className="w-[50%] text-[14px] h-[40px] bg-blue-500 rounded-sm text-white"
              type="submit"
            >
              Delete
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export const ListPayModal: React.FC<{
  listPayModal: any;
  handleListPayModalClose: any;
  handleListPaySubmit: any;
}> = ({ listPayModal, handleListPayModalClose }) => {
  return (
    <Modal
      open={listPayModal}
      onClose={handleListPayModalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      className=""
    >
      <Box className="w-[93%] lg:w-[500px] " sx={{ ...style }}>
        <div
          className="px-5 pt-4 pb-3 text-[20px] font-semibold"
          style={{ borderBottom: "0.3px solid #24242480" }}
          id="child-modal-title"
        >
          List Your Service
        </div>
        <div className="px-3 pt-3 pb-0">
          <div className="mb-2">
            <h4 className="text-[18px] font-bold">Reach More Clients</h4>
            <p>Once Selected, Please Click Continue</p>
          </div>
          <div
            style={{ border: "0.6px solid grey", borderRadius: "5px" }}
            className="flex items-start justify-between p-3"
          >
            <div className="flex items-center gap-2">
              <input type="radio" name="type" id="type1" />
              <label htmlFor="type1" className="cursor-pointer">
                <h3>Basic Listing (Free)</h3>
                <ul className="text-[13px]">
                  <li>Profile displayed in search result</li>
                  <li>Access to monthly credits for basic leads</li>
                  <li>Limited visibility: ranked lower in search results</li>
                </ul>
              </label>
            </div>
            <h1>$0.00</h1>
          </div>
          <div
            style={{ border: "0.6px solid grey", borderRadius: "5px" }}
            className="flex items-start justify-between p-3 my-3"
          >
            <div className="flex items-center gap-2">
              <input type="radio" name="type" id="type2" />
              <label htmlFor="type2" className="cursor-pointer">
                <h3>Featured Listing</h3>
                <ul className="text-[13px]">
                  <li>
                    Highlighted profile in search results (priority ranking)
                  </li>
                  <li>Tag as "Featured" for more credibility</li>
                  <li>
                    Additional exposure on the homepage or relevant categories
                  </li>
                </ul>
              </label>
            </div>
            <h1>$5.00</h1>
          </div>
          <div
            style={{ border: "0.6px solid grey", borderRadius: "5px" }}
            className="flex items-start justify-between p-3"
          >
            <div className="flex items-center gap-2">
              <input type="radio" name="type" id="type3" />
              <label htmlFor="type3" className="cursor-pointer">
                <h3>Premium Plus Listing</h3>
                <ul className="text-[13px]">
                  <li>Top ranking in search result</li>
                  <li>
                    Access to insights on profile views and lead generation
                    analytics
                  </li>
                  <li>
                    Free trial for additional credit to connect with clients
                  </li>
                </ul>
              </label>
            </div>
            <h1>$15.00</h1>
          </div>
          <hr />
          <h2 className="flex gap-3 my-4">
            What is a featured listing?{" "}
            <MessageCircleQuestion className="cursor-pointer" />
          </h2>
          {/* <div className='flex'>
                        <CheckCircle2 fill='green' stroke='white' />
                        <p className='text-[13px]'><span>Priority Ranking:</span> Your service profile appear higher in search results within your category, increasing visibility to potential clients</p>
                    </div>
                    <div className='flex'>
                        <CheckCircle2 fill='green' stroke='white' />
                        <p className='text-[13px]'><span>Highlighted Appearance:</span> Your profile is tagged as "Featured" making it stanf out from other listings and boosting credibility</p>
                    </div>
                    <div className='flex'>
                        <CheckCircle2 fill='green' stroke='white' />
                        <p className='text-[13px]'><span>Exclusive Exposure:</span> Additional placement on the homepage or prominent areas, attracting more attention from users browsing the platform</p>
                    </div>
                    <div className='flex'>
                        <CheckCircle2 fill='green' stroke='white' />
                        <p className='text-[13px]'><span>Boost your service:</span> to the top of the search results to increase visibility and attract more client actively searching for your expertise</p>
                    </div> */}
          <div className="flex gap-2 mt-4">
            <button
              type="button"
              style={{ border: "1px solid grey" }}
              className="w-[50%] text-[14px] h-[40px] rounded-sm text-gray-700"
              onClick={handleListPayModalClose}
            >
              Cancel
            </button>
            <button
              className="w-[50%] text-[14px] h-[40px] bg-blue-500 rounded-sm text-white"
              type="submit"
            >
              Proceed to pay
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export const LeadPayModal: React.FC<{
  listPayModal: any;
  handleListPayModalClose: any;
  handleListPaySubmit: any;
  lead: any;
}> = ({ listPayModal, handleListPayModalClose, handleListPaySubmit, lead }) => {
  return (
    <Modal
      open={listPayModal}
      onClose={handleListPayModalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box
        className="w-[93%] lg:w-[600px] max-h-96  h-[70%] overflow-y-auto"
        sx={{ ...style }}
      >
        <div
          className="px-5 pt-4 pb-3 text-[20px] font-semibold"
          style={{ borderBottom: "0.3px solid #24242480" }}
          id="child-modal-title"
        >
          View lead details
        </div>
        <div className="px-3 pt-3 pb-0">
          <div className="flex items-center mb-4">
            <img
              src={lead?.user?.profile_picture || AvatarImage}
              alt="Profile"
              className="w-16 h-16 mr-4 rounded-full"
            />
            <div>
              <h2 className="text-xl font-semibold">
                {lead?.user?.firstname + " " + lead?.user?.lastname}
              </h2>
            </div>
          </div>
          <div className="mb-6">
            <h3 className="mb-2 text-lg font-semibold">Message</h3>
            <p className="text-gray-700">{lead?.message}</p>
          </div>

          {lead?.pictures && lead?.pictures.length > 0 && (
            <div className="mb-6">
              <h3 className="mb-2 text-lg font-semibold">Lead Pictures</h3>
              <div className="grid grid-cols-2 gap-2">
                {lead?.pictures.map((picture: string, index: any) => (
                  <img
                    key={index}
                    src={picture}
                    alt={`Lead Picture ${index + 1}`}
                    className="w-full h-auto rounded"
                  />
                ))}
              </div>
            </div>
          )}
          <div className="mb-2">
            {/* <h4 className='text-[18px] font-bold'>{lead.message}</h4> */}
          </div>
          <div
            style={{ border: "0.6px solid grey", borderRadius: "5px" }}
            className="p-3 "
          >
            Pay <span className="font-bold">$10</span> to view this lead and
            gain valuable insights. This will help you connect with potential
            clients and grow your business.
          </div>
          <hr />

          <div className="flex justify-end gap-2 mt-4">
            <button
              type="button"
              style={{ border: "1px solid grey" }}
              className="px-4 text-[14px] h-[40px] rounded-sm text-gray-700"
              onClick={handleListPayModalClose}
            >
              Cancel
            </button>
            <button
              className="px-4 text-[14px] h-[40px] bg-blue-500 rounded-sm text-white"
              type="button"
              onClick={() => handleListPaySubmit(lead)}
            >
              Pay For Lead
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export const JobLeadPayModal: React.FC<{
  listPayModal: any;
  handleListPayModalClose: any;
  handleListPaySubmit: any;
  lead: any;
}> = ({ listPayModal, handleListPayModalClose, handleListPaySubmit, lead }) => {
  const [jobLead, setJobLead] = useState<{
    images?: [];
    budget?: string;
    category?: string;
    title?: string;
    description?: string;
    address?: string;
  }>({
    images: [],
    budget: "",
    category: "",
    title: "",
    description: "",
    address: "",
  });
  const [loading, setLoading] = useState(true);
  const handleGetJob = async (id: any) => {
    try {
      const response = await Api.get(`/job/${id}/`);
      setLoading(false);
      setJobLead(response?.data);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetJob(lead?.job);
  }, [lead?.job]);

  if (loading) {
    return (
      <div className="flex items-center justify-center ">
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </div>
    );
  }
  return (
    <Modal
      open={listPayModal}
      onClose={handleListPayModalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box
        className="w-[93%] lg:w-[50%] max-h-96  h-[70%] overflow-y-auto"
        sx={{ ...style }}
      >
        <div
          className="px-5 pt-4 pb-3 text-[20px] font-semibold capitalize"
          style={{ borderBottom: "0.3px solid #24242480" }}
          id="child-modal-title"
        >
          {jobLead?.title || "Job Details"}
        </div>
        <div className="px-3 pt-3 pb-0">
          <div className="flex items-center mb-4">
            <img
              src={lead?.user?.profile_picture || AvatarImage}
              alt="Profile"
              className="w-16 h-16 mr-4 rounded-full"
            />
            <div>
              <h2 className="text-xl font-semibold">
                {lead?.user?.firstname + " " + lead?.user?.lastname}
              </h2>
            </div>
          </div>
          <div className="flex items-center gap-4 mb-2">
            <h3 className="text-lg font-medium ">Budget:</h3>
            <p className="text-gray-700"> ${jobLead?.budget}</p>
          </div>
          <div className="flex items-center gap-4 mb-2">
            <h3 className="text-lg font-medium ">Service Type:</h3>
            <p className="text-gray-700">{jobLead?.category}</p>
          </div>
          <div className="flex items-center gap-4 mb-2">
            <h3 className="text-lg font-medium ">Location:</h3>
            <p className="text-gray-700">{jobLead?.address}</p>
          </div>
          <div className="mb-6 ">
            <h3 className="mb-2 text-lg font-medium">Description:</h3>
            <p className="text-gray-700">{jobLead?.description}</p>
          </div>

          {jobLead?.images && jobLead?.images?.length > 0 && (
            <div className="mb-6">
              <h3 className="mb-2 text-lg font-semibold">Lead Pictures</h3>
              <div className="grid grid-cols-2 gap-2 lg:grid-cols-4">
                {jobLead?.images.map((picture: any, index: any) => (
                  <img
                    key={index}
                    src={picture?.image_url}
                    alt={`Lead Picture ${index + 1}`}
                    className="w-full h-auto rounded"
                  />
                ))}
              </div>
            </div>
          )}
          <div className="mb-2">
            {/* <h4 className='text-[18px] font-bold'>{lead.message}</h4> */}
          </div>
          <div
            style={{ border: "0.6px solid grey", borderRadius: "5px" }}
            className="p-3 "
          >
            Pay <span className="font-bold">$10</span> to view this lead and
            gain valuable insights. This will help you connect with potential
            clients and grow your business.
          </div>
          <hr />

          <div className="flex justify-end gap-2 mt-4">
            <button
              type="button"
              style={{ border: "1px solid grey" }}
              className="px-4 text-[14px] h-[40px] rounded-sm text-gray-700"
              onClick={handleListPayModalClose}
            >
              Cancel
            </button>
            <button
              className="px-4 text-[14px] h-[40px] bg-blue-500 rounded-sm text-white"
              type="button"
              onClick={() => handleListPaySubmit(lead)}
            >
              Pay For Lead
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export const BoostModal: React.FC<{
  boostModal: any;
  handleBoostModalClose: any;
  handleBoostSubmit: any;
}> = ({ boostModal, handleBoostModalClose }) => {
  return (
    <Modal
      open={boostModal}
      onClose={handleBoostModalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box className="w-[93%] lg:w-[500px]" sx={{ ...style }}>
        <div
          className="px-5 pt-4 pb-3 text-[20px] font-semibold"
          style={{ borderBottom: "0.3px solid #24242480" }}
          id="child-modal-title"
        >
          List And Boost Your Service
        </div>
        <div className="px-3 pt-3 pb-0">
          <div className="mb-2">
            <h4 className="text-[18px] font-bold">Reach More Clients</h4>
            <p>Once Selected, Please Click Continue</p>
          </div>
          <div
            style={{ border: "0.6px solid grey", borderRadius: "5px" }}
            className="flex items-start justify-between p-3"
          >
            <div className="flex items-center gap-2">
              <input type="radio" name="type" id="type1" />
              <label htmlFor="type1" className="cursor-pointer">
                <h3>Bronze Tier: Basic Boost</h3>
                <ul className="text-[12px]">
                  <li>
                    Displayed at the top of search results for relevant
                    categories once per day
                  </li>
                  <li>Highlighted with a subtle badge indicating "Featured"</li>
                </ul>
              </label>
            </div>
            <h1>$10/month</h1>
          </div>
          <div
            style={{ border: "0.6px solid grey", borderRadius: "5px" }}
            className="flex items-start justify-between p-3 my-3"
          >
            <div className="flex items-center gap-2">
              <input type="radio" name="type" id="type2" />
              <label htmlFor="type2" className="cursor-pointer">
                <h3>Silver Tier: Premium Exposure</h3>
                <ul className="text-[12px]">
                  <li>
                    Displayed at the top of search results multiple times daily
                  </li>
                  <li>Enhanced listing with a bolded border to stand out</li>
                  <li>Includes basic analytics (views and clicks)</li>
                </ul>
              </label>
            </div>
            <h1>$20/month</h1>
          </div>
          <div
            style={{ border: "0.6px solid grey", borderRadius: "5px" }}
            className="flex items-start justify-between p-3"
          >
            <div className="flex items-center gap-2">
              <input type="radio" name="type" id="type3" />
              <label htmlFor="type3" className="cursor-pointer">
                <h3>Gold Tier: Maximum Visibility</h3>
                <ul className="text-[12px]">
                  <li>
                    Always displayed in the top 3 results for relevant
                    categories
                  </li>
                  <li>Featured in a rotating banner on the homepage</li>
                  <li>
                    Includes detailed analytics (views, clicks, and conversion
                    insights).
                  </li>
                  <li>
                    Exclusive "Top Service Provider" badge for credibility.
                  </li>
                </ul>
              </label>
            </div>
            <h1>$50/month</h1>
          </div>
          <hr />
          <h2 className="flex gap-3 my-4">
            What is a featured ad?{" "}
            <MessageCircleQuestion className="cursor-pointer" />
          </h2>
          {/* <div className='flex'>
                        <CheckCircle2 fill='green' stroke='white' />
                        <p className='text-[13px]'><span>Prominent Display:</span> Your service or profile is displayed at the top of website and specific search results or category pages for maximum exposure.</p>
                    </div>
                    <div className='flex'>
                        <CheckCircle2 fill='green' stroke='white' />
                        <p className='text-[13px]'><span>Custom Visibility Duration:</span> Choose to run the ad for 1–7 days to target short-term or seasonal campaigns.</p>
                    </div>
                    <div className='flex'>
                        <CheckCircle2 fill='green' stroke='white' />
                        <p className='text-[13px]'><span>Geo-Targeting:</span> Tailor visibility to specific locations or audience demographics for better reach to relevant clients.</p>
                    </div>
                    <div className='flex'>
                        <CheckCircle2 fill='green' stroke='white' />
                        <p className='text-[13px]'><span>Showcase your service</span> prominently in highlighted sections across the platform, drawing more attention and enhancing credibility to stand out from competitors.</p>
                    </div> */}
          <div className="flex gap-2 mt-4">
            <button
              type="button"
              style={{ border: "1px solid grey" }}
              className="w-[50%] text-[14px] h-[40px] rounded-sm text-gray-700"
              onClick={handleBoostModalClose}
            >
              Cancel
            </button>
            <button
              className="w-[50%] text-[14px] h-[40px] bg-blue-500 rounded-sm text-white"
              type="submit"
            >
              Proceed to pay
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export const SubscribeModal: React.FC<{
  newModal: boolean;
  handleSubscribeModalClose: any;
  handleSubmit: any;
  setPrice: any;
  newSkill: any;
}> = ({
  newModal,
  newSkill,
  handleSubmit,
  handleSubscribeModalClose,
}) => {
  return (
    <Modal
      open={newModal}
      onClose={handleSubscribeModalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box className="w-[93%] lg:w-[400px]" sx={{ ...style }}>
        <div
          className="px-5 pt-4 pb-3 text-[20px] font-semibold capitalize"
          style={{ borderBottom: "0.3px solid #24242480" }}
          id="child-modal-title"
        >
          {newSkill?.name}
        </div>
        <div className="px-5 pt-6 pb-3">
          <p className="text-center capitalize">
            Subscribe to {newSkill?.name}
          </p>
          <p className="my-2 text-center">
            Subscribe to get the plan upgraded perks for;
          </p>
          {/* <br /> */}
          {/* <label htmlFor="price">Enter your budget price </label> */}
          {/* <br /> */}
          {/* <CurrencyInput
                        name='price'
                        id='price'
                        placeholder={`$${newSkill?.min_cost} - $${newSkill?.max_cost}`}
                        prefix="$"
                        // required
                        disabled
                        value={newSkill?.max_cost}
                        className="w-full mt-1 rounded-sm"
                        // onValueChange={(value) => setPrice(value)}
                    /> */}
          <h3 className="text-xl font-bold text-center">
            ${newSkill?.max_cost}
          </h3>
          <div className="flex gap-2 mt-4">
            <button
              type="button"
              style={{ border: "1px solid grey" }}
              className="w-[50%] h-[40px] rounded-sm text-gray-700"
              onClick={handleSubscribeModalClose}
            >
              Cancel
            </button>
            <button
              onClick={() => handleSubmit(newSkill?.id)}
              className="w-[50%] h-[40px] bg-blue-500 rounded-sm text-white"
              type="submit"
            >
              Continue
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export const RequestModal: React.FC<{
  newModal: boolean;
  handleRequestModalClose: any;
  handleSubmit: any;
  setMessage: any;
  newProvider: any;
}> = ({
  newModal,
  newProvider,
  handleSubmit,
  handleRequestModalClose,
  setMessage,
}) => {
  const handleInputChange = (e: any) => {
    setMessage(e.target.value);
  };

  return (
    <Modal
      open={newModal}
      onClose={handleRequestModalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box className="w-[93%] lg:w-[400px]" sx={{ ...style }}>
        <div className="px-5 pt-6 pb-3">
          <p className="text-center capitalize ">
            {" "}
            Send a message to the {newProvider?.business_name}
          </p>
          <p className="my-2 text-center">
            Ask questions or describe what you need. You don’t need to include
            contact info yet.
          </p>

          <div className="flex flex-col w-full">
            <textarea
              name="message"
              placeholder="Send a brief description of the job to the provider"
              rows={6}
              className="resize-none border ring-1 placeholder:text-xs ring-[#E2E8F0] border-[#E2E8F0] p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              onChange={(e) => handleInputChange(e)}
            />
          </div>

          {/* <h3 className='text-xl font-bold text-center'>${newSkill?.max_cost}</h3> */}
          <div className="flex gap-2 mt-4">
            <button
              type="button"
              style={{ border: "1px solid grey" }}
              className="w-[50%] h-[40px] rounded-sm text-gray-700"
              onClick={handleRequestModalClose}
            >
              Cancel
            </button>
            <button
              onClick={() => handleSubmit(newProvider?.id)}
              className="w-[50%] h-[40px] bg-blue-500 rounded-sm text-white"
              type="submit"
            >
              Continue
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export const QuoteModal: React.FC<{
  openModal: boolean;
  isCreatingQuote: boolean;
  setOpenModal: any;
  handleSubmitForm: any;
}> = ({ openModal, setOpenModal, isCreatingQuote, handleSubmitForm }) => {
  const [location, setLocation] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const methods = useForm<any>({
    mode: "onChange",
  });
  const {
    // formState: { errors, isValid }, // Access form validation state
    register,
    // reset,
  } = methods;

  const handleSelectCategory = (
    selectedOption: React.SetStateAction<never[]>
  ) => {
    setCategoryOptions(selectedOption);
  };

  const handleSelectLocation = (selectedOption: React.SetStateAction<null>) => {
    setLocation(selectedOption);
  };

  const handleFormSubmit = async (payload: any) => {
    handleSubmitForm({ location, categoryOptions, ...payload });
  };

  return (
    <FlowbiteModal
      dismissible
      show={openModal}
      onClose={() => setOpenModal(false)}
    >
      <FlowbiteModal.Body>
        <div className="relative flex flex-col">
          <button
            type="button"
            onClick={() => setOpenModal(false)}
            className="ml-auto"
          >
            <X />
          </button>

          <FormProvider {...methods}>
            <div className="col-span-12 mt-6 bg-white 2xl:col-span-9 ">
              <form
                onSubmit={methods.handleSubmit(handleFormSubmit)}
                className="grid gap-4 lg:grid-cols-12 "
              >
                <div className="grid col-span-12 gap-6 lg:grid-cols-12">
                  <div className="lg:col-span-6 ">
                    <label
                      htmlFor="date"
                      className="text-sm font-semibold form-label"
                    >
                      Location{" "}
                      <span className="font-bold text-red-600 ">*</span>
                    </label>
                    <AsyncSelectLocation
                      onChange={handleSelectLocation}
                      placeholder="Search for a location/Zip Code"
                    />
                  </div>
                  <div className="lg:col-span-6">
                    <label
                      htmlFor="date"
                      className="text-sm font-semibold form-label"
                    >
                      Category{" "}
                      <span className="font-bold text-red-600 ">*</span>
                    </label>
                    <AsyncSelectCategory
                      onChange={handleSelectCategory}
                      placeholder="Search category"
                    />
                  </div>
                </div>

                <div className="col-span-12 ">
                  <div className="grid gap-6 lg:grid-cols-12">
                    <div className="lg:col-span-6">
                      <label
                        htmlFor="deadline"
                        className="text-sm font-semibold form-label"
                      >
                        Deadline
                        <span className="font-bold text-red-600 ">*</span>
                      </label>
                      <div className="relative w-full mx-auto">
                        <input
                          type="date"
                          min={dayjs().format("YYYY-MM-DD")}
                          className="form-control placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50"
                          {...register("deadline")}
                          id="deadline"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-12">
                  <label
                    htmlFor="date"
                    className="text-sm font-semibold form-label"
                  >
                    Description{" "}
                    <span className="font-bold text-red-600 ">*</span>
                  </label>
                  <textarea
                    id="description"
                    rows={6}
                    {...register("description")}
                    placeholder="Brief description of the task"
                    className={`resize-none border ring-1 placeholder:text-xs ring-[#E2E8F0] w-full border-[#E2E8F0] p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent 
            `}
                  />
                </div>

                <div className="col-span-12">
                  <div>
                    <span className="text-sm font-bold">
                      Upload up to 5 images or file
                    </span>
                    <FileUploader
                      name={`pictures`}
                      id={`file-input-cac`}
                      snapType={""}
                      isMultiple={true}
                      variant="type3"
                      slidesToScroll={1}
                      slidesToShow={1}
                    />
                  </div>
                </div>
                <div>
                  <Button>{isCreatingQuote ? <Spinner /> : "Submit"}</Button>
                </div>
              </form>
            </div>
          </FormProvider>
        </div>
      </FlowbiteModal.Body>
    </FlowbiteModal>
  );
};
