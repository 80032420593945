import cleaningImage from "../assets/images/cleaning.jpg";
import handymanImage from "../assets/images/handyman.jpg";
import plumbingImage from "../assets/images/plumbing.jpg";
import electricImage from "../assets/images/electric.jpg";
import gardenImage from "../assets/images/gardening.jpg";
import paintingImage from "../assets/images/painting.jpg";
import applianceImage from "../assets/images/appliance.jpg";
import furnitureImage from "../assets/images/furniture.jpg";
import pestImage from "../assets/images/pest.jpg";
import hvacImage from "../assets/images/hvac.jpg";
import roofImage from "../assets/images/roof.jpg";
import homeSecurityImage from "../assets/images/home-security.jpg";

export const ExploreServices = [
  {
    serviceHeader: "Home Services",
    services: [
      {
        name: "Home Cleaning",
        img: cleaningImage,
        list: [
          "General dusting and wiping",
          "Trash removal",
          "Carpet and upholstery cleaning",
        ],
        searchValue:"cleaning"
      },
      {
        name: "Handyman Services",
        img: handymanImage,
        list: [
          "Fixing Leaky Faucets",
          "Drywall Patching",
          "Door and Window Repair",
        ],
        searchValue:"general-contractor"
      },
      {
        name: "Plumbing Repair",
        img: plumbingImage,
        list: [
          "Leaky Faucet Repair",
          "Drain Cleaning and Unclogging",
          "Pipe Leak Repair",
        ],
        searchValue:"plumbing"
      },
      {
        name: "Electrical Installation",
        img: electricImage,
        list: [
          "Lighting Installation",
          "Electrical Outlet Installation",
          "Ceiling Fan Installation",
        ],
        searchValue:"electrician"
      },
      {
        name: "Gardening & Landscaping",
        img: gardenImage,
        list: [
          "Lawn Care and Maintenance",
          "Garden Design and Installation",
          "Tree and Shrub Pruning",
        ],
        searchValue:"gardening"
      },
      {
        name: "Painting & Decorating",
        img: paintingImage,
        list: [
          "Interior Painting",
          "Exterior Painting",
          "Wallpaper Installation",
        ],
        searchValue:"painting"
      },
      {
        name: "Appliance Repair",
        img: applianceImage,
        list: [
          "Refrigerator Repair",
          "Washing Machine Repair",
          "Dishwasher Repair",
        ],
        searchValue:"repairs"
      },
      {
        name: "Furniture Assembly",
        img: furnitureImage,
        list: [
          "Flat Pack Furniture Assembly",
          "Office Furniture Setup",
          "Outdoor Furniture Assembly",
        ],
        searchValue:"furniture"
      },
    ],
  },
  {
    serviceHeader: "Explore More Services",
    services: [
      {
        name: "Pest Control",
        img: pestImage,
        list: ["Termite Inspection", "Rodent Control", "Bed Bug Treatment"],
        searchValue:"fumigation"
      },
      {
        name: "HVAC Maintenance",
        img: hvacImage,
        list: [
          "Air Filter Replacement",
          "Duct Cleaning and Sealing",
          "Thermostat Calibration",
        ],
        searchValue:"maintenance"
      },
      {
        name: "Roof Repair",
        img: roofImage,
        list: [
          "Shingle Replacement",
          "Leak Detection and Repair",
          "Roof Inspection and Maintenance",
        ],
        searchValue:"repairs"
      },
      {
        name: "Home Security Installation",
        img: homeSecurityImage,
        list: [
          "Surveillance Camera Installation",
          "Alarm System Setup",
          "Smart Lock Installation",
        ],
        searchValue:"security"
      },
    ],
  },
];


