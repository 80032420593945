import { SettingsLayout } from "context/settings/layout/SettingsLayout";
import {  Box, Modal } from '@mui/material';
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "ui/button";
import SwitchContainer from "ui/switch";
import { EmailIcon, PadLockIcon } from "assets/svg";

import { FormTextInput } from "ui/formTextInput";
import { useMutation } from "@tanstack/react-query";
import { notify } from "shared";
import { changeEmail, changePassword, confirmOTP, } from "services";
import { IChangePasswordType } from "types";
import { useAuthenticatedUser } from "hooks";
import { OtpInput } from "ui/otpInput";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '0.3px solid #24242480',
  boxShadow: "0px 4px 4px 0px #00000040",
  pb: 3,
  borderRadius: 2
};

export const Settings = () => {
  const methods = useForm<any>({});
  const [timeSpan, setTimeSpan] = useState<number>(0);
  const [interval, setCountdown] = useState<boolean>(false);
  const [loadingOtp, setOtpLoading] = useState<boolean>(false);
  const password_methods = useForm<any>({
    mode: "onChange"
  })
  const email_methods = useForm<any>({
    mode: "onChange"
  })
  const otp_methods = useForm<any>({
    mode: "onChange"
  })
  const [openModal, setOpenModal] = useState(false)
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const [openOtpModal, setOpenOtpModal] = useState(false)
  const { userDetails, isLoading , refetch} = useAuthenticatedUser();
 
  const handleRequestModalClose = ()=> {
    setOpenModal(!openModal)
  }
  const handleRequestEmailModalClose = ()=> {
    setOpenEmailModal(!openEmailModal)
  }
  const handleOtpModalClose = ()=> {
    setOpenOtpModal(!openOtpModal)
  }
  const { mutate, isPending } = useMutation({
    mutationFn: async (payload: IChangePasswordType) =>
      await changePassword({ ...payload }),
    onSuccess: async ({ data }) => {
      setOpenModal(false);
    },
    onError: async ({ response }: any) => {
      return notify.error({
        message: "Bad request",
        subtitle: response?.data?.error,
      });
    },
  });
  const { mutate: updateEmail, isPending: updateEmailPending } = useMutation({
    mutationFn: async (payload: any) =>
      await changeEmail({ ...payload }),
    onSuccess: async (data) => {
      setOpenModal(false);
      setOpenOtpModal(!openOtpModal)
      notify.success({
        message: "Success",
        subtitle: data?.message,
      });
    },
    onError: async ({ response }: any) => {
      return notify.error({
        message: "Bad request",
        subtitle: response?.data?.error,
      });
    },
  });
const handlePasswordSubmit = (payload: IChangePasswordType) => {
  mutate({
    old_password: payload?.old_password,
    new_password: payload?.new_password,
  });
};

const formatTime = (milliseconds: number): string => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `in ${formattedMinutes}:${formattedSeconds}`;
};
const handleResendOtp = async () => {
  // handleSignUp(false);

  if (timeSpan === 0) {
    setOtpLoading(true);
    setCountdown(!interval);
  }
  // resendOTP({
  //   email: formPayload?.email,
  // });

  // await emailMutate({
  //   email: formPayload?.email,
  // });
};
const handleEmailSubmit = (payload: any) => {
  updateEmail({
    email: payload?.email,
  });
};

const { mutate: otpMutate, isPending: isValidating } = useMutation({
  mutationFn: async (payload: any) => await confirmOTP({ ...payload }),
  onSuccess: async (response) => {
    setOpenEmailModal(false);
    setOpenOtpModal(!openOtpModal)
    notify.success({
      message: response?.message,
    });
    await refetch()
  },
  onError: async (response) => {
    notify.error({
      message: response?.message,
    });
    // setOpenConfirmPassword(false);
  },
});

const handleOtpSubmit = (payload: any) => {
  otpMutate({
    "otp": payload?.otp?.join(''),
    "action": "EMAIL_UPDATE"
  });
};


  return (
    <SettingsLayout>
      <FormProvider {...methods}>
        <form className="flex flex-col w-full px-6 py-3">
          <span className="mb-6 font-bold">Settings</span>
          <span className="text-sm font-semibold text-[#64748B]">
            SIGN-IN METHOD
          </span>
          <div className="flex flex-col gap-6 my-4">
            <div className="flex justify-between w-full">
              <div className="flex flex-col">
                <span className="font-bold">Email Address</span>
                <span className="text-xs">{userDetails?.data?.email}</span>
              </div>
              <Button type="button" onClick={()=> setOpenEmailModal(true)} variant="light-blue" size="sm">
                Change Email
              </Button>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex flex-col">
                <span className="font-bold">Password</span>
                <span className="text-xs">********</span>
              </div>
              <Button type="button" onClick={()=> setOpenModal(true)} variant="light-blue" size="sm">
                Change Password
              </Button>
            </div>
          </div>
          <div className=" border border-[#E2E8F0] px-[-30px] my-4 mx-[-25px]" />

          <div className="flex flex-col">
            <div className="flex flex-col">
              <div className="mb-3">
                <span className="text-sm font-semibold  text-[#64748B]">
                  TWO-STEP VERIFICATION
                </span>
              </div>

              <div className="flex flex-col gap-6">
                <div className="flex justify-between w-full">
                  <div className="flex flex-col w-full " key={Math.random()}>
                    <span className="font-bold ">Authenticator app</span>
                    <span className="text-sm  text-[#64748B]">
                      Enter a code generated by your authenticator app to
                      confirm it&apos;s you.
                    </span>
                  </div>
                  <div>
                    <SwitchContainer
                      name={"switch"}
                      handleToggle={() => {
                        console.log("too");
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex justify-between w-full">
                    <div className="flex flex-col w-full " key={Math.random()}>
                      <span className="font-bold ">Mobile app prompt</span>
                      <span className="text-sm  text-[#64748B]">
                        Receive a prompt from your mobile app to confirm
                        it&apos;s you.
                      </span>
                    </div>
                    <div>
                      <SwitchContainer
                        name={"switch"}
                        handleToggle={() => {
                          console.log("toggled");
                        }}
                      />
                    </div>
                  </div>
                  <div className=" border border-[#E2E8F0] px-[-30px] my-6 mx-[-25px]" />
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="mb-3">
                <span className="text-sm font-semibold  text-[#64748B]">
                  PREFERENCES
                </span>
              </div>

              <div className="flex flex-col gap-6">
                <div className="flex justify-between w-full">
                  <div className="flex flex-col w-full " key={Math.random()}>
                    <span className="font-bold ">Successful Payments</span>
                    <span className="text-sm  text-[#64748B]">
                      Receive a notification for every successful payment.
                    </span>
                  </div>
                  <div>
                    <SwitchContainer
                      name={"switch"}
                      handleToggle={() => {
                        console.log("first");
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex justify-between w-full">
                    <div className="flex flex-col w-full " key={Math.random()}>
                      <span className="font-bold ">Invoice Payments</span>
                      <span className="text-sm  text-[#64748B]">
                        Receive a notification it a customer sends an incorrect
                        amount to pay their Invoice
                      </span>
                    </div>
                    <div>
                      <SwitchContainer
                        name={"switch"}
                        handleToggle={() => {
                          console.log("first");
                        }}
                      />
                    </div>
                  </div>
                  <div className=" border border-[#E2E8F0] px-[-30px] my-6 mx-[-25px]" />
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="mb-3">
                <span className="text-sm font-semibold  text-[#64748B]">
                  APPERENCES
                </span>
              </div>

              <div className="flex flex-col gap-6">
                <div className="flex justify-between w-full">
                  <div className="flex flex-col w-full " key={Math.random()}>
                    <span className="font-bold ">Theme</span>
                    <span className="text-sm  text-[#64748B]">
                      Change the appearance of your dashboard
                    </span>
                  </div>
                  <div>
                    <SwitchContainer
                      name={"switch"}
                      handleToggle={() => {
                        console.log("first");
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <Modal
            open={openModal}
            onClose={handleRequestModalClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box className="w-[93%] lg:w-[400px]" sx={{ ...style }}>
              <FormProvider  {...password_methods}>
              <form className="px-5 pt-6 pb-3"  onSubmit={password_methods.handleSubmit(handlePasswordSubmit)}>
               
               <p className="text-center capitalize "> Change Password  </p>
               
               <div className='flex flex-col w-full space-y-3'>
               <FormTextInput
       placeholder="Enter your old password..."
       labelText="Password"
       className="w-full"
       type="password"
       icon={<img src={PadLockIcon} />}
       {...password_methods.register("old_password")}
     />

     <FormTextInput
       placeholder="Enter your new password..."
       labelText="Confirm Password"
       className="w-full"
       type="password"
       icon={<img src={PadLockIcon} />}
       {...password_methods.register("new_password")}
     />
               
               </div>
              
               {/* <h3 className='text-center text-xl font-bold'>${newSkill?.max_cost}</h3> */}
               <div className="flex gap-2 mt-4">
                   <button type="button" style={{ border: "1px solid grey" }} className="w-[50%] h-[40px] rounded-sm text-gray-700" onClick={handleRequestModalClose}>Cancel</button>
                   <button className="w-[50%] h-[40px] bg-blue-500 rounded-sm text-white" type="submit">Submit</button>
               </div>
           </form>
              </FormProvider>
               
            </Box>
        </Modal>
        <Modal
            open={openEmailModal}
            onClose={handleRequestEmailModalClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box className="w-[93%] lg:w-[400px]" sx={{ ...style }}>
              <FormProvider  {...email_methods}>
              <form className="px-5 pt-6 pb-3"  onSubmit={email_methods.handleSubmit(handleEmailSubmit)}>
               
               <p className="text-center capitalize "> Change Password  </p>
               
               <div className='flex flex-col w-full space-y-3'>
              
     <FormTextInput
       placeholder="Enter your new Email..."
       labelText="Email"
       className="w-full"
       type="email"
       icon={<img src={EmailIcon} />}
       {...email_methods.register("email")}
     />
               
               </div>
            
               <div className="flex gap-2 mt-4">
                   <button type="button" style={{ border: "1px solid grey" }} className="w-[50%] h-[40px] rounded-sm text-gray-700" onClick={handleRequestEmailModalClose}>Cancel</button>
                   <button className="w-[50%] h-[40px] bg-blue-500 rounded-sm text-white" type="submit">Submit</button>
               </div>
           </form>
              </FormProvider>
               
            </Box>
        </Modal>
        <Modal
            open={openOtpModal}
            onClose={handleOtpModalClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box className="w-[93%] lg:w-[400px]" sx={{ ...style }}>
              <FormProvider  {...otp_methods}>
              <form className="px-5 pt-6 pb-3"  onSubmit={otp_methods.handleSubmit(handleOtpSubmit)}>
               
              <div className="flex flex-col items-center w-full gap-2">
        <span className="text-xl font-bold">Confirm your Email Address</span>
        <span className="text-sm text-center">
          We have sent an OTP to your email,
        </span>
      </div>
               
               <div className='flex flex-col w-full space-y-3'>
              
               <OtpInput
            name="otp"
            length={6}
            // size="md"
            type="number"
            onChange={(value: any) => otp_methods.setValue("otp", value)}
            className="my-4"
          />
               
               </div>
            
               <div className="flex gap-2 mt-4">
                   <button type="button" style={{ border: "1px solid grey" }} className="w-[50%] h-[40px] rounded-sm text-gray-700" onClick={handleOtpModalClose}>Cancel</button>
                   <button className="w-[50%] h-[40px] bg-blue-500 rounded-sm text-white" type="submit">Submit</button>
               </div>
           </form>
              </FormProvider>
              <div>
        <span className="my-3 text-sm cursor-pointer" onClick={handleResendOtp}>
          Didn’t receive a code? Resend {timeSpan !== 0 && formatTime(timeSpan)}
        </span>
      </div>
            </Box>
        </Modal>
    </SettingsLayout>
  );
};
