import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { AutoSearch } from 'component/oldcomponent/HeroSection/AutoSearch';
import { SettingsLayout } from 'context/settings/layout/SettingsLayout';
import dayjs from 'dayjs';
import { useAuthenticatedUser, useCreateJob, useGetCategory } from 'hooks';
import { debounce } from 'lodash';
// import { Dropdown } from 'flowbite-react';
import {  DollarSignIcon } from 'lucide-react';
import { LoginForm } from 'pages/auth';
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getSkill } from 'services';
import { notify } from 'shared';
import { getCurrentLocation } from 'shared/getLocation';
import { Button } from 'ui/button';
import CustomSelect from 'ui/controlledSelectedComponent';
import FileUploader from 'ui/fileUploader';

function AddListing() {
  const navigate = useNavigate()
  const { userDetails } = useAuthenticatedUser();
   const [openForm, setOpenForm] = useState("");
   const [loading, setLoading] = useState(false)
  const [service, setService] = useState<any>({category: ""})
  const [term, setTerm] = useState("");
  const [searchResults, setSearchResults] = useState<any>([]);
  const [location, setLocation] = useState({geometry: {coordinates: []}, place_name: ''})
  const [searchValue, setSearchValue] = useState("")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentLocation, setCurrentLocation] = useState<{ latitude: any; longitude: any } | null>({
      latitude: '',
      longitude: '',
    });
  const methods = useForm<any>({
      mode: "onChange",
    });
  const {
    formState: { errors }, // Access form validation state
    register,
    reset
  } = methods;
  
  const { createJobMutation } = useCreateJob();
  const { isLoading } = useGetCategory();
 

  const { data } = useQuery({
    queryKey: ["service"],
    queryFn: () => getSkill(term),
    enabled: !!term,
  });

   useEffect(() => {
        getCurrentLocation()
        .then((location) => { 
           setCurrentLocation({latitude: location.latitude, longitude: location.longitude})
        })
        // eslint-disable-next-line no-console
        .catch(error => console.log('error:', error));
    }, []);

  useEffect(() => {
      // Debounce the search function to reduce API calls
      const delayedSearch = debounce(() => {
        if (searchValue.trim() !== "") {
          const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            searchValue,
          )}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}`;
  
          axios.get(url)
            .then((response: any) => {
              setSearchResults(response.data.features);
            })
            .catch((error: any) => {
              // eslint-disable-next-line no-console
              console?.error(error);
            });
        } else {
          setSearchResults([]);
        }
      }, 300);
  
      delayedSearch();
  
      // Clean up the debounce function on unmount
      return () => delayedSearch.cancel();
    }, [searchValue]);
  
    // const handleSearch = () => {
    //   let longitude: number | undefined;
    //   let latitude: number | undefined;
    //   if (location?.geometry?.coordinates.length > 0) {
    //     longitude = location.geometry.coordinates[0];
    //     latitude = location.geometry.coordinates[1];
    //   } else if (currentLocation) {
    //     longitude = currentLocation.longitude;
    //     latitude = currentLocation.latitude;
    //   } else {
    //     alert('Please select location');
    //     longitude = undefined;
    //     latitude = undefined;
    //     return;
    //   }
    // };

    const handleLocationChange = (_: any, value: any) => {
      setLocation(value);
    };
  
    const handleServiceSelect = (_: any, value: any) => {
      setService(value)
    }
    const handleChange = (term: string) => {
      setTerm(term);
    };
    
  const onFormSubmit = async (payload: any) => {
    setLoading(true)
    if(!userDetails){
      setOpenForm("login")
      localStorage.setItem('formData', JSON.stringify(payload));
      setLoading(false)
      return
    }
    
    const formData = new FormData();
    formData.append("budget", payload.budget);
    formData.append("title", payload.title);
    formData.append("category", service?.id);
    formData.append("description", payload.description);
    formData.append("longitude", location.geometry.coordinates[0] )
    formData.append("latitude", location.geometry.coordinates[1])
    formData.append("address", location?.place_name)
    formData.append("preferred_date", dayjs(payload.preferred_date).format('YYYY-MM-DD'));
    if (payload?.pictures && payload.pictures.length > 0) {
      payload.pictures.forEach((item: any, index: any) => {
        formData.append(`images[${index}]`, item);
      });
    }
    // payload?.pictures.forEach((item: any, index: any) => {
    //   formData.append(`images[${index}]`, item);
    // });
    const {data} = await createJobMutation(formData);
    if(data.success === true){
      localStorage.removeItem('formData');
      window.location.href = data.data;
      notify.success({ message: "job created successfully" });
    }
    if(data.success === false){
      notify.error({
              message: "Error while creating job, Please check all fields",
              // subtitle: err?.message,
            })
    }
    setLoading(false)
  };
  useEffect(() => {
    const savedFormData = localStorage.getItem('formData');
    if (savedFormData) {
      const parsedFormData = JSON.parse(savedFormData);
      // Populate form fields with saved data
      reset(parsedFormData);
    }
  }, []);
  return (
    <SettingsLayout>
    <div className="grid grid-cols-12 ">
      <div className="col-span-12 mt-12 lg:mt-6 ">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold">Add New Listing</h2>
            {/* <p className='text-sm text-gray-500'>Manage featured ads on the platform</p> */}
          </div>
          
        </div>
      </div>
      <FormProvider {...methods}>
        <div className="col-span-12 2xl:col-span-9 mt-6 border  bg-white shadow-sm p-4 rounded-lg">
        <form className="grid lg:grid-cols-12 gap-6"  onSubmit={methods.handleSubmit(onFormSubmit)}>
          <div className="grid lg:grid-cols-12 col-span-12  gap-6">
            <div className="lg:col-span-8">
              <label
                htmlFor="job-title"
                className="form-label font-semibold text-sm"
              >
                Job Title <span className='text-red-600 font-bold '>*</span>
              </label>
              <input
                 {...register("title", {required: true})}
                id="job-title"
                type="text"
                className="form-control placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50"
                placeholder="Enter Job Title"
              />
               {errors.title && <small className='text-red-500'>Title is required</small>}
    
            </div>
            <div className="lg:col-span-4">
              <label
                htmlFor="job-budget "
                className="form-label font-semibold text-sm"
              >
                Budget <span className='text-red-600 font-bold '>*</span>
              </label>
              <div className="relative">
              <input
                id="job-budget"
                {...register("budget", {required: true, pattern: /^\d+$/ })} 
                type="text"
                className="form-control pl-8 placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50"
                placeholder="Enter Budget"
              />
              <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
                <DollarSignIcon className='size-4'/>
              </span>
              </div>
              

               {errors.budget?.type === 'required' && <small className='text-red-500'>Budget is required</small>}
               {errors.budget?.type === 'pattern' && <small className='text-red-500'>Budget must be a number</small>}
            </div>
          </div>
          <div className='col-span-12'>
          <div className=" grid lg:grid-cols-12 gap-4">
            <div className='lg:col-span-6'>
            <label
                htmlFor="job-category "
                className="form-label text-sm font-semibold"
              >
              Category <span className='text-red-600 font-bold '>*</span>
              </label>
            {isLoading ? (
              <div className=" col-span-3">
                <CustomSelect
                  name={"category"}
                  label=""
                  placeholder=" Select Category"
                  options={[]}
                />
              </div>
            ) : (
              <div className=" col-span-3">
                <AutoSearch 
                searchResult={data?.results || []} 
                handleSelect={handleServiceSelect} 
                getOptionLabel={(option: any) => option?.name as string} 
                handleType={handleChange} 
                label={"Select or search for category"} />
              </div>
            )}
            </div>
            <div className="lg:col-span-6">
                   <label htmlFor="location" className="form-label font-semibold text-sm">Location/Zip Code <span className='text-red-600 font-bold '>*</span></label>
                   <AutoSearch searchResult={searchResults || []} handleSelect={handleLocationChange} getOptionLabel={(option: any) => option?.place_name as string} handleType={setSearchValue} label={"Location/Zip Code"} />
            </div>
          </div>
          </div>
          <div className="col-span-12 ">
               <div className="grid lg:grid-cols-12 gap-6">
                 <div className="lg:col-span-4">
                   <label htmlFor="date" className="form-label font-semibold text-sm">Deadline <span className='text-red-600 font-bold '>*</span></label>
                   <div className="relative w-full mx-auto">
                        <input 
                        type="date" 
                        min={dayjs().format('YYYY-MM-DD')}
                        className='form-control placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50'
                         {...register("preferred_date", {required: true })}  
                         name="preferred_date" id="preferred_date" 
                        />
                {/* <div className="absolute top-0 right-0  w-10 h-full flex items-center justify-center bg-transparent text-slate-500 ">
       <Calendar className="w-4 h-4" />
   </div> */}
</div>
                 </div>
                
                 {/* <div className="lg:col-span-4">
              <label
                htmlFor="job-address "
                className="form-label font-semibold text-sm"
              >
                Address <span className='text-red-600 font-bold '>*</span>
              </label>
              <input
                id="job-address"
                {...register("address", {required: true })} 
                type="text"
                className="form-control placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50"
                placeholder="Enter Address"
              />
               {errors.address && <small className='text-red-500'>Address is required</small>}
            </div> */}
               </div>

             </div>
          <div className="col-span-12">
              <label
                htmlFor="job-description"
                className="form-label font-semibold text-sm"
              >
                Job Description <span className='text-red-600 font-bold '>*</span>
              </label>
              <textarea
               {...register("description", {required: true})}
                id="job-description"
                rows={4}
                className="form-control placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50"
                placeholder="Give a detailed description of what this job is about..."
              />
              {errors.description && <small className='text-red-500'>Description is required</small>}
            </div>
           
          <div className="col-span-12">
            <div>
            <span className="font-bold text-sm">
              Upload up to 5 images or file
            </span>
          <FileUploader
              name={`pictures`}
              id={`file-input-cac`}
              snapType={""}
              isMultiple={true}
              variant="type3"
              slidesToScroll={1}
              slidesToShow={1}
            />
            
            </div>
         
          
          </div>
          
          <div className="col-span-12 py-5">
            <div className="flex justify-end gap-4">
              <Button size={"sm"} type='button' onClick={() => navigate('/listings')}  variant={"outline"}  className="btn btn-rounded-sm btn-secondary lg:px-5  ">Cancel</Button>
              <Button disabled={loading} size={"sm"} className="btn btn-rounded-sm bg-blue-500 text-white">Create Listing</Button>
            </div>
          </div>
        </form>
        </div>
      
      </FormProvider>

     <LoginForm  openLogin={openForm} setOpenLogin={setOpenForm} redirect={window.location.pathname}/>
      
    </div>
    </SettingsLayout>
  );
}

export default AddListing