import { AutoSearch } from 'component/oldcomponent/HeroSection/AutoSearch';
import { SettingsLayout } from 'context/settings/layout/SettingsLayout';
import { Dropdown } from 'flowbite-react';
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'ui/button';
import { Card } from 'ui/card';
import { formatDate } from "utils/formatDate";
import Api from 'utils/auth/api';
import { useQuery } from '@tanstack/react-query';
import { getSkill } from 'services';
import { debounce } from 'lodash';
import axios from 'axios';
import { notify } from 'shared';
import dayjs from 'dayjs';

function Listing() {
  const [date, setDate] = useState<any>();
  const [listing, setListing] = useState<any>({})
  const [openModal, setOpenModal] = useState(false);
  const [edit, setEdit] = useState<boolean>(false)
   const [loading, setLoading] = useState(false)
    const [service, setService] = useState<any>({category: ""})
    const [term, setTerm] = useState("");
    const [searchResults, setSearchResults] = useState<any>([]);
    const [location, setLocation] = useState({geometry: {coordinates: []}, place_name: ""})
    const [searchValue, setSearchValue] = useState("")
  const navigate = useNavigate()
  const {id} = useParams();
  const methods = useForm<any>({
    defaultValues: {
      title: listing?.title,
      description: listing?.description,
      budget: listing?.budget,
      preferred_date: listing?.preferred_date
      // min_cost: single_package?.min_cost,
      // max_cost: single_package?.max_cost,
      // plan_type: single_package?.plan_type,
      // period: single_package?.period,
      // duration: single_package?.duration,
      // perk: single_package?.perk || [],
      // is_active: single_package?.is_active
    }
  });
  const { register,  formState: { errors }, control, reset } = methods
  
  const { data } = useQuery({
    queryKey: ["service"],
    queryFn: () => getSkill(term),
    enabled: !!term,
  });
  const getSingleListing = async (id: any) => {
    const {data} = await Api.get(`/job/${id}/`);
    setListing(data)
    reset(data);
  }

  useEffect(() => {
    getSingleListing(id)
  }, [id])

    useEffect(() => {
        // Debounce the search function to reduce API calls
        const delayedSearch = debounce(() => {
          if (searchValue.trim() !== "") {
            const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
              searchValue,
            )}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}`;
    
            axios.get(url)
              .then((response: any) => {
                setSearchResults(response.data.features);
              })
              .catch((error: any) => {
                console?.error(error);
              });
          } else {
            setSearchResults([]);
          }
        }, 300);
    
        delayedSearch();
    
        // Clean up the debounce function on unmount
        return () => delayedSearch.cancel();
      }, [searchValue]);

  const handleLocationChange = (_: any, value: any) => {
    setLocation(value);
  };

  const handleServiceSelect = (_: any, value: any) => {
    setService(value)
  }
  const handleChange = (term: string) => {
    setTerm(term);
  };

  const onFormSubmit = async (payload: any) => {
    const formData = new FormData();
        formData.append("budget", payload.budget);
        formData.append("title", payload.title);
        formData.append("description", payload.description);
        formData.append("preferred_date", payload.preferred_date);

        // payload?.pictures.forEach((item: any, index: any) => {
        //   formData.append(`images[${index}]`, item);
        // });
        if(service?.id){
          formData.append("category", service?.id);
        }
        if(location.geometry.coordinates[0] && location.geometry.coordinates[1]){
          formData.append("longitude", location.geometry.coordinates[0] )
          formData.append("address", location?.place_name)
          formData.append("latitude", location.geometry.coordinates[1])
        }
        if (payload?.pictures && payload.pictures.length > 0) {
          payload.pictures.forEach((item: any, index: any) => {
            formData.append(`images[${index}]`, item);
          });
        }
        try {
          const response = await Api.patch(`/job/${id}/`, formData);
          if(response.status === 200){
            notify.success({ message: "job updated successfully" });
            setEdit(!edit)
            getSingleListing(id)
          }
        } catch (error: any) {
          notify.error({message: error?.response.data.error})
        }
        setLoading(false)
  }

  return (
    <SettingsLayout>
    <div className="grid grid-cols-12 ">
      <div className="col-span-12 mt-12 lg:mt-6 ">
        <div className="flex flex-col lg:flex-row space-y-3  lg:items-center">
          <div>
            <h2 className="text-2xl font-bold">{listing?.title}</h2>
            {/* <p className='text-sm text-gray-500'>Manage featured ads on the platform</p> */}
          </div>

          <div className="ml-auto flex items-center gap-x-3">
            status: 
            <div>
            <Dropdown label="Active"  size="sm"  renderTrigger={() => <span className="flex text-[14px] capitalize rounded-[50px] justify-center items-center gap-2 w-[170px] cursor-pointer h-[36px] bg-[#F1F5F9]"></span>}>
        <Dropdown.Item>Active</Dropdown.Item>
        <Dropdown.Item>In-Active</Dropdown.Item>
      </Dropdown>
      
           
            </div>
            <div>
          
            </div>
          </div>
          <div>
            <Button size="sm"  onClick={() => setEdit(!edit)}>
              Edit
            </Button>
          </div>
        </div>
      </div>
      <FormProvider {...methods} >
      <div className="col-span-12 2xl:col-span-9 mt-6 border  bg-white shadow-sm p-4 rounded-lg">
        <form className="grid grid-cols-12 gap-6" onSubmit={methods.handleSubmit(onFormSubmit)}>
          <div className="grid lg:grid-cols-12 col-span-12  gap-6  pb-4">
            <div className="lg:col-span-8">
              <label
                htmlFor="job-title"
                className="form-label font-semibold"
              >
                Job Title
              </label>
              <input
                id="job-title"
                type="text"
                disabled={!edit}
                {...register("title", { required: true })}
                className="form-control placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50"
                placeholder="Enter Job Title"
              />
            </div>
            <div className="lg:col-span-4">
              <label
                htmlFor="job-budget"
                className="form-label font-semibold"
              >
                Budget
              </label>
              <input
                id="job-budget"
                disabled={!edit}
                type="text"
                {...register("budget", { required: true })}
                className="form-control placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50"
                placeholder="Enter Budget"
              />
            </div>

            
          </div>
          <div className='col-span-12'>
          <div className=" grid lg:grid-cols-12 gap-4">
            <div className='lg:col-span-6'>
            <label
                htmlFor="job-category "
                className="form-label text-sm font-semibold"
              >
              Category <span className='text-red-600 font-bold '>*</span>
              </label>
            
           <div>
            {edit ?    <AutoSearch 
                                        searchResult={data?.results || []} 
                                        handleSelect={handleServiceSelect} 
                                        getOptionLabel={(option: any) => option?.name as string} 
                                        handleType={handleChange} 
                                        label={"Select or search for category"} /> : 
           <input 
           type="text"
           disabled 
                        className='form-control placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50'
                         {...register("category")}  
                         name="category" id="preferred_date" 
                        />}
                     
           </div>
            </div>
            <div className="lg:col-span-6">
                   <label htmlFor="location" className="form-label font-semibold text-sm">Location/Zip Code <span className='text-red-600 font-bold '>*</span></label>
                   <div>
                    {edit ?
                    <AutoSearch searchResult={searchResults || []} handleSelect={handleLocationChange} getOptionLabel={(option: any) => option?.place_name as string} handleType={setSearchValue} label={"Location"} />
                  :
                   <input 
           type="text"
           disabled 
                        className='form-control placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50'
                         {...register("address")}  
                         name="address" id="preferred_date" 
                        />}
                   </div>
            </div>
          </div>
          </div>
          <div className="col-span-12 ">
               <div className="grid lg:grid-cols-12 gap-6">
                 <div className="lg:col-span-6">
                   <label htmlFor="date" className="form-label font-semibold text-sm">Deadline <span className='text-red-600 font-bold '>*</span></label>
                   <div className="relative w-full mx-auto">
                    {!edit ? 
                   <input 
                   disabled
                        type="text" 
                        value={formatDate(listing?.preferred_date, true)}
                        className='form-control placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50'
                        //  {...register("preferred_date", {required: true })}  
                         name="preferred_date" id="preferred_date" 
                        /> :
                        <input 
                        type="date" 
                        min={dayjs().format('YYYY-MM-DD')}
                        className='form-control placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50'
                         {...register("preferred_date")}  
                         name="preferred_date" id="preferred_date" 
                        />}
                
</div>
                 </div>
                
                 {/* <div className="lg:col-span-4">
              <label
                htmlFor="job-address "
                className="form-label font-semibold text-sm"
              >
                Address <span className='text-red-600 font-bold '>*</span>
              </label>
              <input
                id="job-address"
                {...register("address", {required: true })} 
                type="text"
                className="form-control placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50"
                placeholder="Enter Address"
              />
               {errors.address && <small className='text-red-500'>Address is required</small>}
            </div> */}
               </div>

             </div>
          <div className="col-span-12">
              <label
                htmlFor="job-description"
                className="form-label font-semibold"
              >
                Job Description
              </label>
              <textarea
                id="job-description"
                {...register("description", { required: true })}
                rows={4}
                disabled={!edit}
                className="form-control placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50"
                placeholder="Give a detailed description of what this job is about..."
              />
            </div>
          
          <div className="col-span-12">
          {/* <FileUploader
              name={`pictures`}
              id={`file-input-cac`}
              snapType={""}
              isMultiple={true}
              variant="type2"
            /> */}
<div className="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            {listing?.images && listing?.images?.map((img: any) => (
              <Card  key={img.id} className=" w-full">
                  <img
                  key={img.id}
                  src={img?.image_url}
                  alt="service img"
                  className="w-full h-full bg-contain "
                />
               
              </Card>
               ))}
            </div>
          </div>
         
          <div className="col-span-12 pb-5">
            <div className="flex justify-end gap-4">
              <Button size={"sm"} type='button' onClick={() => navigate('/listings')}  variant={"outline"}  className="btn btn-rounded-sm btn-secondary px-5  ">Cancel</Button>
              <Button size={"sm"} className="btn btn-rounded-sm bg-blue-500 text-white">Update Listing</Button>
            </div>
          </div>
        </form>
      </div>
      </FormProvider>
     

      
    </div>
    </SettingsLayout>
  );
}

export default Listing