import { ReactComponent as ProfileIcon } from "assets/svg/profile.svg";
import { ListChecksIcon, LucideHelpingHand } from "lucide-react"
import { FaUserEdit, FaUsers } from "react-icons/fa";
import { CiCreditCard1 } from "react-icons/ci";
import { MdOutlineFeaturedPlayList } from "react-icons/md";
import { ReactComponent as SettingsIcon } from "assets/svg/setting-2.svg";
import { ReactComponent as NotificationIcon } from "assets/svg/notification.svg";


export const NavigationTab = [
  { title: "Dashboard", icon: <ProfileIcon />, path: "/profile" },
  {
    title: "My Services",
    icon: <LucideHelpingHand />,
    path: "/myservice",
  },
  {
    title: "Update Profile",
    icon: <FaUserEdit  size={27}/>,
    path: "/update-profile",
  },
  {
    title: "Settings",
    icon: <SettingsIcon />,
    path: "/settings",
  },
  {
    title: "Leads",
    icon: <FaUsers size={27}/>,
    path: "/leads",
  },
  {
    title: "Listings",
    icon: <ListChecksIcon size={27} />,
    path: "/listings",
  },
  {
    title: "Notification",
    icon: <NotificationIcon />,
    path: "/notification",
  },
  {
    title: "Featured Ads",
    icon: <MdOutlineFeaturedPlayList size={27} />,
    path: "/featured_pricing",
  },
  // {
  //   title: "Payment Methods",
  //   icon: <CiCreditCard1 size={27} />,
  //   path: "/payment_methods",
  // },
  
  
];