import { useQuery } from "@tanstack/react-query";
import { getServiceProviderProfileById, getServiceProviderReviews } from "services";

export const useGetServiceProfileById = (id: string) => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["service-provider-profiles"],
    queryFn: () => getServiceProviderProfileById(id),
  });

  return { serviceProfileLoading: isLoading, serviceProfileData: data, refetchServiceProfile: refetch};
};

export const useGetServiceProfileReviews = (id: string) => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["service-provider-reviews"],
    queryFn: () => getServiceProviderReviews(id),
  });

  return { serviceReviewLoading: isLoading, serviceReviewData: data, refetchServiceProfileReviews: refetch};
};