/* eslint-disable @typescript-eslint/no-unused-vars */
// MetaTags.tsx
import React from 'react';
import { Helmet } from 'react-helmet-async';

interface MetaTagsProps {
    title?: string | any;
    description?: string;
    image?: string;
    url?: string;
    siteName?: string;
    // twitterHandle: string;
}

// eslint-disable-next-line import/no-default-export
export default function MetaTags({ 
    title, 
    description, 
    image, 
    url, 
    siteName, 
    // twitterHandle 
}: MetaTagsProps){
    return (
        <Helmet>
            <title> {title} - Audtiklance</title>
            <meta name="description" content="Discover Audtiklance, the ultimate platform for freelancers and businesses to connect and grow together." />
            
            <meta property="og:title" content={title +'-'+ 'Audtiklance'} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content="Discover Audtiklance, the ultimate platform for freelancers and businesses to connect and grow together." />
            
            {/* <meta property="og:image" content="https://audtiklance.com/logo-main.png" /> */}
            {/* <meta property="og:url" content={url} /> */}
            <meta name="twitter:card" content='website' />
            <meta name="twitter:title" content="Audtiklance - Empowering Your Success" />
            <meta name="twitter:description" content="Discover Audtiklance, the ultimate platform for freelancers and businesses to connect and grow together." />
            <meta name="twitter:site" content="@audtiklance" />
            <meta name="twitter:creator" content="@audtiklance" />
            <meta name="twitter:image" content="https://audtiklance.com/logo-main.png" />

        </Helmet>
    );
};

