/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { PostCodeIcon, PadLockIcon, GoogleIcon } from "assets/svg";
import { ReactComponent as EmailIcon } from "assets/svg/emailIcon.svg";
import { ReactComponent as ProfileIcon } from "assets/svg/username.svg";
import { FormProvider, useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { passResetReqSchema, signupSchema } from "../authWrapper/schema";
import { ConfirmPassword } from "../confirmPassword";
import { notify } from "shared/notifications/notify";
import { isAuthenticated, setRefreshToken, setToken } from "utils/auth/auth";
import { FormTextInput } from "ui/formTextInput";
import { Checkbox } from "ui/checkBox";
import { Button } from "ui/button";
import { RegistrationCard } from "@component";
import { ICreateEmailType, ISignUpType } from "types";
import { createAnAccount, createAnEmail } from "services";
import { OtpInput } from "ui/otpInput";
import { themeColors } from "Themes/ThemeColors";
import { formatTime } from "utils/formatDate";
import { useFormStore } from "store/formStore";
import { SuccessModal } from "component/profile/profileCreation/SuccessModal";
import { SignUpSuccessModal } from "./SignUpSuccessModal";
import { useNavigate } from 'react-router-dom';

export interface IFormType {
  email: string;
  firstname: string;
  lastname: string;
  postCode: string;
  phone: string;
  password: string;
  countryCode: string;
  terms: boolean;
  otp?: string;
  captchaToken: string;
}

const defaultValues: IFormType = {
  email: "",
  firstname: "",
  lastname: "",
  phone: "",
  countryCode: "234",
  password: "",
  postCode: "",
  terms: false,
  otp: "",
  captchaToken: "",
};

export const SignUp = ({ openSignupForm, setOpenSignUp }: any) => {
  const queryClient = useQueryClient();
  const [secretKey, setSecretKey] = useState("");
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [otpModal, setOtpModal] = useState<string | boolean>("");
  const [modal, setModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [otp, setOtp] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate()

  const handleVerify = (token: string | null) => {
    setCaptchaToken(token);
    methods.setValue("captchaToken", token);
  };
  const [interval, setCountdown] = useState<boolean>(false);
  const [timeSpan, setTimeSpan] = useState<number>(0);
  const methods = useForm<any>({
    mode: "onChange",
    defaultValues,
  });

  // const selected = useFormStore((state: any) => state.selected);

  const otpMethod = useForm({
    mode: "onChange",
    // resolver: yupResolver(passResetReqSchema),
    // defaultValues,
  });

  const otpRequestMethod = useForm({
    mode: "onChange",
    // resolver: yupResolver(passResetReqSchema),
  });

  const { phone } = methods.watch();
  const { errors } = methods.formState;
  // const { otp } = otpMethod.watch();
  const { mutate, isPending: isFetching } = useMutation({
    mutationFn: async (payload: any) => await createAnAccount({ ...payload }),
    onSuccess: async ({ data }) => {
      // window.location.reload();
      // console.log("data", data);
      setSubmitting(false);
      // setOpenConfirmPassword(false);
      setOtpModal("");
      // setModal(true);
      setOpenSignUp(false);
      setToken(data?.access);
      setRefreshToken(data?.refresh);
      queryClient.refetchQueries({
        queryKey: ["get-user-profile"],
      });
      isAuthenticated();
      notify.success({
        subtitle: "Successfully created",
        message: "Welcome back champ",
      });
      // redirect to profile page
      navigate("/search");
    },
    onError: async ({ response }: any) => {
      setSubmitting(false);
      notify.error({
        message: "Error",
        subtitle: response?.data?.error || "",
      });
    },
  });

  const { mutate: emailMutate, isPending: isLoading } = useMutation({
    mutationFn: async (payload: ICreateEmailType) =>
      await createAnEmail({ ...payload }),
    onSuccess: async (response) => {
      setOpenSignUp(false);
      setOtp(response?.data);
      setSecretKey(response?.data);
      setOtpModal("confirmOtp");
      // setOpenConfirmPassword(true);
      notify.success({
        message: "Success",
        subtitle: "sent successfully",
      });
    },
    onError: async ({ response }: any) => {
      notify.error({
        message: "Error",
        subtitle: response?.data?.error || "",
      });
    },
  });

  // const { mutate: otpMutate } = useMutation({
  //   mutationFn: async (payload: IOtpType) => await confirmOTP({ ...payload }),
  //   onSuccess: async (response) => {
  //     setOpenConfirmPassword(false);
  //     handleSignUp(false);
  //     handleOpenLogin(true);
  //   },
  //   onError: async (response) => {
  //     notifications.show({
  //       message: response?.message,
  //     });
  //     // setOpenConfirmPassword(false);
  //   },
  // });

  // const onSubmitEmail = (payload: any) => {
  //   // emailMutate({
  //   //   email: payload?.email,
  //   // });

  //   mutate({
  //     country_code: payload?.countryCode,
  //     email: payload?.email,
  //     lastname: payload?.lastname,
  //     firstname: payload?.firstname,
  //     password: payload?.password,
  //     phone: payload?.phone,
  //     post_code: payload?.postCode,
  //     otp: payload?.otp!,
  //     secret_key: secretKey,
  //   });
  // };

  const onSubmit = (payload: IFormType) => {
    setSubmitting(true);
    mutate({
      country_code: payload?.countryCode,
      email: payload?.email,
      firstname: payload?.firstname,
      lastname: payload?.lastname,
      password: payload?.password,
      phone: payload?.phone,
      post_code: payload?.postCode,
      // otp: otpMethod?.getValues("otp"), returns as array of numbers
      otp: otpMethod?.getValues("otp").join(""),
      secret_key: secretKey,
    });
  };

  const validateEmail = async (payload: any) => {
    setLoad(true);
    emailMutate({
      email: payload?.email,
    });

    // try {
    //   const res = await emailMutate({
    //     email: payload?.email,
    //   });

    //   if (res?.success) {
    //     setOtpModal("confirmOtp");
    //   }
    //   setLoad(false)
    // } catch (error) {
    //   setLoad(false)
    // }
    // console.log('res', res)
  };

  const handleResendOtp = async () => {
    if (timeSpan === 0) {
      // setOtpLoading(true);
      setCountdown(!interval);
    }
    await emailMutate({
      email: methods?.getValues("email"),
    });
  };

  useEffect(() => {
    let timerInterval: any;
    if (otpModal) {
      const startTime = Date.now();
      const endTime = new Date(startTime);
      endTime.setMinutes(endTime.getMinutes() + 2);

      const calculateTimeSpan = () => {
        const remainingTime = endTime.getTime() - Date.now();
        setTimeSpan(() => Math.max(0, remainingTime));
      };

      calculateTimeSpan();

      timerInterval = setInterval(() => {
        calculateTimeSpan();
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [interval, otpModal]);

  return (
    <>
      <RegistrationCard
        show={openSignupForm === "signup"}
        onClose={() => setOpenSignUp("")}
      >
        <div className="flex justify-center w-full">
          <span className="text-xl font-bold">Sign up to Audtiklance</span>
        </div>
        <FormProvider {...methods}>
          <form
            className="flex flex-col w-full gap-5 mt-6"
            onSubmit={methods.handleSubmit(validateEmail)}
          >
            <FormTextInput
              placeholder="First name"
              icon={<ProfileIcon />}
              className="text-xs "
              {...methods.register("firstname")}
            />
            <FormTextInput
              placeholder="Last name"
              icon={<ProfileIcon />}
              className="text-xs "
              {...methods.register("lastname")}
            />
            <FormTextInput
              placeholder="Email address"
              icon={<EmailIcon />}
              {...methods.register("email")}
            />

            <div className="flex items-start w-full gap-3">
              <div className="w-auto flex-col mt-[7px] flex items-center border rounded-[9px] ">
                <PhoneInput
                  country={"ng"}
                  buttonStyle={{
                    border: "none",
                    backgroundColor: "transparent",
                    borderRadius: "30px",
                  }}
                  inputStyle={{
                    border: "none",
                    width: "100px",
                    borderRadius: "8px",
                    height: "42px",
                  }}
                  containerStyle={{
                    display: "flex",
                    alignItems: "center",
                    border: "none",
                    borderRadius: "30px",
                  }}
                  onChange={(value) => {
                    methods.setValue(
                      "countryCode",
                      value
                    );
                  }}
                />
                {errors?.countryCode?.message && (
                  <div className="mt-1 text-xs text-red-600">
                    {/* {errors.countryCode.message} */}
                  </div>
                )}
              </div>
              <div className="w-full">
                <FormTextInput
                  placeholder="Phone number"
                  type="number"
                  {...methods.register("phone")}
                  style={{ padding: 10 }}
                />
              </div>
            </div>

            <FormTextInput
              placeholder="Password"
              className="w-full h-full"
              icon={<img src={PadLockIcon} alt="padlock-icon" />}
              type="password"
              {...methods.register("password")}
            />
            <FormTextInput
              placeholder="Postcode"
              icon={<img src={PostCodeIcon} alt="postcode-icon" />}
              className="text-xs font-normal "
              {...methods.register("postCode")}
            />
            <div>
              <Checkbox
                labelText="I accept the terms and agreements"
                {...methods.register("terms")}
              />
            </div>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY!}
              onChange={handleVerify}
            />
            <Button className="mt-4" type="submit" loading={isFetching}>
              Sign up
            </Button>
          </form>
        </FormProvider>
        <div>
        <div className="flex flex-col items-center w-full my-3">
          <span className="text-sm text-[#7A7B7F]">Or Login with</span>
          <div className="flex flex-col w-full gap-3 my-4">
            <Button
              style={{
                fontWeight: 400,
                color: "#212329",
                backgroundColor: "#F6F6F6",
              }}
              size="sm"
              onClick={() => {
                const url = ` https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URL}&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&scope=openid%20email%20profile&access_type=online&response_type=token`;
                window.open(url, "_self");
              }}
            >
              <img src={GoogleIcon} alt="google icon" />{" "}
              <span> Continue with Google</span>
            </Button>
           
          </div>
        </div>
        </div>
        <div className="flex justify-center w-full mt-3">
          <span className="text-sm ">
            Already have an account?{" "}
            <span
              onClick={() => setOpenSignUp("login")}
              className=" text-[#4188F9] cursor-pointer"
            >
              Login
            </span>
          </span>
        </div>
      </RegistrationCard>
      <div>
        <ConfirmPassword
          openConfirmPassword={openSignupForm}
          setOpenConfirmPassword={setOpenSignUp}
          otpMethod={methods}
          onSubmit={onSubmit}
          phone={phone}
          handleSignUp={setOpenSignUp}
          resendOTP={emailMutate}
          isLoading={isFetching || isLoading}
          requestOTPMethod={otpRequestMethod}
        />
      </div>
      <RegistrationCard
        show={otpModal === "confirmOtp"}
        onClose={() => setOtpModal("")}
      >
        <div className="flex flex-col items-center w-full gap-2">
          <span className="text-xl font-bold">Confirm your Email Address</span>
          <span className="text-sm text-center">
            We have sent an OTP to your email,
          </span>
        </div>
        <FormProvider {...otpMethod}>
          <form className="flex flex-col items-center w-full mt-6 ">
            <OtpInput
              name="otp"
              length={6}
              // size="md"
              type="number"
              onChange={(value: any) => otpMethod.setValue("otp", value)}
              className="my-4"
            />
          </form>
        </FormProvider>
        <div>
          <span
            className="my-3 text-sm cursor-pointer"
            onClick={handleResendOtp}
          >
            Didn’t receive a code? Resend{" "}
            {timeSpan !== 0 && formatTime(timeSpan.toString())}
          </span>
        </div>
        <Button
          className="w-full mt-5 font-normal"
          color={themeColors.navbarBtnBlue}
          style={{ width: "100%" }}
          type="submit"
          disabled={submitting}
          loading={submitting}
          onClick={() => onSubmit(methods?.getValues())}
        >
          {/* {timeSpan === 0
          ? "Resend OTP Verification"
          : "Confirm OTP Verification"} */}
          Confirm OTP Verification
        </Button>
      </RegistrationCard>
      <SignUpSuccessModal modal={modal} />
    </>
  );
};
