import { SetStateAction, useEffect, useState } from "react";
// import { ReactComponent as InfoIcon } from "assets/svg/info.svg";
import { useGetProfileById } from "hooks";
import { Modal } from "flowbite-react";
import { MapPin, X } from "lucide-react";
import { Avatar } from "ui/avatar";

export function ProductDetailsModal({
  id,
  open,
  setDrawer,
  services
}: {
  img: string;
  open: boolean;
  id: string;
  setDrawer: React.Dispatch<SetStateAction<boolean>>;
  services?: any[];
}) {

  const [userId, setUserId] = useState<string>("");
  const { singleProfileData } = useGetProfileById(userId);

  useEffect(() => {
    setUserId(id as string)
  }, [id])
   return (
    <Modal dismissible show={open} onClose={() => setDrawer(false)}>
    <Modal.Body>
      <div className="space-y-6 relative">
        <button type="button" onClick={() => setDrawer(false)} className="absolute right-0">
          <X />
        </button>
      <div className="flex flex-col gap-2 items-center ">
         <Avatar
            src={singleProfileData?.profile_picture}
            fallbackText={singleProfileData?.firstname}
            className="w-[clamp(80px,_10vw,_80px)] font-medium text-black text-center"
            size={80}
          />
      {/* <img  referrerPolicy="no-referrer" src={singleProfileData?.profile_picture} alt={singleProfileData?.firstname} className="w-20 h-20 rounded-full" /> */}
      <h2 className="text-slate-800 capitalize text-base">{singleProfileData?.firstname || singleProfileData?.lastname ? `${singleProfileData.firstname ?? ' '} ${singleProfileData.lastname ?? ' '}` : ''}</h2>       </div>
        <div className="flex justify-center text-base leading-relaxed text-gray-500 dark:text-gray-400"> 
        <MapPin /> 
        <span className="0">
          {singleProfileData?.location ?? 'N/A'}
        </span>
        </div>
        <div className="flex justify-center flex-wrap gap-1">
                    {services?.map((item: any) => (
                      <span
                        key={item.id}
                        className="bg-gray-100 text-gray-700 text-xs font-semibold px-2 py-1 rounded-full"
                      >
                        {item?.sub_category}
                      </span>
                    ))}
                  </div>
        <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
          {singleProfileData?.about_me}
        </div>
      </div>
    </Modal.Body>
  </Modal>
   
  );
}
