/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

/**
 * Fetch place information using Mapbox API based on longitude and latitude.
 * 
 * @param {number} longitude - The longitude of the location.
 * @param {number} latitude - The latitude of the location.
 * @returns {Promise<object>} - A promise that resolves to the place information.
 */
export const getPlaceInfo = async (longitude: any, latitude: any) => {
  if (!longitude || !latitude) {
    throw new Error("Longitude and latitude are required.");
  }

  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch place information.");
  }
};
