/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import emptyImage from "../../assets/images/empty_image.png";
import locationIcon from "../../assets/svg/location.svg";
import starIcon from "../../assets/svg/Star.svg";
import { Avatar } from "ui/avatar";
import { Button } from "ui/button";
import { useNavigate } from "react-router-dom";
import { ErrorType, IFavoriteServiceData, SUB_CATEGORY } from "types";
import { LoginForm } from "pages/auth";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { notify } from "shared";
import { addFavouriteProviderFn, getProfileById, removeFavouriteProviderFn } from "services";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { convertToMiles } from "shared/maskNumber";
import { useGetServiceProfileById } from "hooks/profile/useGetServiceProfileById";
import { useGetProfileById } from "hooks";
import { calculateAverageRating, sumRatings } from "utils";
import { Rating } from "@mui/material";
// import { sumRatings } from "utils";

export const ServiceCard: React.FC<{ service: IFavoriteServiceData }> = ({
  service,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [openForm, setOpenForm] = useState("");
  const [user, setUser] = useState<{profile_picture?: string}>({})

   useEffect(() => {
      const fetchUser = async (id: string) => {
        const response = await getProfileById(id)
        setUser(response)
      }
      if(service?.owner_id !== null){
        fetchUser(service?.owner_id)
      }
     
    }, [service?.owner_id])

  const getCost = (subCategory: SUB_CATEGORY[]) => {
    return subCategory?.map((sub) => sub?.cost);
  };

  const { mutate: likeMutate, isPending: isFetching } = useMutation({
    mutationFn: addFavouriteProviderFn,
    onSuccess: async (data) => {
      if (data?.success === true) {
        queryClient.invalidateQueries({
          queryKey: ["service-provider-profiles"],
        });
        notify.success({ message: data.message });
      }
    },
    onError: async (response: ErrorType) => {
      if (response?.response?.status === 401) {
        notify.error({
          message: "Error",
          subtitle: "Unauthenticated, Please login!",
        });
        setOpenForm("login");
        return;
      }
      notify.error({
        message: "Error",
        subtitle: response?.response?.data?.error,
      });
    },
  });

  const favouriteProvider = (id: string) => {
    likeMutate(id);
  };
  const { mutate: removeLikeMutate, isPending: isRemoveFetching } = useMutation(
    {
      mutationFn: removeFavouriteProviderFn,
      onSuccess: async (data) => {
        if (data?.success === true) {
          queryClient.invalidateQueries({
            queryKey: ["service-provider-profiles"],
          });
          notify.success({ message: data.message });
        }
      },
      onError: async (response: ErrorType) => {
        notify.error({
          message: "Error ",
          subtitle: response?.data?.error,
        });
      },
    }
  );

  const removeFavouriteProvider = (id: string) => {
    removeLikeMutate(id);
  };

  return (
    <div
      style={{ width: "100%" }}
      className="flex flex-col gap-4 mb-6 lg:flex-row"
    >
      <div className="lg:w-[377px] w-[100%] h-[214] relative">
        <img
          className="rounded-[14px] lg:h-[214px] h-[250px] lg:w-[377px] w-[100%]"
          src={service?.profile_picture}
          // serviceProfileData?.images[0]?.image_url ||
          //             serviceProfileData?.profile_picture ||
          //             AvatarImage
          alt=""
        />
        <div className="w-[36px] h-[36px] flex justify-center items-center bg-white absolute top-2 right-2 rounded-[10px] cursor-pointer">
          {/* <HeartIcon/> */}
          {service?.is_favorite ? (
            <FaHeart
              onClick={() => removeFavouriteProvider(service?.id)}
              className="text-red-600"
            />
          ) : (
            <FaRegHeart onClick={() => favouriteProvider(service?.id)} />
          )}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div style={{ width: "100%" }} className="flex justify-between">
          <div className="flex gap-2">
            <Avatar 
            fallbackText={service?.owner_name || service?.business_name}
            src={user?.profile_picture ?? service?.profile_picture}
            className="w-[clamp(80px,_10vw,_80px)] font-medium text-black text-center" />
           
            <div>
              <h6 className="font-semibold">{service?.business_name}</h6>
              <p
                className={` flex gap-2  text-[#64748B] text-[12px] lg:text-[16px]`}
              >
                <img src={locationIcon} alt="" />
                {service?.address || "N/A"}{" "}
                {service?.distance
                  ? `- ${convertToMiles(service?.distance)?.toFixed()}  miles away`
                  : null}{" "}
              </p>
              {/* <p className='flex gap-2 text-[#64748B] text-[12px] lg:text-[16px]'><img src={locationIcon} alt="" /> {service?.state}, {service?.country} - 2.3 Km away</p> */}
            </div>
          </div>
          <div className="items-center hidden md:flex gap-x-1 ">
            <Rating
                precision={0.5}
                value={calculateAverageRating(service?.review) || 0} 
                size="small" 
                readOnly
                />
            <p>
                <span className="font-semibold text-[13px] lg:text-[16px]">
                {calculateAverageRating(service?.review) !== 0 ? `${calculateAverageRating(service?.review)}` : "0"}
                </span>{" "}
              ({sumRatings(service?.review) ?? 0 })
           
            </p>
          </div>
        </div>

        <p className="mt-4 text-[#334155] line-clamp-3">{service?.about_me}</p>
        <p className="mt-2">
          From{" "}
          <span className="font-semibold">
            ${Math.min(...getCost(service?.sub_category))}/hr
          </span>
        </p>
        <div className="flex justify-between">
        <Button
          size="sm"
          className="w-[160px] mt-4"
          onClick={() => {
            // if(!userDetails?.data?.fullname){
            //     setOpenFom("login")
            //     return
            // }
            navigate(`/provider/${service?.id}`);
          }}
        >
          Hire me
        </Button>
        <div className="flex items-center md:hidden gap-x-1">
            <Rating
                precision={0.5}
                value={calculateAverageRating(service?.review) || 0} 
                size="small" 
                readOnly
                />
            <p>
                <span className="font-semibold text-[13px] lg:text-[16px]">
                {calculateAverageRating(service?.review) !== 0 ? `${calculateAverageRating(service?.review)}` : "0"}
                </span>{" "}
              ({sumRatings(service?.review) ?? 0 })
           
            </p>
          </div>
        </div>
       
      </div>
      <LoginForm
        loginPrompt="to hire this provider!"
        openLogin={openForm}
        setOpenLogin={setOpenForm}
        redirect={window.location.pathname}
      />
    </div>
  );
};
