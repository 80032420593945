import { QueryClient, useMutation } from "@tanstack/react-query";
import { createServiceProviderProfile, reviewServiceProviderProfile, updateServiceProviderProfile } from "services";
//import { createServiceProviderProfile } from "services/authServices/services";
import { notify } from "shared";

export const useCreateServiceProviderProfile = () => {
  const queryClient = new QueryClient();
  const {
    mutateAsync: createServiceProviderMutation,
    isPending: isCreatingServiceProvider,
  } = useMutation({
    mutationFn: createServiceProviderProfile,
    onSuccess: async (data) => {
      if (data?.success) {
        queryClient.invalidateQueries({
          queryKey: ["service-provider-profile"],
        });
        // notify.success({ message: "user updated successfully" });
      }
      notify.success({ message: "user updated successfully" });
    },
    onError: async (err) =>
      notify.error({
        message: "Error while updating user information",
        subtitle: err?.message,
      }),
  });

  return {
    createServiceProviderMutation,
    isCreatingServiceProvider,
  };
};

export const useUpdateServiceProviderProfile = () => {
  const queryClient = new QueryClient();
  const {
    mutateAsync: updateServiceProviderMutation,
    isPending: isUpdatingServiceProvider,
  } = useMutation({
    mutationFn: updateServiceProviderProfile,
    onSuccess: async (data) => {
      if (data?.response?.success === true) {
        queryClient.invalidateQueries({
          queryKey: ["service-provider-profile"],
        });
        notify.success({ message: "business updated successfully" });
      }
      
     
    },
    onError: async (err) =>
      notify.error({
        message: "Error while updating user information",
        subtitle: err?.message,
      }),
  });

  return {
    updateServiceProviderMutation,
    isUpdatingServiceProvider,
  };
};

// export const useReviewServiceProviderProfile = (id) => {
//   const queryClient = new QueryClient();
//   const {
//     mutateAsync: reviewServiceProviderMutation,
//     isPending: isReviewingServiceProvider,
//   } = useMutation({
//     mutationFn: reviewServiceProviderProfile,
//     onSuccess: async (data) => {
//       console.log('data', data)
//       // if (data?.response?.success === true) {
//       //   queryClient.invalidateQueries({
//       //     queryKey: ["service-provider-profile"],
//       //   });
//         notify.success({ message: "business reviewed" });
//         // notify.success({ message: "user updated successfully" });
//       // }
//     },
//     onError: async (err) =>
//       notify.error({
//         message: "Error while sending review",
//         subtitle: err?.message,
//       }),
//   });

//   return {
//     reviewServiceProviderMutation,
//     isReviewingServiceProvider
//   };
// };