import { IJobResponseData, IJobResultData } from "types/job";
import Api from "utils/auth/api";

export const getJobsFn = async () => {
  return await Api.get<IJobResponseData>(`/job/?status=OPEN`).then((res) => res.data);
};

export const getMyJobsFn = async () => {
  return await Api.get<IJobResponseData>(`/job/my-jobs/`).then((res) => res.data);
};

export const deleteSingleJob = async (id: any) => {
  return await Api.delete<any>(`/job/${id}/`).then((res) => res);
};

export const getJobFn = async (id: any) => {
  return await Api.get<IJobResultData>(`/job/${id}/`).then((res) => res.data)
}

export const createJobFn = async (data: any) => {
  return await Api.post<any>("/job/", data).then((res) =>{
   return res
  }).catch((err) =>{ 
    return err.response
  });
};
export const bidForJobFn = async (data: any) => {
  return await Api.post<any>(`/job/${data?.id}/bid/`, data).then(
    (res) => res.data,
  );
};
