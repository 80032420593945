import { FC, useEffect, useState } from "react";

import {
  Blog,
  Explore,
  FAQ,
  Footer,
  HeroSection,
  LogosCarousel,
  OtherServices,
} from "@component";
import { getCurrentLocation } from "shared/getLocation";
import AppDownload from "./AppDownload";

interface HomepageProps {
  prop1?: string
}
export const Homepage: FC<HomepageProps> = () => {
  const [currentLocation, setCurrentLocation] = useState<{ latitude: any; longitude: any }>({
    latitude: '',
    longitude: '',
  });
  const [error, setError] = useState<{message: string}>();

  useEffect(() => {
    getCurrentLocation()
    .then((location) => { 
       setCurrentLocation({latitude: location.latitude, longitude: location.longitude})
    })
    .catch(error => setError(error));
}, []);


  return (
    <>
      {/* check home page */}
      <HeroSection />
      <AppDownload />
      <Explore location={currentLocation} />
      <FAQ />
      {/* <Blog /> */}
      <OtherServices />
      

      <Footer prop1={"string"} />
    </>
  );
};
