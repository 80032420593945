import { QueryClient } from '@tanstack/react-query';
import { useDeleteJob, useGetMyJobs } from 'hooks';
import { Edit2Icon, Trash2Icon } from 'lucide-react';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { notify } from 'shared';
import { Button } from 'ui/button';
import Api from 'utils/auth/api';
import { formatDate, formatTime } from "utils/formatDate";


function JobsCard(props: any) {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const queryClient = new QueryClient();
  const { isLoading, myJobsData, refetch } = useGetMyJobs();

  const {deleteJob} = useDeleteJob();
  
   const handleDeleteJob = async (item: any) => {
        const response = await deleteJob({
          id: item?.id
        });
        if(response?.status === 200 || response?.status === 204){
          await refetch()
          queryClient.invalidateQueries({
            queryKey: ["myjobs"],
          });
          notify.success({
          message: "Success ",
          subtitle: 'Job Deleted Successfully',
        })
        }
        else if(response?.status === 400){
          notify.error({
            message: "Error ",
            subtitle: 'Error Deleting Job',
          })
        }
      };

  const handleInitiatePayment = async (id: any) => {
    setLoading(true)
    const response = await Api.post(`/job/${id}/initiate-payment/`);
    console.log('response', response)
    if(response.status === 200){
      setLoading(false)
      window.location.href = response.data.data;
    }
    setLoading(false)
  }

  return (
    <div className="bg-[#FAFCFD] rounded-lg shadow-sm p-6 border border-slate-200 ">
      <div className='flex justify-between'>
        <div className='w-4/5'>
        <Link to={`/listing/${props?.item?.id}`} className="text-lg font-semibold leading-snug">{props?.item?.title}</Link>
        </div>
      <div className=''>
      <span className={`${props?.item?.status == 'OPEN' ? 'bg-green-100 text-green-500' : 'bg-red-100 text-red-500'} inline-block  text-xs font-semibold px-2 py-1 rounded-full mt-2`}>{props?.item?.status}</span>
     {/* {props?.item?.is_paid == false && <span className={`${props?.item?.is_paid == true ? 'bg-green-100 text-green-500' : 'bg-red-100 text-red-500'} inline-block  text-xs font-semibold px-2 py-1 rounded-full mt-2`}>Not Paid</span>} */}
      </div>
      </div>
 
 <div className='flex flex-col gap-2 mt-2'>
  <p className="text-sm text-slate-500 line-clamp-4">{props?.item?.description}</p>
 </div>
  <div className="flex items-center mt-2 gap-2">
    <div className='flex items-center gap-1'>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 7.125V10.125L10.875 11.25M9 3.75C5.47918 3.75 2.625 6.60418 2.625 10.125C2.625 13.6458 5.47918 16.5 9 16.5C12.5208 16.5 15.375 13.6458 15.375 10.125C15.375 6.60418 12.5208 3.75 9 3.75ZM9 3.75V1.5M7.5 1.5H10.5M15.2467 4.19403L14.1217 3.06903L14.6842 3.63153M2.75326 4.19403L3.87826 3.06903L3.31576 3.63153" stroke="#0F172A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    <span className="text-xs text-black font-medium">{formatDate(props?.item?.preferred_date)}</span>
    </div>
 
    <span className="mx-1 text-2xl text-slate-500">•</span>

    <div className='flex items-center gap-1'>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.00016 10.0726C10.2925 10.0726 11.3402 9.02492 11.3402 7.73258C11.3402 6.44023 10.2925 5.39258 9.00016 5.39258C7.70781 5.39258 6.66016 6.44023 6.66016 7.73258C6.66016 9.02492 7.70781 10.0726 9.00016 10.0726Z" stroke="#0F172A" strokeWidth="1.5"/>
<path d="M2.71332 6.3675C4.19082 -0.127498 13.8133 -0.119998 15.2833 6.375C16.1458 10.185 13.7758 13.41 11.6983 15.405C10.1908 16.86 7.80582 16.86 6.29082 15.405C4.22082 13.41 1.85082 10.1775 2.71332 6.3675Z" stroke="#0F172A" strokeWidth="1.5"/>
</svg>

    <span className="text-xs text-black font-medium">{props?.item?.address}</span>
    </div>
    
  </div>
  
  <div className='flex justify-end gap-x-2 mt-2'>
    <Button onClick={() => navigate(`/listing/${props?.item?.id}`)} size="sm" className="px-2  py-2" variant={"outline"} ><Edit2Icon className='size-4'/></Button>
    <Button type='button' onClick={() => handleDeleteJob(props.item)} size="sm" className='px-2  py-2' variant={"destructive"}><Trash2Icon  className='size-4' /></Button>
    {props.item?.is_paid === false && <Button onClick={() => handleInitiatePayment(props.item.id)} disabled={loading} variant="plain" className='text-green-500' size="sm">Make Payment</Button>}
  </div>
</div>
  )
}

export default JobsCard
