import React from 'react'
import './Homepage.css'
import appImage from '../../assets/images/app-image-portrait.png'
import appStore from '../../assets/images/app-store.png'
import playStore from '../../assets/images/play-store.png'

const AppDownload: React.FC = () => {

    return (
        <div className='appDownload'>
            <div>
                <h3>The all-in-one app designed to help you manage and <span>complete everything with ease.</span></h3>
                <p>From personalized guides tailored to you to seamless project planning, everything you need is right here—in one free app.</p>
                <div>
                    <button onClick={()=> window.open('https://apps.apple.com/us/app/audtiklance/id6740778677', '_blank')}><img src={appStore} alt="" /></button>
                    <button><img src={playStore} alt="" /></button>
                </div>
            </div>
            <img src={appImage} alt="" />
        </div>
    )
}

export default AppDownload