import { useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getSkill } from 'services';


interface AsyncSelectCategoryProps {
  onChange: (selectedOption: any) => void;
  placeholder: string;
}

const AsyncSelectCategory: React.FC<AsyncSelectCategoryProps> = ({ onChange, placeholder }) => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchValue, setSearchValue] = useState("")

  const { data: skillData, refetch: skillRefetch } = useQuery({
    queryKey: ["service"],
    queryFn: () => getSkill(searchValue),
    enabled: !!searchValue,
  });

  useEffect(() => {
      const delayedSearch = debounce(() => { skillRefetch() }, 600)
      delayedSearch()
      return () => delayedSearch.cancel()
    }, [searchValue, skillRefetch]);

  const handleGetCategory = async (query: string) => {
    if (!query?.trim()) {
      return [];
    }
    const response = await getSkill(query);
    return response.results.map((item: any) => (
      { label: item.name, value: item}
    ))
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={handleGetCategory}
      defaultOptions={skillData?.results?.map((category: any) => ({
        value: category.id,
        label: category.name
      }))}
      onChange={onChange}
      placeholder={placeholder}
      className="form-control w-full"
    />
  );

};

export default AsyncSelectCategory;