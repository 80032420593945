import "./App.css";
import { BrowserRouter } from "react-router-dom";

import { NotificationContainer, QueryClientProviderWrapper } from "shared";
import { RoutesWrapper } from "pages";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from 'react-helmet-async';

// import { store } from "redux";

export const App = () => {
  const helmetContext = {};

  return (
    <QueryClientProviderWrapper>
      <BrowserRouter>
      <HelmetProvider context={helmetContext}>
      <RoutesWrapper />
      </HelmetProvider>
        {/* <ToastContainer /> */}
        <NotificationContainer />
      </BrowserRouter>
    </QueryClientProviderWrapper>

    // </Provider>
  );
};

// export default App;
