import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { getPaymentStatus, getPricing, leadPayment, subscribePlan } from "services/paymentApi";

export const useGetSubscriptions = () => {
  const { isLoading, data } = useQuery({
    queryKey: [
      "subscriptions",
    ],
    queryFn: () => getPricing()
  });

  return { isLoading, data  };
}

// export const useGetPaymentStatus = () => {
//   const { mutateAsync } = useMutation({
//     mutationFn: (jwt_token: any) => getPaymentStatus(jwt_token),
//   });
//   return { getPaymentStatus: mutateAsync };
// };

export const useSubscribePlan = () => {
  const { mutateAsync } = useMutation({
    mutationFn: (params: { id: string; payload: any }) => 
      subscribePlan(params.id, params.payload),
  });

  return { subscribePlan: mutateAsync };
}

export const usePayForLead = () => {
  const { mutateAsync } = useMutation({
    mutationFn: (params: { id: string; payload: any }) => 
      leadPayment(params.id, params.payload),
  });

  return { payLead: mutateAsync };
}



//   return { makeConvo: mutateAsync };
//   };
