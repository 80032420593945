/* eslint-disable no-console */

import downIcon from "../../assets/svg/down-arrow-black.svg"
import { PaginationItem, Pagination } from "@mui/material"; // Add this import
import { useGetServiceProfile } from "hooks/profile/useGetServiceProfile";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { SearchFilter } from "./SearchFilter";
import { ServiceCard } from "./ServiceCard";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
// import { Autocomplete, TextField } from "@mui/material";
// import { otherServices } from "component/oldcomponent/OtherServices/data";
import { useEffect, useState } from "react";
import { SearchModal } from "./SearchModal";
import { getAllSkill, getSkill } from "services";
import { useQuery } from "@tanstack/react-query";
// import { getCurrentLocation } from "shared/getLocation";
// import { string } from "yup";
import { GoogleServiceCard } from "./GoogleServiceCard";
import { AutoSearch } from "component/oldcomponent/HeroSection/AutoSearch";
import { debounce } from "lodash";
import axios from "axios";
// import { getPlaceInfo } from "utils/getLocation";
import { LoginForm } from "pages/auth";
import MetaTags from "shared/MetaTags";
import { Dropdown } from "flowbite-react";
import NotificationBanner from "component/oldcomponent/common/NotificationBanner";
import AsyncSelectCategory from "component/common/Layout/CategorySearch";
import { getCurrentLocation } from "shared/getLocation";

export const SearchPage = () => {
  const [openForm, setOpenFom] = useState("");
  const [searchParams] = useSearchParams();
  // const searchTerm = searchParams.get("searchTerm") || "";
  const [searchValue, setSearchValue] = useState("")
  const [searchTerm, setSearchTerm] = useState("")
  // const [placeName, setPlaceName] = useState("")
  const longitude = searchParams.get("long") || ''
  const latitude = searchParams.get("lat") || ''
  const [num, setNum] = useState<{ skill: number, service: number }>({ skill: 10, service: 10 })
  const [openModal, setOpenModal] = useState(false)
  const [searchResults, setSearchResults] = useState<any>([]);
  const [catValue, setCatValue] = useState<string>("")
  const [value, setValue] = useState<number[]>([0, 400]);
  const navigate = useNavigate()
  const [location, setLocation] = useState<{ 
    latitude: any; 
    longitude: any, 
    page: number, 
    ordering?: string,
    min_bid?: string, 
    max_bid?: string } | null>({
    latitude: latitude || '',
    longitude: longitude || '',
    page: 1,
    ordering: '',
    min_bid: '',
    max_bid: '',
  });
  const [sort, setSort] = useState('recommended')
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [customLocation, setCustomLocation] = useState<{ latitude: any; longitude: any, page: number } | null>({
    latitude: '',
    longitude: '',
    page: 1,
  });

  const { serviceProfileData, serviceProfileLoading } = useGetServiceProfile(location);

  // const [error, setError] = useState<{message: string}>();

  useEffect(() => {
    setSearchTerm(searchParams.get("searchTerm") || '')
  }, [])


  useEffect(() => {
    const delayedSearch = debounce(() => {
      if (searchValue.trim() !== "") {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          searchValue
        )}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}`;

        axios
          .get(url)
          .then((response) => {
            setSearchResults(response.data.features);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console?.error(error);
          });
      } else {
        setSearchResults([]);
      }
    }, 300);

    delayedSearch();

    return () => delayedSearch.cancel();
  }, [searchValue]);

  // useEffect(async() => {
  //   const place = await getPlaceInfo(longitude, latitude)
  //   console.log('place', place)
  //   setPlaceName(place?.place_name)
  // }, [location])


  useEffect(() => {
    console.log(longitude, latitude);
    
    if (!longitude || !latitude) {
      getCurrentLocation()
        .then((location) => {
          console.log(location);
          
          setLocation({ latitude: location.latitude, longitude: location.longitude, page: 1 });
        })
        // .catch((error) => setError(error));
    }
  }, [longitude, latitude]);

  useEffect(() => {
    if (serviceProfileData?.count) {
      setTotalPages(Math.ceil(serviceProfileData.count / 10)); // Assuming 10 items per page
    }
  }, [serviceProfileData?.count]);

  useEffect(() => {
    const queryParams = new URLSearchParams();

    if (searchTerm) queryParams.set("searchTerm", searchTerm);
    if (searchParams.get("searchTerm")) queryParams.set("searchTerm", searchParams.get("searchTerm")!);
    if (location?.longitude) queryParams.set("long", location?.longitude);
    if (location?.latitude) queryParams.set("lat", location?.latitude);
    if (page > 1) queryParams.set("page", page.toString());

    navigate(`/search?${queryParams.toString()}`, { replace: true });
  }, [location, searchTerm, page, navigate]);

   const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    if (location) {
      setLocation({...location, page: value});
    }
  };

  const handleChange = (event: Event, newValue:any) => {
    setValue(newValue);
    const min_bid = newValue[0]
    const max_bid = newValue[1]
    const debouncedSearch = debounce(() => {
     
      if (location) { 
        setLocation({
            ...location, 
            min_bid,
            max_bid 
        });
    } else {
        console.error("Location must be defined before updating ordering.");
    }
    }, 2000)
    debouncedSearch()
  
  };

  const { data } = useQuery({
    queryKey: ["service"],
    queryFn: () => getAllSkill()
  });
  

  useEffect(()=> {
    setOpenModal(false)
  }, [searchTerm])


  const handleServiceSelect = (_: any, value: any) => {
    const catValue = value?.value ? value?.value : value
    searchParams.delete("searchTerm")
    setSearchTerm(catValue?.name || "handyman")
    // if (customLocation?.longitude && customLocation?.latitude) {
    //   setLocation({
    //     longitude: customLocation?.longitude,
    //     latitude: customLocation?.latitude,
    //     page: 1,
    //   });
    // } else {
    //   setLocation({ longitude: location?.longitude, latitude: location?.latitude, page: 1 });
    // }
  }

  // const handleServiceSelect = (_: any, value: string | null) => {
  //   if(customLocation?.longitude && customLocation?.latitude){
  //     navigate(`/search?searchTerm=${value}&long=${customLocation?.longitude}&lat=${customLocation?.latitude}`, {replace: true})
  //   }else{
  //     navigate(`/search?searchTerm=${value}&long=${location?.longitude}&lat=${location?.latitude}`, {replace: true})
  //   }
  // }

  const handleChangeCategory = (term: string) => {
    const debouncedSearch = debounce(() => {
      // handleServiceSelect(null,term)
      // if(customLocation?.longitude && customLocation?.latitude){
      //   handleServiceSelect(null,term)
      // }else{
      //   handleServiceSelect(null, term)
      // }
      setCatValue(term)
    }, 1000)
    debouncedSearch()
  };

  const { data: skillData, refetch: skillRefetch } = useQuery({
    queryKey: ["service"],
    queryFn: () => getSkill(catValue),
    enabled: !!catValue,
  });

  useEffect(() => {
    const delayedSearch = debounce(() => { skillRefetch() }, 600)
    delayedSearch()
    return () => delayedSearch.cancel()
  }, [catValue, skillRefetch]);

  const handleLocationChange = (_: any, value: any) => {
    const location = value?.geometry?.coordinates
    setLocation({longitude: location?.[0], latitude: location?.[1], page: 1})
    setCustomLocation({longitude: location?.[0], latitude: location?.[1], page: 1})
  };

  const handleModalClose = () => {
    setOpenModal(false)
  }

  const handleSortResult = async (value: string ) => {
    setSort(value)
    if (location) { 
      setLocation({
          ...location, 
          ordering: value, 
      });
  } else {
      console.error("Location must be defined before updating ordering.");
  }
    
     
    //  setLocation({
    //   ...location,
    //   ordering: value
    //  })
  }

  // if (error?.message) {
  //   return (
  //       <Box className="flex-col space-y-2" sx={{ display: 'flex', flexDirection: 'flex-column ', width: "100%", height: "97vh", justifyContent: "center", alignItems: "center" }}>
  //         <h3 className="text-xl">          Sorry, looks like there was an error completing your request.          </h3>
  //        <h6>  What went wrong? 
  //        <span className="font-bold text-red-500">     Please allow location permission!!         </span>
  //        </h6>
        
  //       </Box> 
  //     )
  // }


  return (
    <div>
              <MetaTags 
                title={"Audtiklance Search Page"}
                description={"Audtiklance is an online marketplace connecting freelancers with businesses and individuals looking for services like graphic design, writing, programming, and more."}
                image={"https://app.audtiklance.com/logo-main.png"}
                url="https://app.audtiklance.com/search"
                siteName="https://app.audtiklance.com/"
            />

<div className="px-6 py-24 mt-12 lg:py-10 lg:px-16 lg:mt-6">
      
      <div className="flex flex-col items-start justify-between gap-4 lg:items-end lg:flex-row">
        <div>
          <p className="text-[#6E6E70] lg:text-[16px] text-[13px] font-semibold"><span onClick={() => navigate("/")} className="cursor-pointer">Home</span> / Search Results</p>
          <h1 className="text-[18px] lg:text-[24px] font-[700] text-[#212329] my-1">Service Provider Results ({serviceProfileData?.results?.length}) </h1>
          <Link to="/add-listing" className="text-[#4d8bf0] lg:text-[16px] text-[13px] font-medium">Didn't find your desired Skill Provider?</Link>
        </div>
        <div className="">
            <NotificationBanner
              message="Not finding the right provider? Skip the search and let providers reach out to you!"
              leftImageSrc="https://storage.googleapis.com/a1aa/image/hAKtLuhYpMrCItTxeVtjECqxvTO9oLzNkWOEfpm4JpzOIfOoA.jpg"
              rightImageSrc="https://storage.googleapis.com/a1aa/image/hAKtLuhYpMrCItTxeVtjECqxvTO9oLzNkWOEfpm4JpzOIfOoA.jpg"
            />
          </div>
        <div className="flex items-center gap-2">
          <span className="text-[14px]">Sort By:</span>
          <Dropdown label=""  size="sm"  renderTrigger={() => <span className="flex text-[14px] capitalize rounded-[50px] justify-center items-center gap-2 w-[170px] cursor-pointer h-[36px] bg-[#F1F5F9]">{sort || 'Sort By'} <img src={downIcon} alt="" /></span>}>
        <Dropdown.Item onClick={() => handleSortResult('recommended')}>Recommended</Dropdown.Item>
        <Dropdown.Item onClick={() => handleSortResult('distance')}>Distance</Dropdown.Item>
        <Dropdown.Item onClick={() => handleSortResult('recommended')}>3rd Party</Dropdown.Item>
        {/* <Dropdown.Item>Sign out</Dropdown.Item> */}
      </Dropdown>
          {/* <div className="flex text-[14px] rounded-[50px] justify-center items-center gap-2 w-[170px] cursor-pointer h-[36px] bg-[#F1F5F9]">Recommended <img src={downIcon} alt="" /></div> */}
        </div>

      </div>
      
      <div className="flex flex-col items-start gap-4 mt-12 lg:flex-row">
      <AutoSearch 
      searchResult={searchResults || []} 
      handleSelect={handleLocationChange} 
      getOptionLabel={(option: any) => option?.place_name as string} 
      handleType={setSearchValue} 
      label={"Location"}
      // defaultValue={placeName || ''}
      />

      <AutoSearch 
        searchResult={skillData?.results || []}
       handleSelect={handleServiceSelect} 
       getOptionLabel={(option: any) => option?.name as string} 
       handleType={handleChangeCategory} 
      //  defaultValue={searchTerm || ''}
       label={"What service are you looking for today?....."} />

{/* <AsyncSelectCategory
                      onChange={handleServiceSelect}
                      placeholder="Search category"
                    /> */}
      </div>
      
      <div className="flex flex-col gap-4 mt-12 lg:flex-row">
        <SearchFilter handleChange={handleChange} setNum={setNum} num={num} value={value} searchParams={searchTerm} categories={data?.data || []} />
        {
          serviceProfileLoading ?  <Box sx={{ display: 'flex', width: "100%", height: "97vh", justifyContent: "center", alignItems: "center" }}>
                 <CircularProgress />
               </Box> :
               <div>
                
               </div>
        }
        {
          serviceProfileData?.results?.length! < 1 ?
            <div className="w-[100%] h-[70vh] lg:w-[75%] flex justify-center items-center">
              <p>No Result Found</p>
            </div> :
            <div className="w-[100%] lg:w-[75%]">
              {
                serviceProfileData?.results?.map((service, i) => {
                  // const { singleProfileData } = useGetProfileById(service?.owner_id);
                  // console.log('singleProfileData', singleProfileData)
                  return (
                    service?.is_google_place ? 
                    <GoogleServiceCard key={i} service={service} /> : 
                    <ServiceCard key={i} service={service} />
                  )
                 
              })
              }
               <div className="flex justify-end mt-8">
             <Pagination
               count={totalPages}
               page={page}
               onChange={handlePageChange}
               color="primary"
               renderItem={(item) => (
                 <PaginationItem
                   {...item}
                   sx={{
                     '&.Mui-selected': {
                       backgroundColor: '#10172A',
                       color: 'white',
                     },
                   }}
                 />
               )}
             />
           </div>
            </div>
        }

             {/* Add pagination */}
            
      </div>
            <LoginForm loginPrompt='to hire this provider!'  openLogin={openForm} setOpenLogin={setOpenFom} />

      <SearchModal openModal={openModal} handleChange={handleChange} handleModalClose={handleModalClose} num={num} setNum={setNum} value={value} searchParams={searchTerm} />
      <div className="fixed left-0 flex justify-center w-full lg:hidden bottom-7">
        <button onClick={() => setOpenModal(true)} className="w-32 text-white bg-blue-600 h-9 rounded-3xl">Filter</button>
      </div>
    </div>
    </div>
    
  );
};
