import { QueryClient, useMutation } from "@tanstack/react-query";
import { createQuoteFn } from "services/quote.service";

export const useCreateQuote = () => {
  const queryClient = new QueryClient();
  const { mutateAsync: createQuoteMutation, isPending: isCreatingQuote } =
    useMutation({
      mutationFn: createQuoteFn,
    });

  return {
    createQuoteMutation,
    isCreatingQuote,
  };
};
