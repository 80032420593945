import React, { useEffect, useState } from "react";
import { Card } from "ui/card";
import Rating from "@mui/material/Rating";
import emptyImage from "../../assets/images/empty_image.png";

import { Avatar } from "ui/avatar";
import locationIcon from "../../assets/svg/location.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "ui/button";
import {
  useGetServiceProfileById,
  useGetServiceProfileReviews,
} from "hooks/profile/useGetServiceProfileById";
import { ProductDetailsModal } from "./productDetailsModal";
// import { useGetConversation } from "hooks/services/useGetConversation";
import { LoginForm } from "pages";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useAuthenticatedUser, useGetProfileById } from "hooks";
import { FaPhone } from "react-icons/fa";
import { QuoteModal, RequestModal } from "context/settings/services/ServiceModals";
import { SUB_CATEGORY } from "types";
import {
  ArrowBigLeft,
  ChevronRight,
  X,
} from "lucide-react";
import MetaTags from "shared/MetaTags";
import { convertToMiles, maskPhoneNumber } from "shared/maskNumber";
import { messageRequest, reviewServiceProviderProfile } from "services";
import { useMutation } from "@tanstack/react-query";
import { notify } from "shared";
import { IErrorType } from "context/settings/type";
import { Modal } from "flowbite-react";
import Carousel from 'react-material-ui-carousel'
import { calculateAverageRating } from "utils";
import { useCreateQuote } from "hooks/quote/useCreateQuote";

dayjs.extend(relativeTime);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const labels: { [index: string]: string } = {
  0.5: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};

export const ProductDetails = () => {
  const location = useLocation();
  const { id } = useParams();
  const [openForm, setOpenFom] = useState("");
  const [openRequestForm, setOpenRequestForm] = useState(false);
  const [message, setMessage] = useState("");
  const [drawer, setDrawer] = useState(false);
  const [openReview, setOpenReview] = useState(false)
  const navigate = useNavigate();
  const { userDetails } = useAuthenticatedUser();
  const [openQuoteModal, setOpenQuoteModal] = useState(false)
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState<any>(0);
  // const { initiateConvo } = useGetConversation();
  const [userId, setUserId] = useState<string>("");
  const { singleProfileData } = useGetProfileById(userId);
  const { createQuoteMutation, isCreatingQuote } = useCreateQuote()
  // const handleConvoInitiation = async () => {
  //   const res = await initiateConvo({ user: serviceProfileData?.owner_id });
  //   if (res?.success) {
  //     navigate(`/chat/${res?.data?.id}`);
  //   }
  // };

  const { serviceProfileData, refetchServiceProfile } =
    useGetServiceProfileById(id as string);
  const {
    serviceReviewData,
    refetchServiceProfileReviews,
  } = useGetServiceProfileReviews(id as string);
  const { mutate: updateMutate } = useMutation({
    mutationFn: reviewServiceProviderProfile,
    onSuccess: async (data) => {
      if (data?.success === true) {
        await refetchServiceProfile();
        await refetchServiceProfileReviews();
        setComment("");
        setRating(0);
        setOpenReview(false)
        notify.success({ message: "review added successfully" });
      }
    },
    onError: async (err: IErrorType) => {
      notify.error({
        message: "Error while adding information",
        subtitle: err?.response?.data?.error || "An error occurred",
      });
    },
  });

  useEffect(
    () => setUserId(serviceProfileData?.owner_id || ""),
    [serviceProfileData]
  );

  const { mutate: mutateSendMessage } =
    useMutation({
      mutationFn: messageRequest,
      onSuccess: async (data) => {
        if (data?.status === 201) {
          setOpenRequestForm(false);
          await refetchServiceProfile();
          notify.success({ message: "message sent successfully" });
        }
      },
      onError: async (err: IErrorType) => {
        notify.error({
          message: "Error while adding information",
          subtitle: err?.response?.data?.error || "An error occurred",
        });
      },
    });

  const hasExternalReviews =
    serviceProfileData &&
    serviceProfileData.external_reviews &&
    Object.keys(serviceProfileData.external_reviews).length > 0;

  const handleOpenRequestModal = () => {
    setOpenRequestForm(true);
  };

  const handleCloseRequestModal = () => {
    setOpenRequestForm(false);
  };

  const handleSubmit = () => {
    const formData = {
      id,
      comment,
      rating,
    };
    if (rating == 0) {
      notify.error({
        message: "Rating is Required",
      });
      return;
    }
    updateMutate(formData);
  };

  const handleQuoteSubmit = async (data: any) => {
    // eslint-disable-next-line no-console
    const formData = new FormData()
    formData.append('service_profile', id!)
    formData.append('description', data.description)
    formData.append('deadline', data.deadline)
    if (data?.pictures && data.pictures.length > 0) {
      data.pictures.forEach((item: any, index: any) => {
        formData.append(`images[${index}]`, item);
      });
    }
    const { status } = await createQuoteMutation(formData);
    if (status === 201) {
      notify.success({
        message: "Quote created successfully"
      })
      setOpenQuoteModal(false)
    } else {
      notify.error({
        message: "Error creating quote, please try again"
      })
    }

  }

  const handleSubmitRequest = () => {
    const formData = {
      service_profile: serviceProfileData?.id,
      message,
    };

    if (!message) {
      notify.error({
        message: "Message is Required",
      });
      return;
    }
    mutateSendMessage(formData);
    // setOpenRequestForm(false)
  };

  const getCost = (subCategory: SUB_CATEGORY[]) => {
    return subCategory?.map((sub) => sub?.cost);
  };

  return (
    <>
      <MetaTags
        title={serviceProfileData?.business_name || ""}
        description={
          "Audtiklance is an online marketplace connecting freelancers with businesses and individuals looking for services like graphic design, writing, programming, and more."
        }
        // image={serviceProfileData?.profile_picture}
        url={`https://app.audtiklance.com${location.pathname}`}
        siteName="https://app.audtiklance.com/"
      />
      <div className="container my-[80px] w-full px-4 ">
        <Button
          onClick={() => navigate(-1)}
          variant={"plain"}
          className="!px-0 "
        >
          <ArrowBigLeft size={16} /> Go back
        </Button>
        <div className="flex flex-col md:flex-row w-full h-[calc(100vh-160px)] gap-6">
          <div className="flex flex-col w-full col-span-8 overflow-y-auto md:w-8/12 hideScrollBar">
            <div className="w-[70%]">
              <span className="font-bold ">
                {serviceProfileData?.business_name}
              </span>
            </div>
            <div className="flex flex-col gap-4">

              <Carousel indicators={false}>
                {
                  [{ image_url: serviceProfileData?.profile_picture }, ...serviceProfileData?.images || []]?.map((image, i) => (
                    <Card key={i} className="border-0 mt-6 w-full rounded-[14px]  object-contain ">
                      <img
                        style={{ height: 500 }}
                        src={
                          image?.image_url ||
                          emptyImage
                        }
                        alt={serviceProfileData?.business_name}
                        referrerPolicy="no-referrer"
                        className="w-full rounded-lg"
                      />
                    </Card>
                  ))
                }
              </Carousel>

              {serviceProfileData?.is_google_place === false && (
                <div className="p-2 bg-white rounded-lg shadow-none">
                  <h4 className="mb-1 text-sm font-semibold">
                    Services Offered
                  </h4>
                  <div className="flex flex-wrap gap-1">
                    {serviceProfileData?.sub_category.map((item) => (
                      <span
                        key={item.id}
                        className="px-2 py-1 text-xs font-semibold text-gray-700 bg-gray-100 rounded-full"
                      >
                        {item?.sub_category}
                      </span>
                    ))}
                  </div>



                </div>
              )}
            </div>
            {serviceProfileData?.is_google_place === true && (
              <div className="mt-3">
                <Card className="min-h-full pb-3 lg:hidden">
                  <div className="flex-1 space-y-3">
                    <div className="flex items-center justify-between py-3">
                      <h6 className="font-bold ">About Me</h6>
                      <div className="flex justify-between ">
                        <span className="text-sm">
                          <span className="font-bold">
                            {serviceProfileData?.external_rating || "0"}/5{" "}
                          </span>
                          <span className=" text-[#64748B]">
                            ({serviceProfileData?.external_reviews?.length || "0"})
                          </span>
                        </span>
                      </div>
                    </div>
                    <p className="text-sm">
                      {serviceProfileData?.about_me || "N/A"}
                    </p>
                    {/* {`${!userDetails ? 'blur-text' : ''} */}
                    {serviceProfileData?.is_google_place === true && (
                      <p className={` flex gap-2  text-sm`}>
                        <img src={locationIcon} alt="" />
                        {serviceProfileData?.address}
                      </p>
                    )}
                    {serviceProfileData?.is_google_place === true && (
                      <a
                        href={`tel:${serviceProfileData?.phone}`}
                        className={`${!userDetails ? "pointer-events-none" : " "}  text-sm flex items-center gap-2`}
                      >
                        <FaPhone />
                        {!userDetails
                          ? maskPhoneNumber(serviceProfileData?.phone)
                          : serviceProfileData?.phone}
                      </a>
                    )}
                  </div>
                  <div></div>
                </Card>
              </div>
            )}

            {serviceProfileData?.is_google_place === false && (
              <div className="mt-3">
                <Card className="flex-col min-h-full pb-4 shadow-none lg:hidden">
                  <div className="flex flex-1">
                    <div className="flex-1 space-y-3">
                      <h6 className="mb-3 font-bold">About Me</h6>

                      <div className="flex gap-2">
                        <Avatar
                          fallbackText={serviceProfileData?.owner_name}
                          src={singleProfileData?.profile_picture || ""}
                          className="w-[clamp(50px,_8vw,_50px)] font-medium text-black"
                        // size={40}
                        />
                        <div>
                          <h6 className="font-semibold capitalize">
                            {serviceProfileData?.owner_name}
                          </h6>
                          <p
                            className={` flex gap-2  text-[#64748B] text-[12px] lg:text-sm`}
                          >
                            <img src={locationIcon} alt="" />
                            {serviceProfileData?.address || "N/A"}{" "}
                            {serviceProfileData?.distance
                              ? `- ${convertToMiles(serviceProfileData?.distance)?.toFixed()}  miles away`
                              : null}{" "}
                          </p>
                          <button
                            type="button"
                            onClick={() => setDrawer(true)}
                            className="font-bold gap-2 text-blue-500 text-[12px]  lg:text-sm"
                          >
                            See profile{" "}
                            <ChevronRight className="inline-flex items-center size-4" />
                          </button>
                        </div>
                      </div>
                      {/* {
                      <p className={` flex gap-2  text-sm`}>
                        <img src={locationIcon} alt="" />
                        {serviceProfileData?.address}
                      </p>
                    } */}
                      {/* <button
                          type="button"
                          onClick={() => setDrawer(true)}
                          className="font-bold gap-2 text-blue-500 text-[12px] "
                        >
                          See profile{" "}
                          <ChevronRight className="inline-flex items-center size-4" />
                        </button> */}
                    </div>
                    <span>
                      From{" "}
                      <span className="mx-1 font-bold">
                        ${Math.min(...getCost(serviceProfileData?.sub_category))}
                        /hr
                      </span>
                    </span>
                  </div>
                  <div className="flex items-center justify-between mt-3">
                    <div className="space-y-2">
                      <p className="text-slate-600">
                        Get a  <span
                          onClick={() => {
                            if (!userDetails?.data?.firstname) {
                              setOpenFom("login");
                              return;
                            }
                            setOpenQuoteModal(!openQuoteModal)
                          }}
                          className="text-blue-500 underline cursor-pointer">free quote</span> from this professional
                      </p>
                      <p className="text-sm text-slate-500">
                        Save up to 10% with for new users
                      </p>
                    </div>
                    <div>
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6 12C9.31373 12 12 9.31373 12 6C12 2.68629 9.31373 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31373 2.68629 12 6 12ZM5.00002 4.66665C5.00002 4.49555 5.05683 4.22407 5.20771 4.01452C5.33411 3.83896 5.55064 3.66665 6.00002 3.66665C6.55069 3.66665 6.83675 3.95753 6.93356 4.24917C7.03553 4.55633 6.94859 4.8997 6.63022 5.11195C6.10403 5.46274 5.71645 5.80258 5.51226 6.27903C5.33208 6.69944 5.3327 7.16013 5.33329 7.5933L5.33335 7.66665H6.66669C6.66669 7.12087 6.67816 6.94338 6.73778 6.80426C6.78359 6.69738 6.89601 6.53722 7.36982 6.22135C8.22818 5.64913 8.47455 4.65916 8.199 3.8291C7.91827 2.98352 7.11601 2.33331 6.00002 2.33331C5.11607 2.33331 4.49926 2.71656 4.12567 3.23544C3.77654 3.72034 3.66669 4.28219 3.66669 4.66665H5.00002ZM6.66665 9.66667V8.33333H5.33331V9.66667H6.66665Z"
                          fill="#74767E"
                        />
                      </svg>
                    </div>
                  </div>
                </Card>


              </div>
            )}

            <div className="flex flex-col gap-3 mt-3">
              <span className="font-bold">Overview</span>
              <p className="text-sm">{serviceProfileData?.about_me || "No business description "}</p>
              {/* <div className="w-full lg:w-[396px] flex justify-between">
                <span className="text-sm font-medium">Professional Expert</span>
                <span className="text-sm font-medium">
                  4 hires on this website
                </span>
              </div> */}
              <div className="space-y-2">
                {/* <p className="text-sm font-medium">Business Description</p> */}

              </div>
            </div>

            <div className="flex flex-col w-full mt-3">
              <span className="font-bold">Reviews</span>
              <span>
                <div className="flex items-center py-2 gap-x-1">
                  <Rating
                    precision={0.5}
                    value={calculateAverageRating(serviceProfileData?.review) || 0}
                    size="medium"
                    readOnly
                  />
                  <p className="font-semibold text-[13px] lg:text-[16px] ">

                    {calculateAverageRating(serviceProfileData?.review) !== 0 ? `${calculateAverageRating(serviceProfileData?.review)} ` : "0"}


                    out of 5


                  </p>
                </div>
                {/* <span className="text-sm font-bold">
                  {serviceProfileData?.external_rating || "0"}/5
                  <span className="mx-2 font-medium">Very Satisfied</span>
                </span> */}
              </span>
            </div>

            {serviceProfileData?.is_google_place === false && userDetails?.data?.service_profile !== serviceProfileData?.id && (
              <div className="px-2 mt-3">
                <div>
                  <Button variant="outline" size="sm" className="text-orange-400 hover:text-white hover:bg-orange-400" type="button" onClick={() => setOpenReview(true)}>Drop a review </Button>
                </div>
              </div>
            )}
            <div className="flex flex-col gap-12 mt-6">
              {serviceProfileData?.is_google_place === false &&
                serviceReviewData?.data &&
                serviceReviewData?.data?.map((review, index) => (
                  <div key={index} className="flex flex-col gap-2">
                    <div className="flex gap-2">
                      <Avatar
                        fallbackText="T"
                        className="bg-[#F85D5D]"
                        src={review?.reviewer?.profile_picture}
                      />
                      <div className="flex flex-col">
                        <span className="text-sm font-bold">
                          {review?.reviewer?.firstname +
                            " " +
                            review?.reviewer?.lastname}
                        </span>
                        {/* <span className="text-sm ">{review?.authorAttribution?.displayName}</span> */}
                        <Rating
                          name="size-medium"
                          value={review?.rating}
                          precision={1}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      {/* <span>rating</span> */}
                      <span className="text-xs">
                        {dayjs(review.created_at).fromNow()}
                      </span>
                    </div>
                    <span className="mt-1 text-sm">{review?.comment}</span>
                  </div>
                ))}
            </div>
            <div className="flex flex-col gap-12 mt-6">
              {hasExternalReviews &&
                serviceProfileData?.external_reviews?.map((review, index) => (
                  <div key={index} className="flex flex-col gap-2">
                    <div className="flex gap-2">
                      <Avatar
                        fallbackText="T"
                        className="bg-[#F85D5D]"
                        src={review?.authorAttribution?.photoUri}
                      />
                      <div className="flex flex-col">
                        <span className="text-sm font-bold">
                          {review?.authorAttribution?.displayName}
                        </span>
                        <span className="text-sm ">
                          {review?.authorAttribution?.displayName}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      {/* <span>rating</span> */}
                      <span className="text-xs">
                        {dayjs(review.publishTime).fromNow()}
                      </span>
                    </div>
                    <span className="mt-2 text-sm">
                      {review?.originalText?.text}
                    </span>
                  </div>
                ))}
            </div>
          </div>
          {serviceProfileData?.is_google_place === false && (
            <div className="sticky w-full col-span-4 md:w-4/12 h-fit top-20">

              <Button
                size="sm"
                className="w-full mt-4 lg:hidden"
                onClick={() => {
                  if (!userDetails?.data?.firstname) {
                    setOpenFom("login");
                    return;
                  }
                  // handleConvoInitiation();
                  // navigate("/chat");
                  handleOpenRequestModal();
                }}
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 19H8.5C4.5 19 2.5 18 2.5 13V8C2.5 4 4.5 2 8.5 2H16.5C20.5 2 22.5 4 22.5 8V13C22.5 17 20.5 19 16.5 19H16C15.69 19 15.39 19.15 15.2 19.4L13.7 21.4C13.04 22.28 11.96 22.28 11.3 21.4L9.8 19.4C9.64 19.18 9.27 19 9 19Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.4965 11H16.5054"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.4945 11H12.5035"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.49451 11H8.50349"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span> Message provider</span>

              </Button>
              <Card className="hidden border rounded-lg lg:block ">
                <div className="p-4 space-y-3">
                  <div className="flex flex-row items-end space-x-1">
                    <p className="text-[#404145] ">From</p>
                    <p>
                      <span className="text-lg font-bold">
                        $
                        {Math.min(...getCost(serviceProfileData?.sub_category))}
                        /hr
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="space-y-2">
                      <p className="text-slate-600">
                        Get a  <span
                          onClick={() => {
                            if (!userDetails?.data?.firstname) {
                              setOpenFom("login");
                              return;
                            }
                            setOpenQuoteModal(!openQuoteModal)
                          }}
                          className="text-blue-500 underline cursor-pointer">free quote</span> from this professional
                      </p>
                      <p className="text-sm text-slate-500">
                        Save up to 10% with for new users
                      </p>
                    </div>
                    <div>
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6 12C9.31373 12 12 9.31373 12 6C12 2.68629 9.31373 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31373 2.68629 12 6 12ZM5.00002 4.66665C5.00002 4.49555 5.05683 4.22407 5.20771 4.01452C5.33411 3.83896 5.55064 3.66665 6.00002 3.66665C6.55069 3.66665 6.83675 3.95753 6.93356 4.24917C7.03553 4.55633 6.94859 4.8997 6.63022 5.11195C6.10403 5.46274 5.71645 5.80258 5.51226 6.27903C5.33208 6.69944 5.3327 7.16013 5.33329 7.5933L5.33335 7.66665H6.66669C6.66669 7.12087 6.67816 6.94338 6.73778 6.80426C6.78359 6.69738 6.89601 6.53722 7.36982 6.22135C8.22818 5.64913 8.47455 4.65916 8.199 3.8291C7.91827 2.98352 7.11601 2.33331 6.00002 2.33331C5.11607 2.33331 4.49926 2.71656 4.12567 3.23544C3.77654 3.72034 3.66669 4.28219 3.66669 4.66665H5.00002ZM6.66665 9.66667V8.33333H5.33331V9.66667H6.66665Z"
                          fill="#74767E"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="border border-[#E2E8F0]  my-2" />
                <div className="p-4">
                  <div
                    style={{ width: "100%" }}
                    className="flex justify-between"
                  >
                    <div className="flex gap-2">
                      <Avatar
                        fallbackText={serviceProfileData?.owner_name}
                        src={singleProfileData?.profile_picture || ""}
                        className="w-[clamp(50px,_8vw,_50px)] font-medium text-black"
                      // size={40}
                      />
                      <div>
                        <h6 className="font-semibold capitalize">
                          {serviceProfileData?.owner_name}
                        </h6>
                        <p
                          className={` flex gap-2  text-[#64748B] text-[12px] lg:text-sm`}
                        >
                          <img src={locationIcon} alt="" />
                          {serviceProfileData?.address || "N/A"}{" "}
                          {serviceProfileData?.distance
                            ? `- ${convertToMiles(serviceProfileData?.distance)?.toFixed()}  miles away`
                            : null}{" "}
                        </p>
                        <button
                          type="button"
                          onClick={() => setDrawer(true)}
                          className="font-bold gap-2 text-blue-500 text-[12px]  lg:text-sm"
                        >
                          See profile{" "}
                          <ChevronRight className="inline-flex items-center size-4" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div></div>
                  <div>
                    <Button
                      size="sm"
                      className="w-full mt-4"
                      onClick={() => {
                        if (!userDetails?.data?.firstname) {
                          setOpenFom("login");
                          return;
                        }
                        // handleConvoInitiation();
                        // navigate("/chat");
                        handleOpenRequestModal();
                      }}
                    >
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 19H8.5C4.5 19 2.5 18 2.5 13V8C2.5 4 4.5 2 8.5 2H16.5C20.5 2 22.5 4 22.5 8V13C22.5 17 20.5 19 16.5 19H16C15.69 19 15.39 19.15 15.2 19.4L13.7 21.4C13.04 22.28 11.96 22.28 11.3 21.4L9.8 19.4C9.64 19.18 9.27 19 9 19Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.4965 11H16.5054"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.4945 11H12.5035"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.49451 11H8.50349"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <span>Message provider</span>
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          )}
          {serviceProfileData?.is_google_place === true && (
            <div className="sticky w-full col-span-4 md:w-4/12 h-fit top-20">

              <a
                href={`tel:${serviceProfileData?.phone}`} // Replace with the actual phone number field
                className="inline-block w-full py-2 mt-4 text-sm font-bold text-center text-white bg-blue-500 rounded-sm lg:hidden"
                onClick={(e) => {
                  if (!userDetails?.data?.firstname) {
                    e.preventDefault(); // Prevent the default action if not logged in
                    setOpenFom("login"); // Open the login form
                  }
                }}
              >
                Call Now
              </a>

              <Card className="hidden p-4 border rounded-lg lg:block">
                <div className="space-y-3">
                  <h6 className="mb-3 font-bold">About Provider</h6>
                  <p className="text-sm">
                    {serviceProfileData?.about_me || "N/A"}
                  </p>
                  {/* {`${!userDetails ? 'blur-text' : ''} */}
                  {serviceProfileData?.is_google_place === true && (
                    <p className={` flex gap-2  text-sm`}>
                      <img src={locationIcon} alt="" />
                      {serviceProfileData?.address}
                    </p>
                  )}
                  {serviceProfileData?.is_google_place === true && (
                    <a
                      href={`tel:${serviceProfileData?.phone}`}
                      className={`${!userDetails ? "pointer-events-none" : " "}  text-sm flex items-center gap-2`}
                    >
                      <FaPhone />
                      {!userDetails
                        ? maskPhoneNumber(serviceProfileData?.phone)
                        : serviceProfileData?.phone}
                    </a>
                  )}
                </div>
                <div>
                  <div className="flex justify-between mt-2">


                    <span className="text-sm">
                      <span className="font-bold">
                        {serviceProfileData?.external_rating || "0"}/5{" "}
                      </span>
                      <span className=" text-[#64748B]">
                        ({serviceProfileData?.external_reviews?.length || "0"})
                      </span>
                    </span>
                  </div>
                  {/* <Button
                    size="sm"
                    className="w-full mt-4"
                    onClick={() => {
                       if(!userDetails?.data?.firstname){
                              setOpenFom("login")
                              return
                          }
                      // handleConvoInitiation();
                      // navigate("/chat");
                      handleOpenRequestModal()
                    }}
                  >
                   Call Now
                  </Button> */}
                  <a
                    href={`tel:${serviceProfileData?.phone}`} // Replace with the actual phone number field
                    className="inline-block w-full py-2 mt-4 text-sm font-bold text-center text-white bg-blue-500 rounded-sm"
                    onClick={(e) => {
                      if (!userDetails?.data?.firstname) {
                        e.preventDefault(); // Prevent the default action if not logged in
                        setOpenFom("login"); // Open the login form
                      }
                    }}
                  >
                    Call Now
                  </a>
                </div>
              </Card>
            </div>
          )}
        </div>
      </div>
      <LoginForm
        loginPrompt="to hire this provider!"
        openLogin={openForm}
        setOpenLogin={setOpenFom}
        redirect={window.location.pathname}
      />
      <RequestModal
        setMessage={setMessage}
        newModal={openRequestForm}
        newProvider={serviceProfileData}
        handleRequestModalClose={handleCloseRequestModal}
        handleSubmit={handleSubmitRequest}
      />
      <ProductDetailsModal
        id={serviceProfileData?.owner_id as string}
        img={serviceProfileData?.profile_picture as string}
        services={serviceProfileData?.sub_category}
        open={drawer}
        setDrawer={setDrawer}
      />

      <QuoteModal
        openModal={openQuoteModal}
        setOpenModal={setOpenQuoteModal}
        handleSubmitForm={handleQuoteSubmit}
        isCreatingQuote={isCreatingQuote}
      />
      <Modal dismissible show={openReview} onClose={() => setOpenReview(false)}>
        <Modal.Body>
          <div className="relative flex flex-col">
            <button type="button" onClick={() => setOpenReview(false)} className="ml-auto">
              <X />
            </button>
            <div className="flex flex-col items-center gap-2 mt-4">
              <Rating
                name="size-medium"
                value={rating}
                precision={1}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
              />
              <textarea
                id="review"
                rows={6}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Enter review"
                className={`resize-none border ring-1 placeholder:text-xs ring-[#E2E8F0] w-full border-[#E2E8F0] p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent 
            `}
              />
              <div>
                <Button onClick={() => handleSubmit()}>Submit</Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


    </>
  );
};
