import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Button } from "ui/button";
import CustomSelect from "ui/controlledSelectedComponent";
import FileUploader from "ui/fileUploader";
import { FormTextInput } from "ui/formTextInput";
import { profileSchema } from "./profileSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormStore } from "store";
import FormTextarea from "ui/formTextArea";
import { AutoSearch } from "component/oldcomponent/HeroSection/AutoSearch";
import { debounce } from "lodash";
import axios from "axios";
import PhoneInput from "react-phone-input-2";

export const PersonalInfoForm = ({ onSubmit }: { onSubmit: () => void }) => {
  const [valid, setValid] = useState(false)
  const [searchResults, setSearchResults] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("")
  const [location, setLocation] = useState({geometry: {coordinates: []}})
  const methods = useForm({
    mode: "onChange", // Enables real-time validation
    resolver: yupResolver(profileSchema), // Custom resolver
  });

  const [formState] = useState(() => {
    const stored = JSON.parse(localStorage.getItem("form") as string);

    return stored || {};
  });

  const setSelected = useFormStore((state) => state.setSelected);

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, }, // Access form validation state
    register,
    watch
  } = methods;

  useEffect(() => {
    // setValue("city", formState?.city);
    // setValue("country", formState?.country);
    setValue("email", formState?.email);
    setValue("name", formState?.name);
    setValue("firstname", formState?.firstname);
    setValue("lastname", formState?.lastname);
    setValue("password", formState?.password);
    setValue("phoneNumber", `+${formState?.phoneNumber}`);
    setValue("address", formState.address);
    // setValue("state", formState?.state);
    // setValue("zipCode", formState?.zipCode);
    // setValue("latitude", location?.geometry?.coordinates[1])
    // setValue("longitude", location?.geometry?.coordinates[0])
    setValue("description", formState?.description)
  }, [setValue]);

  useEffect(() => {
    if (watch()?.email 
    && watch()?.name 
    && watch()?.firstname 
    && watch()?.lastname 
    && watch()?.latitude 
    && watch()?.longitude 
    && watch()?.password 
    && watch()?.phoneNumber 
    && watch().description 
    && watch()?.pictures.length > 0) {
      setValid(true)
    } else {
      setValid(false)
    }
  }, [watch()])

  useEffect(() => {
    // Debounce the search function to reduce API calls
    const delayedSearch = debounce(() => {
      if (searchValue.trim() !== "") {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          searchValue,
        )}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}`;

        axios.get(url)
          .then((response: any) => {
            setSearchResults(response.data.features);
          })
          .catch((error: any) => {
            console.error(error);
          });
      } else {
        setSearchResults([]);
      }
    }, 300);

    delayedSearch();

    // Clean up the debounce function on unmount
    return () => delayedSearch.cancel();
  }, [searchValue]);

  const onFormSubmit = (data: any) => {
    setSelected({
      ...data,
    });
    localStorage.setItem("form", JSON.stringify(data));
    // If form is valid, proceed to the next step
    onSubmit();
  };

  // const location = value?.geometry?.coordinates
  // console.log('value', value)
  // setPlaceName(value?.place_name)
  // setLocation({longitude: location[0], latitude: location[1], page: 1})
  const handleLocationChange = (_: any, value: any) => {
    setValue("address", value?.place_name)
    setValue("latitude", value?.geometry?.coordinates[1])
    setValue("longitude", value?.geometry?.coordinates[0])
    setLocation(value);
  };

  
  return (
    <FormProvider {...methods}>
      <form
        className="grid grid-cols-1 px-4  md:grid-cols-[1fr_1fr_auto] gap-6 pb-10"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        
        {/* Personal Information Fields */}
        <div className="flex flex-col gap-8">
          <FormTextInput
            //name={"name"}

            labelText="First Name"
            placeholder="Enter your first name..."
            {...register("firstname")}
          />
          <FormTextInput
            //name={"name"}
            labelText="Last Name"
            placeholder="Enter your last name..."
            {...register("lastname")}
          />
          <FormTextInput
            //name={"name"}
            labelText="Business Name"
            placeholder="Enter your business name..."
            {...register("name")}
          />

          <FormTextInput
            //name={"email"}
            type="email"
            labelText="Email Address"
            placeholder="Enter your email address..."
            {...register("email")}
          />
          <FormTextInput
            //name={"email"}
            type="password"
            labelText="Password"
            placeholder="Enter your password..."
            {...register("password")}
          />
        </div>

        {/* Contact Information Fields */}
        <div className="flex flex-col gap-8">
        <div className="w-auto">
                <div className="mb-2">
                  <p className="text-sm font-bold">Phone</p>
                </div>
                <PhoneInput
                  // country={userDetails?.data?.country_code || 'us'}
                  value={""}
                  buttonStyle={{
                    border: "none",
                    backgroundColor: "transparent",
                    borderRadius: "30px",
                  }}
                  inputStyle={{
                    border: "1px solid #E2E8F0",
                    width: "100%",
                    outline: "none",
                    borderRadius: "8px",
                    height: "42px",
                  }}
                  containerStyle={{
                    display: "flex",
                    alignItems: "center",
                    border: "none",
                    borderRadius: "30px",
                  }}
                  // onChange={(value) => {
                  //   console.log(value)
                  //   methods.setValue(
                  //     "countryCode",
                  //     value
                  //   );
                  // }}
                  onChange={(value, country: any, e, formattedValue) => {
                    console.log('value, formmattedValue', value, formattedValue.trim(), e, country)
                    // methods.setValue("countryCode", country?.dialCode);
                    methods.setValue("phoneNumber", value);
                  }}
                />
                
              </div>
          {/* <FormTextInput
            //  name={"phoneNumber"}
            labelText="Phone Number"
            placeholder="+10000000000"
            {...register("phoneNumber")}
          /> */}
          <div>
          <label
          className={`
            block text-sm font-bold text-gray-900 dark:text-white mb-2
          `}
        >
          Location / Zip Code
        </label>
        <AutoSearch searchResult={searchResults || []} handleSelect={handleLocationChange} getOptionLabel={(option: any) => option?.place_name as string} handleType={setSearchValue} label={"Zip Code/Location"} />

          </div>
          {/* <div>
         
        <FormTextInput
            //name={"email"}
            type="text"
            labelText="Business Address"
            placeholder="Enter your business address..."
            {...register("address")}
          />
          </div> */}
          <FormTextarea rows={6} {...register("description")} name="description" label="Business Description" placeholder="Describe your business" />
        </div>

        {/* File Upload */}
        <div className="min-w-[18rem] h-full pb-3">
          <FileUploader
            id={`file-input-cac`}
            snapType={""}
            isMultiple={true}
            name="pictures"
            variant="type2"

          />
        </div>

        {/* Button Control */}
        <div className="flex fixed bottom-0 left-0 right-0 gap-4 h-[80px] bg-white justify-end border-t border-gray-200">
          <div className="container flex items-center justify-end w-full gap-4">
            <Button variant="light-blue" className="px-10 w-fit">
              Cancel
            </Button>
            <Button
              variant="default"
              size="sm"
              className="w-fit px-14"
              type="submit"
              disabled={!valid} // Disable until form is valid
            >
              Next
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
