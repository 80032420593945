// import { AuthContainer } from "pages/auth/authWrapper/authWrapper";
import { FC, ReactNode, useContext, useEffect, useState } from "react";

import { LayoutContext } from "context/LayoutContext";
import { Navbar } from "./Header/NavBar";

interface LayoutProps {
  children: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  // const [openLogin, setOpenLogin] = useState(false);
  const { modalOpen } = useContext(LayoutContext);

  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
    };
  }, [modalOpen]);

  return (
    <div className="relative min-h-screen flex flex-col font-tv2SansDisplay">
      {modalOpen && <div className="fixed inset-0 z-40  backdrop-blur-sm" />}
      <Navbar
        handleOpenSignin={() => {
          // setOpenLogin(true);
        }}
      />
      <main className="overflow-x-hidden xxlg:mt-[70px]">{children}</main>

      {/* <AuthContainer openLogin={openLogin} setOpenLogin={setOpenLogin} /> */}
    </div>
  );
};
