import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { deleteAllNotification, deleteSingleNotification, getNotifications, markNotificationAsRead } from "services";
import {leadPayment, subscribePlan } from "services/paymentApi";

export const useGetNotifications = (props: {
  is_read?: string
} | null) => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: [
      "notifications",
      {
        is_read: props?.is_read,
      },
    ],
    queryFn:  getNotifications
  });

  return { isLoading, data, refetch  };
}

export const useMarkAsRead = () => {
  const { mutateAsync } = useMutation({
    mutationFn: (params: { id: string}) => 
      markNotificationAsRead(params.id),
  });
  return { markAsRead: mutateAsync };
}


export const useDeleteNotification = () => {
  const { mutateAsync } = useMutation({
    mutationFn: (params: { id: string }) => 
      deleteSingleNotification(params.id),
  });

  return { deleteNotification: mutateAsync };
}

export const useDeleteAllNotification = () => {
  const { mutateAsync } = useMutation({
    mutationFn: () => 
      deleteAllNotification(),
  });

  return { deleteAllNotification: mutateAsync };
}

export const usePayForLead = () => {
  const { mutateAsync } = useMutation({
    mutationFn: (params: { id: string; payload: any }) => 
      leadPayment(params.id, params.payload),
  });

  return { payLead: mutateAsync };
}



//   return { makeConvo: mutateAsync };
//   };
