import React, { DetailedHTMLProps, InputHTMLAttributes, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ReactComponent as CloseEyeIcon } from "assets/svg/closeEyeIcon.svg";
import { ReactComponent as OpenEyeIcon } from "assets/svg/eyeIcon.svg";

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  labelText?: string;
  name: string;
  showInputRequirements?: boolean;
  Icon?: React.ReactElement;
  callback?: () => void;
  customStyle?: { [key: string]: string };
  showIndicator?: boolean;
  icon?: React.ReactNode;
  className?: string;
};

export const FormTextInput = ({
  type,
  name,
  labelText,
  placeholder,
  showInputRequirements,
  // callback,
  // Icon,
  customStyle,
  showIndicator = false,
  icon,
  className,
  ...rest
}: Props) => {
  const { formState, register } = useFormContext();
  const { errors } = formState;
  const [show, setShow] = useState(false);
  const errorMessage = errors[name]?.message;

  // const value = watch()[name];
  // const noInputVal = !value || value.length < 1;
  // const isNumber = /\d/.test(value);
  // const isLengthValid = value?.length > 6;
  // const isLowercase = /[a-z]/.test(value) && !noInputVal;
  // const isUppercase = /[A-Z]/.test(value);
  // const isSpecialChar = /[@$!%*?&#]/.test(value);

  return (
    <div className="w-full">
      {labelText !== "" && (
        <label
          htmlFor={name}
          className={`${
            className
              ? className
              : "block text-sm font-bold text-gray-900 dark:text-white "
          } mb-2 `}
        >
          {labelText}
        </label>
      )}
      <div className="relative" style={customStyle}>
        {icon && (
          <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
            {icon}
          </div>
        )}
        {type === "password" && showIndicator && (
          <button
            type="button"
            id="tooltip-clickable"
            className="absolute inset-y-0 z-20 h-6 w-6 -translate-y-1/2 cursor-pointer"
          >
            {
              <div
                className={errorMessage ? "text-red-500" : "text-green-600"}
              />
            }
          </button>
        )}
        <input
          placeholder={placeholder}
          type={type && !show ? type : "text"}
          step={"any"}
          {...register(name)}
          {...rest}
          id={name}
          className={`${
            formState.errors[name]
              ? "text-red-600 ring-red-500 focus-within:ring-red-500"
              : "text-[#302F2C] focus-within:ring-[#4188F9]"
          } ${
            icon ? "ps-10 p-2.5" : "ps-3 p-2.5"
          }   bg-gray-50 ring-1 h-[50px] border outline-none border-none   border-[#E2E8F0] placeholder:text-[#AAAAAA] placeholder:font-normal placeholder:text-xs ring-[#E2E8F0] transition-all duration-300 ease-in-out text-sm rounded-lg block w-full dark:bg-gray-700  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
          style={customStyle}
        />

        {/* relative flex h-[50px] w-full  rounded-md border-none bg-[#F9FAFB] pl-2 text-sm font-light  text-[#333] outline-none  ring-1 border border-[#E2E8F0] placeholder:text-[#AAAAAA] placeholder:font-normal placeholder:text-xs ring-[#E2E8F0] transition-all duration-300 ease-in-out  dark:bg-gray-700  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500   */}

        {type === "password" && (
          <button
            onClick={() => setShow(!show)}
            type="button"
            className="absolute right-2 inset-y-0 end-5 top-0 text-black text-opacity-70"
          >
            {!show ? <OpenEyeIcon /> : <CloseEyeIcon />}
          </button>
        )}
      </div>

      {errorMessage &&
        typeof errorMessage === "string" &&
        !showInputRequirements && (
          <p
            className={`w-full mt-2 bg-inherit text-xs text-red-500 transition-all duration-300 ease-in-out ${
              errors[name] ? "top-[calc(100%_+_0.3rem)]" : "top-[50%] z-[-1]"
            }`}
          >
            {errorMessage}
          </p>
        )}
    </div>
  );
};
