import React from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

interface AsyncSelectLocationProps {
  onChange: (selectedOption: any) => void;
  placeholder: string;
}

const AsyncSelectLocation: React.FC<AsyncSelectLocationProps> = ({ onChange, placeholder }) => {
  const fetchMapboxData = async (inputValue: string ) => {
    if (!inputValue?.trim()) {
      return [];
    }

    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      inputValue
    )}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}`;

    try {
      const response = await axios.get(url);
      return response.data.features.map((feature: { id: any; place_name: any; geometry: any; }) => ({
        value: feature.id,
        label: feature.place_name,
        geometry: feature.geometry
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to fetch Mapbox data", error);
      return [];
    }
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={fetchMapboxData}
      defaultOptions={[]}
      onChange={onChange}
      placeholder={placeholder}
      className="form-control w-full"
    />
  );
};

export default AsyncSelectLocation;