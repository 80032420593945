import React, { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ProfileIcon } from "assets/svg";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  otpSchema,
  passResetReqSchema,
  resetPasswordSchema,
} from "../authWrapper/schema";
import { useMutation } from "@tanstack/react-query";

import { ConfirmPassword } from "../confirmPassword";
import { AddNewPassword } from "../addPassword";
import { notify } from "shared";
import { FormTextInput } from "ui/formTextInput";
import { Button } from "ui/button";
import { RegistrationCard } from "@component";
import {
  resetPassword,
  confirmOTP,
  createPassword,
  createAnEmail,
} from "services";
import {
  IResetPasswordType,
  IOtpType,
  ICreatePasswordType,
  ICreateEmailType,
} from "types";

export const ForgotPassword = ({
  openForgetPassword,
  setOpenForgetPassword,
  // handleOpenLogin,
}: {
  openForgetPassword: string;
  setOpenForgetPassword: Dispatch<SetStateAction<string>>;
  // handleOpenLogin: () => void;
}) => {
  const [openConfirmPassword, setOpenConfirmPassword] = useState<string>("");
  const [secretKey, setSecretKey] = useState("");
  const [openNewPassword, setOpenNewPassword] = useState(false);
  const { mutate, isPending: isFetching } = useMutation({
    mutationFn: async (payload: IResetPasswordType) =>
      await resetPassword({ ...payload }),
    onSuccess: async (response) => {
      setOpenConfirmPassword("confirm-password");
      setOpenForgetPassword("");
      setSecretKey(response?.data);
      notify.success({
        message: "Successful",
        subtitle: response?.message,
      });
      // navigate("/admin");
    },
    onError: async ({ response }: any) => {
      notify.error({
        message: "Error",
        subtitle: response?.data?.error,
      });
      // setOpenConfirmPassword(false);
    },
  });

  const { mutate: otpMutate, isPending: isValidating } = useMutation({
    mutationFn: async (payload: IOtpType) => await confirmOTP({ ...payload }),
    onSuccess: async () => {
      setOpenConfirmPassword("");
      setOpenNewPassword(true);
    },
    onError: async (response) => {
      notify.error({
        message: response?.message,
      });
      // setOpenConfirmPassword(false);
    },
  });

  const { mutate: passwordMutate, isPending: isCreating } = useMutation({
    mutationFn: async (payload: ICreatePasswordType) =>
      await createPassword({ ...payload }),
    onSuccess: async (response) => {
      // handleOpenLogin();
      setOpenForgetPassword("login");
      setOpenNewPassword(false);
      notify.success({
        message: "Sucessfull",
        subtitle: response?.message || "Password created successfully",
      });
    },
    onError: async (response) => {
      notify.error({
        message: response?.message,
      });
      // setOpenConfirmPassword(false);
    },
  });

  const { isPending: isLoading } = useMutation({
    mutationFn: async (payload: ICreateEmailType) =>
      await createAnEmail({ ...payload }),
    onSuccess: async (response) => {
      setSecretKey(response?.data);
      setOpenConfirmPassword("confirm-password");
      notify.success({
        message: "Success",
        subtitle: "sent successfully",
      });
    },
    onError: async () => {
      notify.error({
        message: "Bad request",
        subtitle: "error",
      });
    },
  });

  const otpMethods = useForm({
    mode: "onChange",
    resolver: yupResolver(passResetReqSchema),
    // defaultValues,
  });

  const otpMethod = useForm({
    mode: "onChange",
    resolver: yupResolver(otpSchema),
    // defaultValues,
  });

  const { otp } = otpMethod.watch();

  const newPasswordMethod = useForm({
    mode: "onChange",
    resolver: yupResolver(resetPasswordSchema),
    // defaultValues,
  });

  const onSubmit = (payload: IResetPasswordType) => {
    mutate(payload);
  };

  const onSubmitOTP = (payload: IOtpType) => {
    otpMutate({
      secret_key: secretKey,
      action: "ACTIVATE_USER",
      otp: payload?.otp,
    });
  };

  const onSubmitNewPassword = (payload: ICreatePasswordType) => {
    passwordMutate({
      password: payload?.password,
      secret_key: secretKey,
      otp,
    });
  };
  return (
    <>
      <RegistrationCard
        show={openForgetPassword === "forgot-password"}
        onClose={() => setOpenForgetPassword("")}
      >
        <div className="w-full flex justify-center">
          <span className="text-xl font-bold">Forget Password</span>
        </div>
        <FormProvider {...otpMethods}>
          <form
            className="flex flex-col gap-5 mt-6 w-full"
            onSubmit={otpMethods.handleSubmit(onSubmit)}
          >
            <FormTextInput
              labelText="Email"
              placeholder="Enter email address..."
              icon={<img src={ProfileIcon} alt="profile icon" />}
              className=" "
              {...otpMethods.register("email")}
            />

            <Button className="w-full" type="submit" loading={isFetching}>
              Continue
            </Button>
          </form>
        </FormProvider>
      </RegistrationCard>

      <ConfirmPassword
        openConfirmPassword={openConfirmPassword}
        setOpenConfirmPassword={setOpenConfirmPassword}
        otpMethod={otpMethod}
        onSubmit={onSubmitOTP}
        handleSignUp={() => ""}
        resendOTP={mutate}
        isLoading={isLoading || isValidating}
        requestOTPMethod={otpMethods}
        type="forgot-password"
      />

      <AddNewPassword
        openNewPassword={openNewPassword}
        methods={newPasswordMethod}
        onSubmit={onSubmitNewPassword}
        isLoading={isCreating}
        onClose={setOpenNewPassword}
      />
    </>
  );
};
