import { useQuery } from "@tanstack/react-query";
import { getUserLeads, getUserProfile } from "services";

export const useGetLeads = () => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["user-leads"],
    queryFn: getUserLeads,
  });

  return { isLoading, leads: data, refetch};
};
