import { SettingsLayout } from "context/settings/layout/SettingsLayout";
// import { ToggleSwitch } from "flowbite-react";
import React, { useState } from "react";
import AvatarImage from 'assets/images/avatarImage.png'
import { JobLeadPayModal, LeadPayModal} from "context/settings/services/ServiceModals";
import { useGetLeads } from "hooks/jobs/useGetLeads";
import { usePayForLead} from "hooks/subscription/useGetSubscriptions";
import { notify } from "shared";
import { Badge } from "flowbite-react";
import { useGetConversation } from "hooks/services/useGetConversation";
import { useNavigate } from "react-router-dom";
import { Button } from "ui/button";
export const Leads = () => {
  const navigate = useNavigate();
  const [listPayModal, setListPayModal] = useState(false)
  const [jobPayModal, setJobPayModal] = useState(false)

  const [lead, setLead] = useState()
  const { leads } =  useGetLeads();

  const handleListPayModalClose = () => {
    setListPayModal(false)
    setJobPayModal(false)
  }
  // const handleListPaySubmit = (data: any) => {
  //     console.log('first', data)
  // }

    const { payLead } = usePayForLead();

    const { initiateConvo } = useGetConversation();
  
    const handleConvoInitiation = async (id: string) => {
      const res = await initiateConvo({ user: id });
      if (res?.success) {
        navigate(`/chat/${res?.data?.id}`);
      }
    };
  
  const  handleListPaySubmit = async (data: any) => {
    const response = await payLead({
      id: data?.id,
      payload: {
        amount : 10
      }
    });
    if(response?.success === true){
        notify.success({
        message: 'Redirecting to payment page',
      })
      // close modal and clear price state
      setListPayModal(false);
      // redirect to payment page in another page 
      window.location.href = response?.data;
    }
    else if(response?.success === false){
      notify.error({
        message: "Error ",
        subtitle: response?.error,
      })
    }
  };


  return (
    <SettingsLayout>
     <div className="my-12 lg:my-6">
     <div className=" flex flex-col items-center justify-center w-full mx-auto bg-white ">
    <div className="w-full px-4 py-5 border-b sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
            Leads
        </h3>
       
    </div>
    {leads?.results?.length === 0 && (
          <div className="flex flex-col items-center justify-center w-full h-[300px]">
            <h4 className="text-lg font-medium text-gray-900 dark:text-white">
              No leads available
            </h4>
            <Button onClick={() => navigate('/pricing')} className="mt-4">
              Subscribe to get leads
            </Button>
          </div> )
        }
    <ul className="flex flex-col divide-y divide w-full">
   
      {leads?.results?.map((lead: any) => (
        <li onClick={()=> { 
          if(lead?.paid === false){
            setLead(lead)
            lead?.is_job === false ?  setListPayModal(true):  setJobPayModal(true)
          }
          else{
            handleConvoInitiation(lead?.user?.id)}
          }} key={lead?.id} className="flex flex-row">
            <div className="flex items-center flex-1 p-4 cursor-pointer select-none">
                <div className="flex flex-col items-center justify-center w-10 h-10 mr-4">
                    <a href="#" className="relative block">
                        <img alt="profil" src={lead?.user?.profile_picture || AvatarImage} className="mx-auto object-cover rounded-full h-10 w-10 "/>
                    </a>
                </div>
                <div className="flex-1 pl-1 mr-16 flex items-start gap-3">
                  <div className="">
                    <div className="font-medium dark:text-white">
                        {lead?.user?.firstname + ' ' + lead.user.lastname}
                      </div>
                      <div className="text-sm text-gray-600 dark:text-gray-200 line-clamp-1">
                        {lead?.message}
                      </div>
                  </div>
                  {/* {lead?.paid === false &&  <Badge color="failure">Not Paid</Badge>} */}
                   
                </div>
                <div className="flex items-center gap-2">
                {lead?.paid === false ?  <Badge color="failure">Not Paid</Badge> :  
                <button  className="flex justify-end w-24 text-right">
                    <svg width="20" fill="currentColor" height="20" className="text-gray-500 hover:text-gray-800 dark:hover:text-white dark:text-gray-200" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                        </path> 
                    </svg>
                </button>}
            {/* <span className="text-sm font-medium text-red-600 bg-red-100 px-2 py-1 rounded">
              {item.status}
            </span> */}
           
          </div>
                
            </div>
        </li>
      ))}
        
      
        
    </ul>
</div>
     </div>

{lead && <LeadPayModal lead={lead} handleListPayModalClose={handleListPayModalClose} handleListPaySubmit={handleListPaySubmit} listPayModal={listPayModal} />} 
{lead && <JobLeadPayModal lead={lead} handleListPayModalClose={handleListPayModalClose} handleListPaySubmit={handleListPaySubmit} listPayModal={jobPayModal} />}


    </SettingsLayout>
  );
};
