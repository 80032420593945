import { useQuery } from "@tanstack/react-query";
import { getProfileById } from "services";

export const useGetProfileById = (id: string) => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["single-user-profile"],
    queryFn: () => getProfileById(id),
  });
  return { singleProfileLoading: isLoading, singleProfileData: data, refetch};
};
