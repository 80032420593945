import Api from "utils/auth/api";

export const getPricing = async () => {
  return Api.get("/subscription-plan/").then((res) => res.data);
};

export const subscribePlan = async (id: string, payload: any) => {
  return Api.post<any>(
    `/subscription-plan/${id}/subscribe/`,
    payload
  ).then((res) => res.data)
  .catch((err) => {
    return err.response.data;
  });
};


export const leadPayment = async (id: string, payload: any) => {
  return Api.post<any>(
    `/lead/${id}/payment/`,
    payload
  ).then((res) => res.data)
  .catch((err) => {
    return err.response.data;
  });
};
// /api/verify-payment/

export const getPaymentStatus = async (jwt_token: string) => {
  return Api.get(`/verify-payment/?token=${jwt_token}`).then((res) => res.data).catch((err) => {
    return err.response.data;
  });
};
