import clsx from "clsx";

export interface NotificationMsgProps {
  message?: string;
  subtitle?: string;
  type?: "success" | "error";
}

export function NotificationMsg({
  message,
  subtitle,
}: NotificationMsgProps) {
  return (
    <div className="flex flex-col">
      {message && (
        <h2
          className={clsx(
            "text-sm font-bold tracking-[0.2px] first-letter:capitalize",
            // type === "success" ? " text-green-500" : " text-red-500"
          )}
        >
          {message}
        </h2>
      )}

      {subtitle && (
        <p className="text-xs !text-[#9b9a9a]">
          {subtitle[0].toUpperCase() + subtitle.slice(1)}
        </p>
      )}
    </div>
  );
}
