import { useAuthenticatedUser, useUpdateServiceProviderProfile } from 'hooks';
import { ArrowBigRight, Edit2, LucidePlus, Trash2 } from "lucide-react"
import { useGetServiceProfileById } from 'hooks/profile/useGetServiceProfileById';
import { CircularProgress } from '@mui/material';
// import CurrencyInput from 'react-currency-input-field'
import locationIcon from "../../../assets/svg/location.svg"
import React, { useEffect, useState } from 'react'
import debounce from "lodash/debounce";
import { Button } from 'ui/button';
import { SUB_CATEGORY } from 'types';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { getSkill } from 'services';
import { BoostModal, DeleteServiceModal, ListPayModal, ListServiceModal, NewServiceModal, SearchServiceModal } from './ServiceModals';
import { Input } from 'assets';
import { FormTextInput } from 'ui/formTextInput';
import { FormProvider, useForm } from 'react-hook-form';
import { getPlaceInfo } from 'utils/getLocation';
import axios from 'axios';
import { AutoSearch } from 'component/oldcomponent/HeroSection/AutoSearch';
import { notify } from 'shared';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0.3px solid #24242480',
  boxShadow: "0px 4px 4px 0px #00000040",
  pb: 3,
  borderRadius: 2
};

type skillType = { name: string, id: string }

export const MyServices: React.FC = () => {
  const [listModal, setListModal] = useState(false)
  const [searchModal, setSearchModal] = useState(false)
  const [newModal, setNewModal] = useState(false)
  const [boostModal, setBoostModal] = useState(false)
  const [listPayModal, setListPayModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [price, setPrice] = useState("")
  const [activeSkill, setActiveSkill] = useState<SUB_CATEGORY>()
  const [term, setTerm] = useState("");
  const [searchValue, setSearchValue] = useState("")
  const [searchResults, setSearchResults] = useState<any>([]);
  const [newSkill, setNewSkill] = useState<skillType>()
  const [skills, setSkills] = useState<any>([])
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState<{address: string, longitude: string, latitude: string}>({
    address: "",
    longitude: "",
    latitude: ""
  })
  const queryClient = new QueryClient();

  const { updateServiceProviderMutation, isUpdatingServiceProvider } =
  useUpdateServiceProviderProfile();
  const { userDetails, isLoading } = useAuthenticatedUser();
  const { serviceProfileData, serviceProfileLoading, refetchServiceProfile } =
    useGetServiceProfileById(userDetails?.data?.service_profile as string);


    const handleBusinessNameUpdate = async () => {
      setLoading(true)
      const formData = {
        "business_name": editedName,
      }
      const res = await updateServiceProviderMutation(formData);
      if (res?.success == true) {
        await refetchServiceProfile()
        handleNewModalClose()
        setIsEditing(false);
        setLoading(false)
        notify.success({ message: "business updated successfully" });
      }
    };
  
    const handleLocationChange = (_: any, value: any) => {
      setLocation({
        address : value?.place_name,
        longitude: value?.geometry?.coordinates[0],
        latitude: value?.geometry?.coordinates[1]
      });
    };
  
    const changeBusinessLocation = async () => {
      setLoading(true)
      const res = await updateServiceProviderMutation(location);
      if (res?.success == true) {
        await refetchServiceProfile()
        handleNewModalClose()
        setIsEditingLocation(false)
        setLoading(false)
        notify.success({ message: "business updated successfully" });
      }
    
    }
    useEffect(() => {
      const getLocation = async () => {
        // setEditedName(serviceProfileData?.business_name || "");
        if (serviceProfileData?.longitude && serviceProfileData?.latitude) {
          const response = await getPlaceInfo(serviceProfileData.longitude, serviceProfileData.latitude);
          console.log(response);
        }
      };
      getLocation();
    }, [serviceProfileData])

    useEffect(() => {
      const updatedSkills = serviceProfileData?.sub_category.map((item) =>( {
      cost: Number(item.cost),
      time_frame: item.time_frame,
      skill: item?.sub_category_id,
      skillName: item?.sub_category!,
      }))
      setSkills(updatedSkills)
    },[serviceProfileData?.sub_category] )

  const handleListModalClose = () => {
    setListModal(false)
  }

  const handleSearchModalClose = () => {
    setSearchModal(false)
  }

  const handleNewModalClose = () => {
    setNewModal(false)
  }

  const handleListPayModalClose = () => {
    setListPayModal(false)
  }

  const handleBoostModalClose = () => {
    setBoostModal(false)
  }

  const handleDeleteModalClose = () => {
    setDeleteModal(false)
    
  }

  const handleServiceSelect = (_: any, value: skillType | null) => {
    setNewSkill(value!)
  }

  const handleAddNewSkill = async () => {
   
    const obj = {
      cost: Number(price),
      time_frame: "HOURLY",
      skill: newSkill?.id,
      skillName: newSkill?.name!,
    }
    // Check if the skill already exists in the skills array
const skillExists = skills.some((skill: any) => skill.skill === obj.skill);

let formData;

if (skillExists) {
  // If the skill exists, replace it in the skills array
  formData = {
    skill_data: skills.map((skill: any) => 
      skill.skill === obj.skill ? obj : skill // Replace if it matches
    )
  };
} else {
  // If the skill does not exist, add it to the skill_data array
  formData = {
    skill_data: [
      ...skills,
      obj
    ]
  };
}

    const res = await updateServiceProviderMutation(formData);
 
    if (res?.success == true) {
      await refetchServiceProfile()
      handleNewModalClose()
      handleSearchModalClose()
      setNewModal(false)
      notify.success({ message: "business updated successfully" });
    }
    if (res?.response?.data?.success == false) {
      notify.error({
        message: "Error while updating user information",
        subtitle: res?.response?.data?.message,
      })
    }
  }

 const handleDeleteService = async (skill: any) => {
    const filtered = skills.filter((item: any) => item.skill !== skill.skill)
   const formData = {
      skill_data: filtered
    }

    const res = await updateServiceProviderMutation(formData);
 
    if (res?.success == true) {
      await refetchServiceProfile()
      handleDeleteModalClose()
      setNewModal(false)
      notify.success({ message: "service deleted successfully" });
    }
    if (res?.response?.data?.success == false) {
      notify.error({
        message: "Error while updating user information",
        subtitle: res?.response?.data?.message,
      })
    }

  }

  const handleBoostSubmit = () => {

  }

  const handleListPaySubmit = () => {

  }
  const methods = useForm({
    mode: "onChange", // Enables real-time validation
    // resolver: yupResolver(profileSchema), // Custom resolver
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isValid, }, // Access form validation state
    register,
    watch
  } = methods;

  useEffect(() => {
    // Debounce the search function to reduce API calls
    const delayedSearch = debounce(() => {
      if (searchValue.trim() !== "") {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          searchValue,
        )}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}`;

        axios.get(url)
          .then((response: any) => {
            setSearchResults(response.data.features);
          })
          .catch((error: any) => {
            console.error(error);
          });
      } else {
        setSearchResults([]);
      }
    }, 300);

    delayedSearch();

    // Clean up the debounce function on unmount
    return () => delayedSearch.cancel();
  }, [searchValue]);


  const { data, refetch } = useQuery({
    queryKey: ["service"],
    queryFn: () => getSkill(term),
    enabled: !!term,
  });

  useEffect(() => {
    const delayedSearch = debounce(() => { refetch() }, 600)
    delayedSearch()
    return () => delayedSearch.cancel()
  }, [term, refetch]);

  if (serviceProfileLoading || isLoading) {
    return (
      <div className='w-full h-[90vh] flex justify-center items-center'>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className='p-8 mt-10 lg:mt-0'>
      <div className="mb-8 w-full">
        <div className="mb-4">
          <div>
            {/* add a function to make the business name editable */}
            <div className="flex items-center gap-2">
            {/* {isEditing ? (
              <div className="flex flex-wrap items-center gap-2">
                <input
                  type="text"
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value)}
                  className="text-base w-auto lg:text-[25px] font-semibold px-2 py-1 border rounded"
                  autoFocus
                />
                <Button disabled={loading} size="sm" className='me-0' onClick={handleBusinessNameUpdate}>Save</Button>
                <Button size="sm" className='me-0' variant="outline" onClick={() => setIsEditing(false)}>Cancel</Button>
              </div>
            ) : ( */}
              {/* <> */}
                <h2 className="tex-base lg:text-[25px] font-semibold">
                  {serviceProfileData?.business_name}
                </h2>
                {/* <span
                  
                  onClick={() => {
                    setIsEditing(true);
                    setEditedName(serviceProfileData?.business_name || "");
                  }}
                >
                  <Edit2 className="lg:h-4 lg:w-4 w-3 h-3" />
                </span>
              </>
            )} */}
          </div>
         
          </div>
         <div className='flex items-center gap-2 w-full'>
       
               <p className="flex gap-2 mt-2 text-sm lg:text-lg" style={{ textTransform: "capitalize" }}><img src={locationIcon} alt="" /> 
          Based In: 
         {serviceProfileData?.address}
          </p>
               
         </div>
          
        </div>
        
      </div>
    
      <div className='flex justify-between '>
        <div>
        <h5 className='font-semibold'>Your Services</h5>
      <p className='my-2' style={{ color: "gray", fontSize: "14px" }}>Add Targeting Preferences To Get The Jobs You Want.</p>
      <a href="/" style={{ color: "blue", cursor: "pointer", textDecoration: "underline", fontSize: "14px" }}>Targeting Preferences Guide</a>
     
        </div>
      <div className="">
          <Button onClick={() => setSearchModal(true)} className='lg:hidden'  variant="outline" size="sm">
            <LucidePlus size="16" />
          </Button>
          <Button size="sm" className='hidden lg:block' onClick={() => setSearchModal(true)} variant="outline">Add New Service</Button>
        </div>
      </div>
      <div className='w-full mt-8'>
        {
          skills?.map((service: any, i: any) => (
            <div  key={i} className='flex justify-between w-full py-3 cursor-pointer' style={{ borderTop: "0.4px solid grey", borderBottom: "0.4px solid grey" }}>
              <div key={i} className='flex w-full py-3 items-center cursor-pointer' 
                 style={{ borderTop: "0.4px solid grey", borderBottom: "0.4px solid grey" }}>
                 <span className='text-[14px]'>{service?.skillName}</span> 
                 <Trash2 className="w-4 h-4 text-muted-foreground ml-auto mr-4" onClick={() => {
                    setDeleteModal(true)
                    setActiveSkill(service)
                  }} />
                  <ArrowBigRight  onClick={() => { 
                setActiveSkill(service); setPrice(String(service?.cost));
                 setListModal(true) }} className="w-6 h-6 text-muted-foreground " />
                  {/* icon to Delete */}
                  
                </div>
              <div>
                {/* <Button>Edit</Button>
                <Button>Delete</Button> */}
              </div>
            </div>
            // <div onClick={() => { setActiveSkill(service); setPrice(String(service?.cost)); setListModal(true) }} key={i} className='flex justify-between w-full py-3 cursor-pointer' style={{ borderTop: "0.4px solid grey", borderBottom: "0.4px solid grey" }}>{service?.sub_category} <ArrowBigRight className="w-6 h-6 text-muted-foreground" /></div>
          ))
        }
      </div>
      <DeleteServiceModal deleteService={handleDeleteService} deleteModal={deleteModal} activeSkill={activeSkill} setDeleteModal={setDeleteModal} handleDeleteModalClose={handleDeleteModalClose} />
        <ListServiceModal setBoostModal={setBoostModal} listModal={listModal} setPrice={setPrice} activeSkill={activeSkill} setListPayModal={setListPayModal} handleListModalClose={handleListModalClose} price={price} />
        <SearchServiceModal searchModal={searchModal} setNewModal={setNewModal} setTerm={setTerm} data={data} handleSearchModalClose={handleSearchModalClose} handleServiceSelect={handleServiceSelect} />
        <NewServiceModal handleAddNewSkill={handleAddNewSkill} handleNewModalClose={handleNewModalClose} setPrice={setPrice} newModal={newModal} newSkill={newSkill} />
        <ListPayModal handleListPayModalClose={handleListPayModalClose} handleListPaySubmit={handleListPaySubmit} listPayModal={listPayModal} />
        <BoostModal boostModal={boostModal} handleBoostModalClose={handleBoostModalClose} handleBoostSubmit={handleBoostSubmit} />
    </div>
  )
}