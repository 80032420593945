import React from "react";

import { ExploreServices } from "data/ExploreData";
import { Paragraph } from "assets";
import { FaChevronRight } from "react-icons/fa";
import { AdzCard } from "ui/adzCard";
import { useNavigate } from "react-router-dom";

const data = ExploreServices;

interface IExplore {
location?: {
  longitude?: string,
  latitude?: string,
}
}

export const Explore = ({
 location
}: IExplore) => {
  const navigate = useNavigate();
  return (
    <div className="w-full px-6 pb-6 pt-9 ">
      <div className="container flex flex-col w-full gap-6 ">
        <div className="flex items-center justify-between w-full ">
          <span className=" text-base md:text-[24px] font-bold">
            Explore Home Services
          </span>
          <span
            onClick={() => navigate("/search")}
            className="cursor-pointer font-bold text-[#4188F9] text-xs md:text-base"
          >
            View More
          </span>
        </div>
        <div className="grid h-full grid-cols-2 gap-4 lg:grid-cols-4 md:grid-cols-3">
          {
            data[0].services.map((service, i) => (
              <div key={Math.random()}>
                <AdzCard
                  img={service.img}
                  list={service.list}
                  name={service.name}
                  searchValue={service?.searchValue}
                  longitude={location?.longitude}
                  latitude={location?.latitude}
                />
              </div>
            ))
          }
        </div>
      </div>

      <div className="container flex flex-col w-full gap-6 my-14">
        <div className="flex items-center justify-between w-full ">
          <span className=" text-base md:text-[24px] font-bold">
            Explore More Services
          </span>
          <span
            onClick={() => navigate("/search")}
            className=" font-bold text-[#4188F9] text-xs md:text-base cursor-pointer"
          >
            View More
          </span>
        </div>
        <div className="grid h-full grid-cols-2 gap-4 lg:grid-cols-4 md:grid-cols-3">
          {
            data[1].services.map((service, i) => (
              <div key={Math.random()}>
                <AdzCard
                  img={service.img}
                  list={service.list}
                  name={service.name}
                />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};
