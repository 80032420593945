import { Footer } from '@component'
import { Box, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { SubscribeModal } from 'context/settings/services/ServiceModals';
import { useGetSubscriptions, useSubscribePlan } from 'hooks/subscription/useGetSubscriptions';
import { toNumber } from 'lodash';
import React, { useState } from 'react'
import { notify } from 'shared';

function Pricing() {

  const { isLoading, data } = useGetSubscriptions();
  
  const { subscribePlan } = useSubscribePlan();

  const [newModal, setNewModal] = useState(false);
  const [newSkill, setNewSkill] = useState<any>(null);
  const [price, setPrice] = useState(0);

  const handleSubscribeModalClose = () => {
    setNewModal(false);
  };

  const handleSubscribe = async (id: string) => {
    const response = await subscribePlan({
      id,
      payload: {
        amount : toNumber(newSkill?.max_cost)
      }
    });
    if(response?.success === true){
        notify.success({
        message: 'Redirecting to payment page',
      })
      // close modal and clear price state
      setNewModal(false);
      setPrice(0);
      // redirect to payment page in another page 
      window.location.href = response?.data;
    }
    else if(response?.success === false){
      notify.error({
        message: "Error ",
        subtitle: response?.error,
      })
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', width: "100%", height: "97vh", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Box>
    )
  }
  
  return (
    <div>
<section className="text-gray-600 body-font overflow-hidden">
  <div className="container px-5 py-24 mx-auto">
    <div className="flex flex-col text-center w-full mb-16">
      <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">Pricing</h1>
      
    </div>
    <div className="flex flex-wrap justify-center -m-4">
      {data?.results?.map((item: any) => (
        <div key={item.id}  className="p-4 group  xl:w-1/4 md:w-1/3 w-full">
        <div className="h-full p-6 rounded-lg border-2 group-hover:border-[#4188F9] border-gray-300 flex flex-col relative overflow-hidden">
          <h2 className="text-sm tracking-widest title-font mb-1 font-medium ">{item?.max_cost === '1.00' ? 'Free' : `$${item?.max_cost}`}/{item?.period}</h2>
          <h1 className="text-2xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none text-nowrap capitalize">{item?.name}</h1>
         
         {item?.perk?.reverse()?.map((feature: any) => (
         <p key={feature.id} className="flex text-sm  items-center text-gray-600 mb-2">
            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>
            {feature?.content}
          </p>
            ))}
      
      {/* add a class to hide if name includes fundamental */}
            <div  className={`${item.name === 'AudtikLance Fundamentals' ? 'hidden' : 'flex'} items-center mt-auto group-hover:bg-[#4188F9] text-white bg-gray-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded`}> 
          <button onClick={() => { setNewModal(true); setNewSkill(item)}} className="flex items-center mt-auto group-hover:bg-[#4188F9] text-white bg-gray-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded">
            Upgrade
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button>
          </div>
        </div>
      </div>
      ))}
      
    </div>
  </div>
</section>

<SubscribeModal handleSubmit={handleSubscribe} handleSubscribeModalClose={handleSubscribeModalClose} setPrice={setPrice} newModal={newModal} newSkill={newSkill} />


<Footer prop1={"string"} />
    </div>
  )
}

export default Pricing