import { Autocomplete, TextField } from '@mui/material'
import React from 'react'

export const AutoSearch: React.FC<{searchResult: any, defaultValue?: any, handleSelect: any, handleType: any, label: any, getOptionLabel: any, labelStyles?: any, height?: any}> = ({searchResult, defaultValue, handleSelect, handleType, label, labelStyles, height  ,getOptionLabel}) => {
   
    return (
        <Autocomplete
            options={searchResult}
            getOptionLabel={getOptionLabel}
            onChange={handleSelect}
            freeSolo
            defaultValue={defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    onChange={(e) => handleType(e.target.value)}
                    InputLabelProps={{
                        sx: {
                          "&.Mui-focused": {
                            // paddingBottom: '2rem',
                            position: 'absolute',
                            ...labelStyles,
                          },
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          color: "grey",
                        },
                        "& .MuiInputLabel-root": {
                          color: "grey",
                        },
                        height: height || 'auto', // Apply the dynamic height
                      }}
                />
            )}
            sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                        borderColor: "#10172A" // Keep border as default on hover
                    },
                    "&.Mui-focused fieldset": {
                        // padding: '2rem',
                        borderColor: "#10172A", // Remove blue border on focus
                    },
                },
            }}
        />
    )
}
