import axios, { AxiosRequestConfig } from "axios";
// import axios, { AxiosRequestConfig } from "axios";
import { BehaviorSubject } from "rxjs";
import {
  getRefreshToken,
  getToken,
  isAuthenticated,
  removeToken,
  setToken,
} from "./auth";
import { notify } from "shared";

let refreshed = false;
const getBaseUrl = () => process.env.REACT_APP_API_BASE_URL;
export const subscriber = new BehaviorSubject(0);

const Api = axios.create({
  baseURL: getBaseUrl(),
});

// Request interceptor for API calls
Api.interceptors.request.use(
  // @ts-ignore
  async (config: AxiosRequestConfig) => {
    if (!config?.headers?.noBearerToken) {
      config.headers = {
        Authorization: isAuthenticated() ? `Bearer ${getToken()}` : "",
      };
    }

    delete config?.headers?.noBearerToken;
    return config;
  },
  (err) => {
    if (
      err?.response?.status === 401 &&
      // @ts-ignore
      err?.response?.data?.detail === "Incorrect authentication credentials." &&
      !refreshed
    ) {
      refreshed = true;
      Api.post("/auth/token/refresh/", {
        refresh: getRefreshToken(),
      })
        .then((res: any) => {
          setToken(res.data.access);
          return Api(err.config);
        })
        .catch((error) => {
          notify.error(error?.error || "Session expired");
          setTimeout(() => {
            removeToken();
            window.history.pushState({}, "User Login", "/");
          }, 4000);
          return Promise.reject(error);
        });
      return;
    }
    refreshed = false;
    // Promise.reject(err);
    return Promise.reject(err);
  },
);

// Response interceptor for API calls
// Api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async function (error) {
//     return Promise.reject(error);
//   },
// );

// function refreshToken(token: string) {
//   return Api.post("/auth/token/refresh/", {
//     refresh: token,
//   });
// }

export const Post = async (
  url: string,
  data: any,
  config?: any,
  newUrl?: string,
) => {
  try {
    return await axios.post(`${newUrl ? newUrl : getBaseUrl()}${url}`, data, {
      responseType: "blob",
      headers: {
        Authorization: isAuthenticated() ? `Bearer ${getToken()}` : "",
        ...config,
      },
    });
  } catch (error: any) {
    subscriber.next(error.response);
    throw error.response;
  }
};

export default Api;
