export const otherServices = [
  // "Plumbing",
  // "Electrical Work",
  {name: 'Plumbing', value: 'plumber'},
  {name: 'Electrical Work', value: 'electrician'},
  // "House Cleaning",
  {name: 'House Cleaning', value: 'cleaning'},
  {name: 'Painting & Decorating', value: 'painter'},
  {name: 'Appliance Repair', value: 'repairs'},
  // "Furniture Assembly",
  {name: 'Furniture Assembly', value: 'carpenter'},
  // "Carpet Cleaning",
  {name: 'Carpet Cleaning', value: 'cleaning service'},
  // "Pest Control",
  {name: 'Window Cleaning', value: 'cleaning'},
  {name: 'Handyman Services', value: 'general_contractor'},
  {name: 'HVAC Maintenance', value: 'hvac_maintenance'},
  {name: 'Home Security Installation', value: 'home_security_installation'},
  {name: 'Roof Repair', value: 'roof_repair'},
  {name: 'Flooring Installation', value: 'flooring_installation'},
  // "Interior Design",
  // "Smart Home Setup",
  // "Moving & Packing Services",
 {name: "Gardening & Landscaping", value: 'gardening'}
  // "Trash Removal",
  // "Pool Cleaning",
  // "Chimney Sweep",
  // "Fence Repair",
  // "Gutter Cleaning",
  // "Tile Installation",
  // "Home Renovation",
  // "Kitchen Remodeling",
  // "Bathroom Remodeling",
  // "Closet Installation",
  // "Attic Insulation",
  // "Basement Waterproofing",
  // "Door Installation & Repair",
  // "Garage Door Installation",
  // "Driveway Sealing",
  // "Deck & Patio Construction",
  // "Septic Tank Maintenance",
  // "Solar Panel Installation",
  // "Home Energy Audits",
  // "Wallpaper Installation",
  // "Shutter & Blind Installation",
  // "Waterproofing Services",
  // "Hardwood Floor Refinishing",
  // "Countertop Installation",
  // "Ceiling Fan Installation",
  // "Pressure Washing",
  // "Tree Trimming & Removal",
  // "Snow Removal",
  // "Holiday Light Installation",
  // "Pet Waste Removal",
  // "Mailbox Installation",
  // "Lawn Aeration",
  // "Sprinkler System Installation",
  // "Home Organization Services",
  // "Grout Cleaning",
  // "Curtain Installation",
  // "Drywall Repair",
  // "Soundproofing",
  // "Window Tinting",
  // "Garage Organization",
  // "Home Theater Setup",
  // "Cable & Wiring Installation",
  // "Fireplace Maintenance",
  // "Exterior Painting",
  // "Storm Damage Repair",
  // "Driveway Repair",
  // "Mirror & Glass Installation",
  // "Fountain & Water Feature Maintenance",
  // "Upholstery Cleaning",
  // "Shower Door Installation",
  // "Leak Detection & Repair",
  // "Home Staging Services",
  // "Cabinet Refacing",
  // "Power Outlet Installation",
  // "Mosquito Control",
  // "Rug Cleaning",
  // "Water Heater Repair",
  // "Bricklaying & Masonry",
  // "Porch Repair",
  // "Well Pump Services",
  // "Security Camera Installation",
  // "Fence Staining",
  // "Garden Irrigation Systems",
  // "Room Additions",
  // "Baby Proofing Services",
  // "Clog Removal",
  // "Awnings Installation",
  // "Greenhouse Setup",
  // "Eavestrough Repair",
  // "Weatherproofing Services",
];

export const serviveByCategory: Record<string, Array<{name: string, value: string}>> = {
  "Popular Home Services": [
    {name: "Handyman Services", value: "handyman"},
    {name: "Plumbing Services", value: "plumber"},
    {name: "HVAC Services", value: "hvac_contractor"},
    {name: "Cleaning Services", value: "cleaning_service"},
    {name: "Moving Services", value: "moving_company"},
    {name: "Landscaping Services", value: "landscaper"},
    {name: "Painting Services", value: "painter"},
    {name: "Concrete Services", value: "masonry_contractor"},
    {name: "Home Inspection Services", value: "home_inspector"},
    {name: "Electrical Services", value: "electrician"},
  ],
  "Cleaning & Organization": [
    {name: "Home Cleaning", value: "cleaning_service"},
    {name: "Organizing Services", value: "home_organizer"},
    {name: "Deep Cleaning", value: "cleaning_service"},
    {name: "Exterior House Cleaning", value: "window_cleaning_service"},
    {name: "Pool Cleaning", value: "pool_cleaning_service"},
    {name: "Post Construction Cleaning", value: "cleaning_service"},
  ],
  "Maintenance & Repair": [
    {name: "General Contractor", value: "general_contractor"},
    {name: "Appliance Repair", value: "appliance_repair_service"},
    {name: "Roofing", value: "roofing_contractor"},
    {name: "Door Repair", value: "door_repair_service"},
    {name: "Painting", value: "painter"},
    {name: "Flooring", value: "flooring_contractor"},
    {name: "Garage Door Repair", value: "garage_door_repair"},
    {name: "Window Repairs", value: "window_repair_service"},
    {name: "Gutter Repair", value: "gutter_cleaning_service"},
  ],
  "Pest Control": [
    {name: "Organic Pest Control", value: "pest_control_service"},
    {name: "Termite Control", value: "pest_control_service"},
    {name: "Rodent Control", value: "pest_control_service"},
    {name: "Wildlife Removal", value: "wildlife_control_service"},
    {name: "Insect Control", value: "pest_control_service"},
  ],
  Plumbing: [
    {name: "Leaky Faucet Repair", value: "plumber"},
    {name: "Drain Cleaning and Unclogging", value: "plumber"},
    {name: "Pipe Leak Repair", value: "plumber"},
  ],
  "Electrical Installation": [
    {name: "Lighting Installation", value: "electrician"},
    {name: "Electrical Outlet Installation", value: "electrician"},
    {name: "Ceiling Fan Installation", value: "electrician"},
  ],
  "Gardening & Landscaping": [
    {name: "Lawn Care and Maintenance", value: "lawn_care_service"},
    {name: "Garden Design and Installation", value: "landscaper"},
    {name: "Tree and Shrub Pruning", value: "tree_service"},
  ],
  "Painting & Decorating": [
    {name: "Interior Painting", value: "painter"},
    {name: "Exterior Painting", value: "painter"},
    {name: "Wallpaper Installation", value: "interior_designer"},
  ],
};

export const skills: string[] = [
  "Roofing",
  "Door Repair",
  "Painting",
  "Flooring",
  "Garage Door Repair",
  "Window Repairs",
  "Gutter Repair",
  "Organic Pest Control",
  "Termite Control",
  "Rodent Control",
  "Wildlife Removal",
  "Insect Control",
  "Lawn Care and Maintenance",
  "Garden Design and Installation",
  "Tree and Shrub Pruning",
  "Lighting Installation",
  "Electrical Outlet Installation",
  "Ceiling Fan Installation",
];
