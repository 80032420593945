import React from "react";
import { ReactComponent as NotificationIcon } from "assets/svg/notification.svg";
import { ReactComponent as SettingsIcon } from "assets/svg/setting-2.svg";
import { ReactComponent as ProfileIcon } from "assets/svg/profile.svg";
import { LucideHelpingHand } from "lucide-react"
import { Link, useLocation } from "react-router-dom";
import { UpdateProfile } from "../profile/UpdateProfile";
import { FaUserEdit } from "react-icons/fa";
import { CiCreditCard1 } from "react-icons/ci";
import { MdOutlineFeaturedPlayList } from "react-icons/md";
import { NavigationTab } from "PagesData/SettingsNav";
import { useAuthenticatedUser } from "hooks";

export const SettingsLayout = ({ children }: { children: React.ReactNode }) => {
  const currentPath = useLocation();
  const { userDetails } = useAuthenticatedUser();

  const isLinkActive = (href: string) => currentPath?.pathname === href;

  return (
      <section className="md:border h-screen md:border-[#E2E8F0] mt-4 flex rounded-xl">
      <div className={`border-r border-[#E2E8F0] sticky top-[0px] h-[calc(100vh_-_80px)]  w-[305px] hidden  ${userDetails?.data?.service_profile ? "md:block" : "hidden"}`}>
        {/* <div className="border-b-[#E2E8F0] border-b py-4 flex items-center">
          <span className="px-4 text-sm font-bold">Settings</span>
        </div> */}
        <div className={`flex flex-col h-full gap-1 py-4 mx-3 overflow-y-auto ${userDetails?.data?.service_profile ? "" : "hidden"}`}>
          {NavigationTab?.map((v) => (
            <Link
              key={Math.random()}
              // className="flex items-center gap-2 px-4 py-2 rounded-lg cursor-pointer hover:bg-blue-50"
              className={`text-sm flex items-center cursor-pointer hover:bg-blue-50 py-2 px-4 rounded-lg gap-2 hover:text-primary-500 ${
                isLinkActive(v?.path) ? "font-semibold text-blue-500" : ""
              }`}
              to={v?.path}
            >
              <span>{v?.icon}</span>
              <span className="sm">{v.title}</span>
            </Link>
          ))}
        </div>
      </div>
      <div  className="flex-1 hideScrollBar overflow-y-auto h-[calc(100vh_-_80px)] mt-8">
        <main className={`w-full px-4 max-w-screen-xxlg ${userDetails?.data?.service_profile ? "" : "mx-auto"}`} >{children}</main>
      </div>
    </section>
   
  );
};
