import React, { useState } from "react";
import plumbingImage from "../../assets/images/google-places.png";
import locationIcon from "../../assets/svg/location.svg";
import starIcon from "../../assets/svg/Star.svg";
import { Button } from "ui/button";
import { Link} from "react-router-dom";
import { ErrorType, IFavoriteServiceData } from "types";
// import { LayoutContext } from "context";
import { LoginForm } from "pages/auth";
import { useAuthenticatedUser } from "hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { notify } from "shared";
import { addFavouriteProviderFn, removeFavouriteProviderFn } from "services";
import { FaHeart, FaPhone, FaRegHeart } from "react-icons/fa";
import { convertToMiles, maskPhoneNumber } from "shared/maskNumber";

export const GoogleServiceCard: React.FC<{ service: IFavoriteServiceData }> = ({
  service,
}) => {
  // const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userDetails } = useAuthenticatedUser();
  const [openForm, setOpenForm] = useState("");

  // const { modalOpen, openModal } = useContext(LayoutContext);

  // const getCost = (subCategory: SUB_CATEGORY[]) => {
  //   return subCategory?.map((sub) => sub?.cost);
  // };

  const { mutate: likeMutate } = useMutation({
    mutationFn: addFavouriteProviderFn,
    onSuccess: async (data) => {
      if (data?.success === true) {
        queryClient.invalidateQueries({
          queryKey: ["service-provider-profiles"],
        });
        notify.success({ message: data.message });
      }
    },
    onError: async (response: ErrorType) => {
      if (response?.response?.status === 401) {
        notify.error({
          message: "Error",
          subtitle: "Unauthenticated, Please login!",
        });
        setOpenForm("login");
        return;
      }
      notify.error({
        message: "Error",
        subtitle: response?.response?.data?.error,
      });
    },
  });

  const favouriteProvider = (id: string) => {
    likeMutate(id);
  };
  const { mutate: removeLikeMutate } = useMutation(
    {
      mutationFn: removeFavouriteProviderFn,
      onSuccess: async (data) => {
        if (data?.success === true) {
          queryClient.invalidateQueries({
            queryKey: ["service-provider-profiles"],
          });
          notify.success({ message: data.message });
        }
      },
      onError: async (response: ErrorType) => {
        notify.error({
          message: "Error ",
          subtitle: response?.data?.error,
        });
      },
    }
  );

  const removeFavouriteProvider = (id: string) => {
    removeLikeMutate(id);
  };

  return (
    <div
      style={{ width: "100%" }}
      className="flex flex-col gap-4 mb-6 lg:flex-row"
    >
      <div className="lg:w-[377px] w-[100%] h-[214] relative">
        <img
          className="rounded-[14px] lg:h-[214px] h-[250px] lg:w-[377px] w-[100%] object-contain border border-gray-300"
          src={
            service?.profile_picture ? service?.profile_picture : plumbingImage
          }
          referrerPolicy="no-referrer"
          alt=""
        />
        <div className="w-[36px] h-[36px] flex justify-center items-center bg-white absolute top-2 right-2 rounded-[10px] cursor-pointer">
          {service?.is_favorite ? (
            <FaHeart
              onClick={() => removeFavouriteProvider(service?.id)}
              className="text-red-600"
            />
          ) : (
            <FaRegHeart onClick={() => favouriteProvider(service?.id)} />
          )}
        </div>

        <div className="flex justify-center items-center  absolute top-2 left-2 rounded-[10px] cursor-pointer">
          <Button
            size={"sm"}
            variant="light-blue"
            className="bg-white text-black"
          >
            Third Party
          </Button>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div style={{ width: "100%" }} className="flex justify-between">
          <div className="flex gap-2">
            {/* <Avatar className="bg-[#D9D9D9] w-[clamp(50px,_8vw,_50px)]" /> */}
            <div className="space-y-2 w-full">
              {/* <a href={service?.profile_picture} target="_blank" rel="noopener noreferrer">
                                <img src={service?.profile_picture} alt="" className="w-[50px] h-[50px] rounded-full" />
                            </a> */}
              {/* navigate(`/provider/${service?.id}`) */}
              {/* {userDetails ?  */}
              <Link to={`/provider/${service?.id}`} className="font-semibold">
                {service?.business_name}
              </Link>
              {/* //  :                              */}
              {/* <p onClick={() => setOpenForm('login')} className='font-semibold cursor-pointer'>{service?.business_name}</p> */}
              {/* } */}
              <p
                className={` flex gap-2  text-[#64748B] text-[12px] lg:text-[16px]`}
              >
                <img src={locationIcon} alt="" />
                {service?.address || "N/A"}{" "}
                {service?.distance
                  ? `- ${convertToMiles(service?.distance)?.toFixed()}  miles away`
                  : null}{" "}
              </p>
              <a
                href={`tel:${service?.phone}`}
                className={`${!userDetails ? "pointer-events-none" : ""} text-[#64748B] text-[12px] lg:text-[16px] flex items-center gap-2`}
              >
                <FaPhone />
                {!userDetails
                  ? service?.phone
                    ? maskPhoneNumber(service?.phone)
                    : "N/A"
                  : service?.phone}
              </a>
            </div>
          </div>
          {service?.review && (
            <div className="flex items-center gap-2">
              <img src={starIcon} alt="" />
              <p>
                <span className="font-semibold text-[13px] lg:text-[16px]">
                  5.0
                </span>{" "}
                (144)
              </p>
            </div>
          )}
        </div>

        <p className="mt-4 text-[#334155]">{service?.about_me}</p>
        <a
          href={`tel:${service?.phone}`} // Replace with the actual phone number field
          className="px-4  inline-block text-center font-bold text-sm bg-blue-500 text-white rounded-sm py-2"
          onClick={(e) => {
            if (!userDetails?.data?.firstname) {
              e.preventDefault(); // Prevent the default action if not logged in
              setOpenForm("login"); // Open the login form
            }
          }}
        >
          Call Now
        </a>
      </div>
      <LoginForm
        loginPrompt="to hire this provider!"
        openLogin={openForm}
        setOpenLogin={setOpenForm}
        redirect={window.location.pathname}
      />
    </div>
  );
};
