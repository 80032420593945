/* eslint-disable camelcase */
import JobsCard from "component/oldcomponent/common/JobsCard/JobsCard";
import { SettingsLayout } from "context/settings/layout/SettingsLayout";
// import JobsCard from '/component/oldcomponent/common/JobsCard'
import { useAuthenticatedUser, useGetMyJobs } from "hooks";
import {
  useDeleteAllNotification,
  useDeleteNotification,
  useGetNotifications,
  useMarkAsRead,
} from "hooks/notifications/useNotifications";
import React from "react";
import {  useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { notify } from "shared";
import { Button } from "ui/button";

export const Listings = () => {
  const methods = useForm<any>({});
  const navigate = useNavigate();
  const { userDetails } = useAuthenticatedUser();
  const {  myJobsData } = useGetMyJobs();

  const [params, setParams] = React.useState<any>({
    is_read: "",
  });
  const { data, refetch } = useGetNotifications(params);
  const { markAsRead } = useMarkAsRead();
  const { deleteNotification } = useDeleteNotification();
  const { deleteAllNotification } = useDeleteAllNotification();

  const handleMarkNotification = async (item: any) => {
    if (item?.notification_type === "LEAD") {
      navigate(`/leads`);
    } else if (item?.notification_type === "REVIEW") {
      navigate(`/provider/${userDetails?.data?.service_profile}`);
    } else if (item?.notification_type === "CHAT") {
      navigate(`/chat`);
    }
    const response = await markAsRead({
      id: item?.id,
    });

    if (response?.success === true) {
      await refetch();
      // window.location.href = response?.data;
    } else if (response?.success === false) {
      notify.error({
        message: "Error ",
        subtitle: response?.error,
      });
    }
  };

  const handleDeleteNotification = async (item: any) => {
    const response = await deleteNotification({
      id: item?.id,
    });
    if (response?.status === 200 || response?.status === 204) {
      await refetch();
      notify.success({
        message: "Success ",
        subtitle: "Notification Deleted Successfully",
      });
    } else if (response?.status === 400) {
      notify.error({
        message: "Error ",
        subtitle: "Error Deleting Notification",
      });
    }
  };

  const handleDeleteAllNotification = async () => {
    const response = await deleteAllNotification();
    if (response?.success === true) {
      await refetch();
      notify.success({
        message: "Success ",
        subtitle: response?.message,
      });
    } else if (response?.success === false) {
      notify.error({
        message: "Error ",
        subtitle: response?.error,
      });
    }
  };

  return (
    <SettingsLayout>
      <div className="grid grid-cols-12 ">
        <div className="col-span-12 mt-12 lg:mt-6 ">
          <div className="flex justify-between items-center">
            <div className="">
              <h2 className="text-2xl font-bold">Listings</h2>
            </div>
            <button
              onClick={() => navigate("/add-listing")}
              type="button"
              className="hover:bg-gray-50 p-3  rounded text-sm font-medium text-gray-600 "
            >
              Create new Listing
            </button>
          </div>
        </div>
        {/* <div className=" border border-[#E2E8F0] px-[-30px] my-6 mx-[-25px]" /> */}
        <div className="col-span-12 grid grid-cols-12 gap-6 mt-4">
          <div className="col-span-12 sm:col-span-12 2xl:col-span-3 intro-y">
            <div className="bg-white rounded-lg border">
              {myJobsData?.data?.length === 0 && (
                <div className="flex flex-col items-center justify-center w-full h-[300px]">
                  <h4 className="text-lg font-medium text-gray-900 dark:text-white">
                    No Listings!!
                  </h4>
                  <Button
                    onClick={() => navigate("/add-listing")}
                    className="mt-4"
                  >
                    Create new Listing
                  </Button>
                </div>
              )}
              <div className="p-4 grid lg:grid-cols-3 gap-4">
                {myJobsData?.data?.map((item: any, index: any) => (
                  <JobsCard key={index} item={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
};
