import React, { useState, useRef, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Avatar } from "ui/avatar";
import { Button } from "ui/button";
import FormTextarea from "ui/formTextArea";
import { FormTextInput } from "ui/formTextInput";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { IErrorType, IGetProfileType } from "../../type";
import DisplayInputWrapper from "ui/displayInputWrapper";
import { notify } from "shared";
import ConfirmDialog from "ui/confirmDialog";
import { updateUserProfile, deleteUserProfile } from "services";
// import { SettingsLayout } from "../layout/SettingsLayout";
import PhoneInput from "react-phone-input-2";

export const UpdatePersonalProfile = () => {
  const { userDetails } = useAuthenticatedUser();
  const queryClient = useQueryClient();
  const [editPhone, setEditPhone] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(
    userDetails?.data?.profile_picture ?? null,
  );
  const [imageFile, setImageFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const profileData = [
    {
      label: "Male",
      value: "MALE",
    },
    {
      label: "Female",
      value: "FEMALE",
    },
  ];

  const methods = useForm<IGetProfileType>({
    mode: "onChange",
    defaultValues: {
      firstname: userDetails?.data?.firstname,
      lastname: userDetails?.data?.lastname,
      aboutMe: userDetails?.data?.about_me,
      phone: userDetails?.data?.phone,
      gender: userDetails?.data?.gender,
      image: userDetails?.data?.profile_picture,
      countryCode: userDetails?.data?.country_code,
      dob: userDetails?.data?.dob,
    },
    // resolver: yupResolver(profileSchema),
  });

  const { mutate: updateMutate, isPending: isFetching } = useMutation({
    mutationFn: updateUserProfile,
    onSuccess: async (data) => {
      if (data?.success === true) {
        queryClient.invalidateQueries({
          queryKey: ["user-profile"],
        });
        notify.success({ message: "user updated successfully" });
      }
    },
    onError: async (err: IErrorType) => {
      notify.error({
        message: "Error while updating user information",
        subtitle: err?.response?.data?.error || "An error occurred",
      })
    },
  });

  const onSubmit = async (payload: IGetProfileType) => {
    const formData = new FormData();
    formData.append("about_me", payload.aboutMe || '');
    formData.append("firstname", payload.firstname || '');
    formData.append("lastname", payload.lastname || '');
    formData.append("gender", payload.gender || '');
    formData.append("dob", payload.dob || '');
    formData.append("phone", payload.phone || userDetails?.data?.phone || '');
    // formData.append("country_code", payload.countryCode || '');
    // formData.append("profile_picture", selectedImage ? await fetchBlobFromUrl(selectedImage) : '');
    // if (selectedImage) {
    //   const blob = await fetchBlobFromUrl(selectedImage);
    //   formData.append("profile_picture", blob);
    // }
    if (imageFile) {
      // If a new image was selected, append the File directly
      formData.append("profile_picture", imageFile);
    } else if (selectedImage && selectedImage !== userDetails?.data?.profile_picture) {
      // If there's a selected image URL that's different from the original profile picture
      const blob = await fetchBlobFromUrl(selectedImage);
      formData.append("profile_picture", blob);
    }

    // else {
    //   formData.append("profile_picture", selectedImage || '' )
    // }
    formData.append("country_code", payload.countryCode || userDetails?.data?.country_code || '' );
    updateMutate(formData);
  };

  const fetchBlobFromUrl = async (url: string): Promise<Blob> => { 
    const response = await fetch(url);
    return await response.blob();
  };

  const { mutate, isPending } = useMutation({
    mutationFn: async () => await deleteUserProfile(),
    onSuccess: async () => {
      setShowDeleteModal(false);
      queryClient.invalidateQueries({
        queryKey: ["user-profile"],
      });
      notify.success({ message: "user deleted successfully" });
    },
    onError: async (err) => {
      setShowDeleteModal(false);
      notify.error({
        message: "Error while deleting user information",
        subtitle: err?.message,
      });
    },
  });

  const handleDeleteProfile = () => {
    mutate();
    window.location.reload();
  };

  // const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file && file.size <= 5 * 1024 * 1024) {
  //     // Check file size (5MB max)
  //     const objectUrl = URL.createObjectURL(file);
  //     setSelectedImage(objectUrl);
  //     setImageFile(file);
  //     methods.setValue("image", objectUrl);
  //   } else {
  //     notify.error({ message: "File is too large. Max size is 5MB." });
  //   }
  // };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validImageTypes.includes(file.type)) {
        notify.error({
          message:
            "Only image files with extensions jpg, jpeg, png are allowed.",
        });
        return;
      }
      if (file.size <= 5 * 1024 * 1024) {
        // Check file size (5MB max)
        const objectUrl = URL.createObjectURL(file);
        setImageFile(file); // Store file instead of URL
        setSelectedImage(objectUrl);
        methods.setValue("image", objectUrl);
      } else {
        notify.error({ message: "File is too large. Max size is 5MB." });
      }
    }
  };

  // const { userDetails } = useAuthenticatedUser();

  useEffect(() => {
    methods.setValue("aboutMe", userDetails?.data?.about_me);
  }, [userDetails?.data]);

  const handleChangePictureClick = () => {
    fileInputRef.current?.click();
  };
  return (
    // <SettingsLayout>
    <div className={`py-4 px-6 h-full flex flex-col gap-4 w-full `} >
      {/* <span className="text-sm font-bold">My Profile</span> */}
      {/* <span className="text-[#64748B] text-sm font-bold">MY PROFILE </span> */}
      {/* <span className=" text-center text-[#64748B] text-lg lg:text-xl font-bold">Edit Personal Information</span> */}
      <div className=" w-full h-full">
        <div className="flex flex-col lg:flex-row lg:flex mb-20 lg:mb-0 gap-3 w-full h-28 items-center">
          <div className="h-full flex items-end">
            <Avatar
              size={100}
              className="flex items-end text-black font-bold"
              fallbackText={`${userDetails?.data?.firstname + ' ' + userDetails?.data?.lastname}`}
              src={selectedImage ?? userDetails?.data?.profile_picture}
            />
          </div>
          <div className="flex items-end h-full gap-2">
            <Button
              size="sm"
              variant={"default"}
              onClick={handleChangePictureClick}
            >
              Change Picture
            </Button>
            <input
              type="file"
              accept="image/*"
              className="hidden"
              ref={fileInputRef}
              onChange={handleImageChange}
            />
            <Button
              variant="destructive"
              size="sm"
              onClick={() => setSelectedImage(null)}
            >
              Delete
            </Button>
          </div>
        </div>
        <div>
          <FormProvider {...methods}>
            <form
              className=""
              onSubmit={methods.handleSubmit(onSubmit)}
              encType="multipart/form-data"
            >
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
              <DisplayInputWrapper
                name="firstname"
                labelText="First Name"
                placeholder="Last Name"
                type="text"
                setValue={(str) => methods.setValue("firstname", str)}
                defaultText={userDetails?.data?.firstname}
                labelClass="font-bold"
              />
              <DisplayInputWrapper
                name="lastname"
                labelText="Last Name"
                placeholder="Last Name"
                type="text"
                setValue={(str) => methods.setValue("lastname", str)}
                defaultText={userDetails?.data?.lastname}
                labelClass="font-bold"
              />
              <DisplayInputWrapper
                name="dob"
                labelText="Date of birth"
                placeholder="date"
                type="date"
                setValue={(str) => methods.setValue("dob", str)}
                defaultText={userDetails?.data?.dob}
                labelClass="font-bold"
              />

              <div className="z-10 w-[93%]">
                <DisplayInputWrapper
                  name="gender"
                  labelText="Gender"
                  placeholder="Gender"
                  type="gender"
                  setValue={(str) => methods.setValue("gender", str)}
                  defaultText={userDetails?.data?.gender}
                  labelClass="font-bold "
                  options={profileData}
                />
              </div>

              <div className="w-auto">
                <div className="mb-2">
                  <p className="text-sm font-bold">Phone</p>
                </div>
                {editPhone ?
                <div className="relative">
                <PhoneInput
                  value={methods.watch('phone')}
                  buttonStyle={{
                    border: "none",
                    backgroundColor: "transparent",
                    borderRadius: "30px",
                  }}
                  inputStyle={{
                    border: "1px solid #E2E8F0",
                    width: "100%",
                    outline: "none",
                    borderRadius: "8px",
                    height: "42px",
                  }}
                  containerStyle={{
                    display: "flex",
                    alignItems: "center",
                    border: "none",
                    borderRadius: "30px",
                  }}
                 
                  onChange={(value, country: any,) => {
                    methods.setValue("countryCode", country?.dialCode);
                    methods.setValue("phone", value);
                  }} 
                />
                  <button
                   onClick={() => setEditPhone(!editPhone)}
                  type="button"
                  className="absolute right-[10px] top-[20%] z-20 h-6 w-6  text-black text-opacity-70 shadow-2xl "
                >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM15.8766 15.9891L14.3297 15.982L12 13.2047L9.67266 15.9797L8.12344 15.9867C8.02031 15.9867 7.93594 15.9047 7.93594 15.7992C7.93594 15.7547 7.95234 15.7125 7.98047 15.6773L11.0297 12.0445L7.98047 8.41406C7.95215 8.37971 7.93643 8.3367 7.93594 8.29219C7.93594 8.18906 8.02031 8.10469 8.12344 8.10469L9.67266 8.11172L12 10.8891L14.3273 8.11406L15.8742 8.10703C15.9773 8.10703 16.0617 8.18906 16.0617 8.29453C16.0617 8.33906 16.0453 8.38125 16.0172 8.41641L12.9727 12.0469L16.0195 15.6797C16.0477 15.7148 16.0641 15.757 16.0641 15.8016C16.0641 15.9047 15.9797 15.9891 15.8766 15.9891Z"
                        fill="#606F5C"
                      />
                    </svg>
                 
                </button>
               
                </div>
                 :
                <div className="relative">
                    <FormTextInput
                      labelText={""}
                      placeholder={"Enter Phone Number"}
                      name={"phone"}
                      type={"tel"}
                      disabled
                      value={userDetails?.data?.phone}
                    />
                   <button
          onClick={() => setEditPhone(!editPhone)}
          type="button"
          className="absolute right-[10px] top-[20%] z-20 h-6 w-6  text-black text-opacity-70 shadow-2xl "
        >
         
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="12" fill="#F3EDE1" />
              <path
                d="M16.8676 16.3156H7.66758C7.44633 16.3156 7.26758 16.4944 7.26758 16.7156V17.1656C7.26758 17.2206 7.31258 17.2656 7.36758 17.2656H17.1676C17.2226 17.2656 17.2676 17.2206 17.2676 17.1656V16.7156C17.2676 16.4944 17.0888 16.3156 16.8676 16.3156ZM9.08883 15.2656C9.11383 15.2656 9.13883 15.2631 9.16383 15.2594L11.2663 14.8906C11.2913 14.8856 11.3151 14.8744 11.3326 14.8556L16.6313 9.55687C16.6429 9.54531 16.6521 9.53157 16.6584 9.51645C16.6647 9.50133 16.6679 9.48512 16.6679 9.46875C16.6679 9.45238 16.6647 9.43617 16.6584 9.42105C16.6521 9.40593 16.6429 9.39219 16.6313 9.38063L14.5538 7.30188C14.5301 7.27813 14.4988 7.26562 14.4651 7.26562C14.4313 7.26562 14.4001 7.27813 14.3763 7.30188L9.07758 12.6006C9.05883 12.6194 9.04758 12.6419 9.04258 12.6669L8.67383 14.7694C8.66167 14.8363 8.66601 14.9053 8.68649 14.9702C8.70696 15.0351 8.74295 15.094 8.79133 15.1419C8.87383 15.2219 8.97758 15.2656 9.08883 15.2656Z"
                fill="#0A2211"
              />
            </svg>
          
        </button>
                </div>
                 }
                
                
              </div>

              {/* <DisplayInputWrapper
                name="phone"
                labelText="Phone"
                placeholder="phone"
                type="phone"
                setValue={(str) => methods.setValue("phone", str)}
                defaultText={userDetails?.data?.phone}
                labelClass="font-bold"
              /> */}

              <FormTextarea
                name="aboutMe"
                label="About Me"
                placeholder="Type here"
                labelClass="font-bold text-sm "
                rows={6}
              />
              </div>
              <div className="mt-6 flex justify-end">
              <Button
                type="submit"
                disabled={isFetching}
                className=""
                loading={isFetching}
                itemType="image/jpeg"
              >
                Update Information
              </Button>
              </div>
              
            </form>
          </FormProvider>
        </div>
      </div>
      <div className="border border-[#E2E8F0] mx-[-25px] mt-4" />

      <div className="flex flex-col gap-4 w-full pb-6">
        {/* <span className="text-sm mt-2 font-bold text-[#64748B]">
          OPTIONAL INFORMATION
        </span>
        <div className="w-full flex items-center justify-between">
          <div className="flex flex-col">
            <span className="text-base font-bold">Facebook</span>
            <span className="md:text-sm text-xs text-[#475569]">
              Sign in with Facebook and discover your trusted connections to
              buyers
            </span>
          </div>
          <div>
            <Button size="sm" variant="light-blue">
              <LinkIcon /> Connect
            </Button>
          </div>
        </div>

        <div className="w-full flex justify-between items-center">
          <div className="flex flex-col">
            <span className="text-base font-bold">Google</span>
            <span className="md:text-sm text-xs text-[#475569]">
              Sign in with Google and discover your trusted connections to
              buyers
            </span>
          </div>
          <div>
            <Button size="sm" variant="light-blue">
              <LinkIcon /> Connect
            </Button>
          </div>
        </div> */}
        {/* <div className="border border-[#E2E8F0] px-[-30px] my-3 mx-[-25px]" /> */}
        <div className="flex flex-col gap-3">
          {/* <span className="text-sm text-[#475569] font-bold">
            Are you sure you want to delete your account?
          </span> */}
          <div>
            <Button
              variant="destructive"
              size="sm"
              onClick={() => setShowDeleteModal(true)}
            >
             Delete my account
            </Button>
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <ConfirmDialog
            deleting={isPending}
            handleDelete={() => {
              handleDeleteProfile();
            }}
            confirmationMessage="Are you sure you want to delete your account?"
            showModal={showDeleteModal}
            setShowModal={setShowDeleteModal}
          />
        </div>
      )}
    </div>
    // </SettingsLayout>
   
  );
};