import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export function QueryClientProviderWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { retry: 2 },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* <ReactQueryDevtools initialIsOpen={false} position="top" /> */}
    </QueryClientProvider>
  );
}
