import { SelectOptionBtn } from "ui";
import { otherServices } from "./data";
import { useNavigate } from "react-router-dom";

export const OtherServices = () => {
  const navigate = useNavigate()
  return (
    <div className="container flex flex-col justify-center w-full gap-3 px-8 mb-8 ">
      <span className=" text-center text-[24px] font-bold">
        You might be interested in some other services
      </span>
      <div className="flex flex-wrap items-center justify-center w-full gap-3 my-4">
        {otherServices
          .slice(0, 40)
          .map((service, i) => (
            <SelectOptionBtn
              key={i}
              text={service?.name}
              className="bg-[#e6e9ed] text-black border border-[#CBD5E1]"
              onClick={()=> navigate(`search?searchTerm=${service?.value}`)}
            />
          ))}
      </div>
    </div>
  );
};
