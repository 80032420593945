import { RegistrationCard } from "@component";
import { PadLockIcon } from "assets/svg";

import React from "react";
import { FormProvider } from "react-hook-form";
import { Button } from "ui/button";
import { FormTextInput } from "ui/formTextInput";

export const AddNewPassword = ({
  openNewPassword,
  methods,
  onSubmit,
  isLoading,
  onClose,
}: // handleOpenForgetPassword,

{
  openNewPassword: boolean;
  methods: any;
  onSubmit: (v: any) => void;
  isLoading: boolean;
  onClose: (v: boolean) => void;
  // handleOpenForgetPassword: () => void;
}) => {
  // const { errors } = methods.formState;

  return (
    <RegistrationCard
      show={openNewPassword}
      // onClose={handleOpenForgetPassword}
      onClose={() => onClose(false)}
    >
      <div className="w-full flex justify-center">
        <span className="text-xl font-bold">Reset Password</span>
      </div>
      <FormProvider {...methods}>
        <form
          className="flex flex-col gap-5 mt-6 w-full"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <FormTextInput
            placeholder="Enter your password..."
            labelText="Password"
            className="w-full"
            type="password"
            icon={<img src={PadLockIcon} />}
            {...methods.register("password")}
          />

          <FormTextInput
            placeholder="Enter your password..."
            labelText="Confirm Password"
            className="w-full"
            icon={<img src={PadLockIcon} />}
            {...methods.register("passwordConfirmation")}
          />

          <Button type="submit" loading={isLoading}>
            Continue
          </Button>
        </form>
      </FormProvider>
    </RegistrationCard>
  );
};
