import axios from "axios";
import { AutoSearch } from "component/oldcomponent/HeroSection/AutoSearch";
import { useAuthenticatedUser, useUpdateServiceProviderProfile } from "hooks";
import { useGetServiceProfileById } from "hooks/profile/useGetServiceProfileById";
import { debounce } from "lodash";
import { Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { notify } from "shared";
import { Button } from "ui/button";
import FileUploader from "ui/fileUploader";
import Api from "utils/auth/api";

function UpdateBusiness() {
  const { updateServiceProviderMutation } =
    useUpdateServiceProviderProfile();
  const { userDetails } = useAuthenticatedUser();
  const { serviceProfileData, refetchServiceProfile } =
    useGetServiceProfileById(userDetails?.data?.service_profile as string);
  const [clearFiles, setClearFiles] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [edit, setEdit] = useState(false);
  const [searchResults, setSearchResults] = useState<any>([]);
  const [location, setLocation] = useState({
    geometry: { coordinates: [] },
    place_name: "",
  });
  const [searchValue, setSearchValue] = useState("");
  const handleClearFiles = () => {
    setClearFiles(true);
    setTimeout(() => setClearFiles(false), 0); // Reset the clearFiles state
  };

  const handleLocationChange = (_: any, value: any) => {
    setLocation(value);
  };

  useEffect(() => {
    // Debounce the search function to reduce API calls
    const delayedSearch = debounce(() => {
      if (searchValue.trim() !== "") {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          searchValue
        )}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}`;

        axios
          .get(url)
          .then((response: any) => {
            setSearchResults(response.data.features);
          })
          .catch((error: any) => {
            // eslint-disable-next-line no-console
            console?.error(error);
          });
      } else {
        setSearchResults([]);
      }
    }, 300);

    delayedSearch();

    // Clean up the debounce function on unmount
    return () => delayedSearch.cancel();
  }, [searchValue]);

  const methods = useForm<any>({
    mode: "onChange",
  });
  const { register, reset } = methods;

  useEffect(() => {
    if (serviceProfileData) {
      reset({
        business_name: serviceProfileData.business_name,
        about_me: serviceProfileData.about_me,
        address: serviceProfileData?.address,
        // Add other fields as needed
      });
    }
  }, [serviceProfileData, reset]);

  const handleDeleteImage = async (imageId: any) => {
    try {
       await Api.delete(`/service-image/${imageId}/`);
      await refetchServiceProfile();
      notify.success({ message: "image deleted successfully" });
    } catch (error: any) {
      notify.error({
        message: "Error while deleting",
        subtitle: error?.message,
      });
    }

    // if (res?.success == true) {
    //   await refetchServiceProfile()

    //   notify.success({ message: "image deleted successfully" });
    // }
    // if (res?.response?.data?.success == false) {
    //   notify.error({
    //     message: "Error while updating user information",
    //     subtitle: res?.response?.data?.message,
    //   })
    // }
  };

  const onFormSubmit = async (payload: any) => {
    setSubmitting(true);
    const formData = new FormData();
    formData.append("business_name", payload.business_name);
    formData.append("about_me", payload.about_me);
    if(location.geometry.coordinates[0] && location.geometry.coordinates[1]){
      formData.append("longitude", location.geometry.coordinates[0] )
      formData.append("address", location?.place_name)
      formData.append("latitude", location.geometry.coordinates[1])
    }
    if (payload?.pictures && payload.pictures.length > 0) {
      payload.pictures.forEach((item: any, index: any) => {
        formData.append(`images[${index}]`, item);
      });
    }

    const res = await updateServiceProviderMutation(formData);
    if (res?.success == true) {
      await refetchServiceProfile();
      reset({ ...payload, pictures: [] });
      handleClearFiles();
      setSubmitting(false);
      setEdit(!edit)
      notify.success({ message: "business updated successfully" });
    }
    if (res?.response?.data?.success == false) {
      notify.error({
        message: "Error while updating user information",
        subtitle: res?.response?.data?.message,
      });
      setSubmitting(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="col-span-12 2xl:col-span-9  bg-white p-4 ">
        <form
          className="grid lg:grid-cols-12 gap-6"
          onSubmit={methods.handleSubmit(onFormSubmit)}
        >
          <div className="grid lg:grid-cols-12 col-span-12  gap-6">
            <div className="col-span-12">
              <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                <div className="">
                  <label
                    htmlFor="job-title"
                    className="form-label font-semibold text-sm"
                  >
                    Business Name
                  </label>
                  <input
                    {...register("business_name")}
                    id="business_name"
                    type="text"
                    className="form-control placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50"
                    placeholder="Enter Business Name"
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="location"
                    className="form-label font-semibold text-sm"
                  >
                    Location/Zip Code{" "}
                  </label>
                  <div>
                    {edit ? (
                      <div className="relative">
                        <AutoSearch
                          searchResult={searchResults || []}
                          handleSelect={handleLocationChange}
                          getOptionLabel={(option: any) =>
                            option?.place_name as string
                          }
                          handleType={setSearchValue}
                          label={"Location/Zip Code"}
                        />
                        <button
                          onClick={() => setEdit(!edit)}
                          type="button"
                          className="absolute right-[10px] top-[25%] z-20 h-6 w-6  text-black text-opacity-70 shadow-2xl "
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM15.8766 15.9891L14.3297 15.982L12 13.2047L9.67266 15.9797L8.12344 15.9867C8.02031 15.9867 7.93594 15.9047 7.93594 15.7992C7.93594 15.7547 7.95234 15.7125 7.98047 15.6773L11.0297 12.0445L7.98047 8.41406C7.95215 8.37971 7.93643 8.3367 7.93594 8.29219C7.93594 8.18906 8.02031 8.10469 8.12344 8.10469L9.67266 8.11172L12 10.8891L14.3273 8.11406L15.8742 8.10703C15.9773 8.10703 16.0617 8.18906 16.0617 8.29453C16.0617 8.33906 16.0453 8.38125 16.0172 8.41641L12.9727 12.0469L16.0195 15.6797C16.0477 15.7148 16.0641 15.757 16.0641 15.8016C16.0641 15.9047 15.9797 15.9891 15.8766 15.9891Z"
                              fill="#606F5C"
                            />
                          </svg>
                        </button>
                      </div>
                    ) : (
                      <div className="relative">
                        <input
                          type="text"
                          disabled
                          className="form-control placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50"
                          {...register("address")}
                          name="address"
                          id="preferred_date"
                        />
                        <button
                          onClick={() => setEdit(!edit)}
                          type="button"
                          className="absolute right-[10px] top-[20%] z-20 h-6 w-6  text-black text-opacity-70 shadow-2xl "
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="12" cy="12" r="12" fill="#F3EDE1" />
                            <path
                              d="M16.8676 16.3156H7.66758C7.44633 16.3156 7.26758 16.4944 7.26758 16.7156V17.1656C7.26758 17.2206 7.31258 17.2656 7.36758 17.2656H17.1676C17.2226 17.2656 17.2676 17.2206 17.2676 17.1656V16.7156C17.2676 16.4944 17.0888 16.3156 16.8676 16.3156ZM9.08883 15.2656C9.11383 15.2656 9.13883 15.2631 9.16383 15.2594L11.2663 14.8906C11.2913 14.8856 11.3151 14.8744 11.3326 14.8556L16.6313 9.55687C16.6429 9.54531 16.6521 9.53157 16.6584 9.51645C16.6647 9.50133 16.6679 9.48512 16.6679 9.46875C16.6679 9.45238 16.6647 9.43617 16.6584 9.42105C16.6521 9.40593 16.6429 9.39219 16.6313 9.38063L14.5538 7.30188C14.5301 7.27813 14.4988 7.26562 14.4651 7.26562C14.4313 7.26562 14.4001 7.27813 14.3763 7.30188L9.07758 12.6006C9.05883 12.6194 9.04758 12.6419 9.04258 12.6669L8.67383 14.7694C8.66167 14.8363 8.66601 14.9053 8.68649 14.9702C8.70696 15.0351 8.74295 15.094 8.79133 15.1419C8.87383 15.2219 8.97758 15.2656 9.08883 15.2656Z"
                              fill="#0A2211"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12">
              <label
                htmlFor="business-description"
                className="form-label font-semibold text-sm"
              >
                Business Description
              </label>
              <textarea
                {...register("about_me")}
                id="business-description"
                rows={4}
                className="form-control placeholder:text-sm placeholder:text-[#7A7B7F] bg-slate-50"
                placeholder="Give a detailed description of what this business is about..."
              />
            </div>
          </div>
          <div className="col-span-12">
            <div>
              <span className="font-bold text-sm">
                Business Images(Upload up to 5 images)
              </span>
              <div>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
                  {serviceProfileData?.images?.map((img: any, index) => (
                    <div key={index} className="relative">
                      <img src={img.image_url} alt="" />

                      {/* // add delete icon */}
                      <button
                        type="button"
                        className="absolute top-2 right-2 bg-white p-1 rounded-full shadow-md hover:bg-red-500 hover:text-white transition-all duration-300 ease-in-out"
                        onClick={() => handleDeleteImage(img.id)}
                      >
                        <Trash2 className="size-4" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <FileUploader
                name={`pictures`}
                id={`file-input-cac`}
                snapType={""}
                isMultiple={true}
                variant="type3"
                slidesToScroll={1}
                slidesToShow={1}
                // setValue={() => {}}
                clearFiles={clearFiles}
              />
            </div>
          </div>

          <div className="col-span-12 py-5">
            <div className="flex justify-end gap-4">
              {/* disabled={loading} <Button size={"sm"} type='button' onClick={() => navigate('/listings')}  variant={"outline"}  className="btn btn-rounded-sm btn-secondary lg:px-5  ">Cancel</Button> */}
              <Button
                disabled={submitting}
                size={"sm"}
                className="btn btn-rounded-sm bg-blue-500 text-white"
              >
                Update Business
              </Button>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
}

export default UpdateBusiness;
