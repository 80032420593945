import * as yup from "yup";

//phone number validation

// const phoneRegExp = /\d{11}$/;
const phoneRegExp = /^\+?([0-9]{1,3})?\(?([0-9]{3})\)?([0-9]{3,12})$/;
export const profileSchema = yup.object().shape({
  firstname: yup.string().trim().required("First name is required "),
  lastname: yup.string().trim().required("Last name is required "),
  name: yup.string().trim().required("Business name is required "),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
  latitude: yup.number().required("Latitude is required"),
  longitude: yup.number().required("Longitude is required"),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Invalid phone number.")
    .typeError("Required"),
  address: yup.string().required('Business Address is required'),
  // country: yup.string().trim().required("Country is required"),
  // city: yup.string().trim().required("City is required"),
  // state: yup.string().trim().required("State is required"),
  // zipCode: yup.string().trim().required("Zip code is required"),
  pictures: yup.array().min(1).required("Picture is required"),
  description: yup.string().required("Add a business description").min(200, 'must be more than 200 charachters')
});
