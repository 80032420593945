import { deleteSingleJob, getJobFn, getJobsFn, getMyJobsFn } from "services/job.service";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useGetJobs = () => {
  const { isLoading, data } = useQuery({
    queryKey: ["jobs"],
    queryFn: getJobsFn,
  });

  return { isLoading, jobsData: data };
};

export const useGetJobById = (id: string) => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["single-job"],
    queryFn: () => getJobFn(id),
  });
  return { singleJobLoading: isLoading, singleJobData: data, refetch };
};

export const useGetMyJobs = () => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["myjobs"],
    queryFn: getMyJobsFn,
  });

  return { isLoading, myJobsData: data, refetch };

}

export const useDeleteJob = () => {
  const { mutateAsync } = useMutation({
    mutationFn: (params: { id: string }) => 
      deleteSingleJob(params.id),
  });

  return { deleteJob: mutateAsync };
}