import { QueryClient, useMutation } from "@tanstack/react-query";
import { createJobFn } from "services/job.service";
import { notify } from "shared";

export const useCreateJob = () => {
  const queryClient = new QueryClient();
  const { mutateAsync: createJobMutation, isPending: isCreatingJob } =
    useMutation({
      mutationFn: createJobFn,
    });

  return {
    createJobMutation,
    isCreatingJob,
  };
};
