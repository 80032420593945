import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { getServiceProviderProfile } from "services";

export const useGetServiceProfile = (props: {
  longitude : string, 
  latitude: string, 
  page: number, 
  ordering?: string, 
  min_bid?: string, 
  max_bid?: string } | null) => {
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("searchTerm") || "";
  const longitude = searchParams.get("long" || "long" || "")
  const latitude = searchParams.get("lat" || "lat" || "")
  const category = searchParams.get("category") || "";

  const { isLoading, data, refetch } = useQuery({
    queryKey: [
      "service-provider-profiles",
      {
        search: searchTerm,
        longitude: props?.longitude ,
        latitude: props?.latitude,
        category,
        page: props?.page,
        ordering: props?.ordering,
        min_bid: props?.min_bid,
        max_bid: props?.max_bid
      },
    ],
    queryFn: getServiceProviderProfile,
  });

  return { serviceProfileLoading: isLoading, serviceProfileData: data, serviceProviderRefectch: refetch };
};
