import React from 'react'
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

const NotificationBanner = ({ message, leftImageSrc, rightImageSrc }: any) => {
  return (
    <Link to="/add-listing" className="bg-blue-600 text-white rounded-full flex items-center justify-between lg:px-6 p-2 lg:py-4 w-full max-w-xl">
      <img
        alt="Illustration of a person with a headset"
        className="w-12 h-12 rounded-full"
        src={leftImageSrc}
      />
      <p className="text-center flex-1 mx-4 text-sm">{message}</p>
      <img
        alt="Illustration of a person with a headset"
        className="w-12 h-12 rounded-full"
        src={rightImageSrc}
      />
    </Link>
  );
};
NotificationBanner.propTypes = {
  message: PropTypes.string.isRequired,
  leftImageSrc: PropTypes.string.isRequired,
  rightImageSrc: PropTypes.string.isRequired,

};

NotificationBanner.defaultProps = {
  
};
export default NotificationBanner