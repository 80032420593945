import {
  Chat,
  RecentJobs,
  ServiceProfileCreation,
  ServiceRequest,
  ProductDetails,
} from "@component";

import { SearchPage, Homepage } from "pages";
import { ProfilePage } from "../pages/SettingsPage/ProfilePage";
import { Notification } from "../pages/SettingsPage/Notification";
import { Settings } from "../pages/SettingsPage/settings";
import { routes } from "routes";
import { ProfileCreationPage } from "pages/ProfileCreation";
import { Favourites } from "pages/favouritesPage";
import { routerType } from "types";
import ChatDetailPage from "component/chat/ChatDetailPage";
import { Privacy } from "pages/Privacy/Privacy";
import { Terms } from "pages/Terms/Terms";
import { Services } from "pages/SettingsPage/Services";
import { UpdateProfile } from "context/settings/profile/UpdateProfile";
import { PaymentMethod } from "pages/SettingsPage/PaymentMethods";
import { AddCard } from "pages/SettingsPage/AddCard";
import { FeaturedAds } from "../pages/SettingsPage/FeaturedAds";
import Pricing from "pages/Pricing/Pricing";
import PaymentSuccess from "pages/Pricing/PaymentSuccess";
import { Leads } from "pages/SettingsPage/Leads";
import { Listings } from "pages/SettingsPage/Listing/Listings";
import Listing from "pages/SettingsPage/Listing/Listing";
import AddListing from "pages/SettingsPage/Listing/addListing";

// import { Settings } from "lucide-react";

export const pagesData: routerType[] = [
  {
    path: routes.HOME.path,
    element: <Homepage />,
    title: routes.HOME.name,
    useAuth: false,
  },
  {
    path: routes.PRIVACY.path,
    element: <Privacy />,
    title: routes.PRIVACY.name,
    useAuth: false,
  },
  {
    path: routes.TERMS.path,
    element: <Terms />,
    title: routes.TERMS.name,
    useAuth: false,
  },
  {
    path: routes.SEARCH.path,
    element: <SearchPage />,
    title: routes.SEARCH.name,
    useAuth: false,
  },
  {
    path: routes.FAVOURITES.path,
    element: <Favourites />,
    title: routes.FAVOURITES.name,
    useAuth: true,
  },
  {
    path: routes.LISTINGS.path,
    element: <Listings />,
    title: routes.LISTINGS.name,
    useAuth: true,
  },
  {
    path: routes.LISTING.path,
    element: <Listing />,
    title: routes.LISTING.name,
    useAuth: true,
  },
  {
    path: routes.ADDLISTING.path,
    element: <AddListing />,
    title: routes.ADDLISTING.name,
    useAuth: false,
  },
  {
    path: routes.PROFILE.path,
    element: <ProfilePage />,
    title: routes.PROFILE.name,
    useAuth: true,
  },
  {
    path: routes.PROFILE.path,
    element: <ProfilePage />,
    title: routes.PROFILE.name,
    useAuth: true,
  },
  {
    path: routes.MYSERVICES.path,
    element: <Services />,
    title: routes.MYSERVICES.name,
    useAuth: true,
  },
  {
    path: routes.UPDATEPROFILE.path,
    element: <UpdateProfile />,
    title: routes.UPDATEPROFILE.name,
    useAuth: true,
  },
  {
    path: routes.LEADS.path,
    element: <Leads />,
    title: routes.LEADS.name,
    useAuth: true,
  },
  {
    path: routes.PAYMENTMETHODS.path,
    element: <PaymentMethod />,
    title: routes.PAYMENTMETHODS.name,
    useAuth: true,
  },
  {
    path: routes.FEATUREDPRICING.path,
    element: <FeaturedAds />,
    title: routes.FEATUREDPRICING.name,
    useAuth: true,
  },
  {
    path: routes.PRICING.path,
    element: <Pricing />,
    title: routes.PRICING.name,
    useAuth: false,
  },
  {
    path: routes.PAYMENTSUCCESS.path,
    element: <PaymentSuccess />,
    title: routes.PAYMENTSUCCESS.name,
    useAuth: false,
  },
  {
    path: routes.ADDCARD.path,
    element: <AddCard />,
    title: routes.ADDCARD.name,
    useAuth: true,
  },
  {
    path: routes.NOTIFICATION.path,
    element: <Notification />,
    title: routes.NOTIFICATION.name,
    useAuth: true,
  },

  // {
  //   path: routes.TASKDETAILS.path,
  //   element: <TaskDetails />,
  //   title: routes.TASKDETAILS.name,
  //   useAuth: true,
  // },
  {
    path: routes.RECENTJOBS.path,
    element: <RecentJobs />,
    title: routes.RECENTJOBS.name,
    useAuth: false,
  },
  {
    path: routes.PRODUCTDETAILS.path,
    element: <ProductDetails />,
    title: routes.PRODUCTDETAILS.name,
    useAuth: false,
  },
  {
    path: routes.SETTINGS.path,
    element: <Settings />,
    title: routes.SETTINGS.name,
    useAuth: true,
  },
  // {
  //   path: routes.PROFILECREATION.path,
  //   element: <ProfileCreationPage />,
  //   title: routes.PROFILECREATION?.name,
  //   useAuth: true,
  // // },
  // },

  {
    path: routes.PROVIDER.path,
    element: <ProfileCreationPage />,
    title: routes.PROFILECREATION.path,
    useAuth: false,
  },
  {
    path: routes.SERVICESREQUESTS.path,
    element: <ServiceRequest />,
    title: routes.SERVICESREQUESTS.name,
    useAuth: true,
  },
  {
    path: routes.SERVICEPROFILE.path,
    element: <ServiceProfileCreation />,
    title: routes.SERVICEPROFILE.name,

    useAuth: false,
  },
  {
    path: routes.CHAT.path,
    element: <Chat />,
    title: routes.CHAT.path,
    useAuth: false,
  },
  {
    path: routes.CHATCONTENT.path,
    element: <ChatDetailPage />,
    title: routes.CHAT.path,
    useAuth: true,
  },
  // {
  //   path: routes.SETTINGS.path,
  //   element: <SettingsPage />,
  //   title: routes.SETTINGS.name,
  //   useAuth: true,
  // },
  // {
  //   path: routes.SIGNUP.path,
  //   element: <SignUp />,
  //   title: routes.SIGNUP.name,
  //   useAuth: true,
  // },
  // {
  //   path: routes.LOGIN.path,
  //   element: <LoginCard />,
  //   title: routes.LOGIN.name,
  //   useAuth: true,
  // },
  // {
  //   path: routes.FORGOTPASSWORD.path,
  //   element: <ForgotPassword />,
  //   title: routes.FORGOTPASSWORD.name,
  //   useAuth: true,
  // },
  //add other paths here
];
