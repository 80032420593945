interface Route {
  name: string;
  path: string;
}

export const routes: Record<string, Route> = {
  HOME: {
    name: "home",
    path: "/",
  },
  PRIVACY: {
    name: "privacy",
    path: "/privacy",
  },
  TERMS: {
    name: "terms",
    path: "/terms",
  },
  SEARCH: {
    name: "search",
    path: "/search",
  },

  PROFILE: {
    name: "profile",
    path: "/profile",
  },
  // : {
  //   name: "profile",
  //   path: "/profile",
  // },
  MYSERVICES: {
    name: "myservice",
    path: "/myservice",
  },
  UPDATEPROFILE: {
    name: "profile",
    path: "/update-profile",
  },
  LEADS: {
    name: "leads",
    path: "/leads",
  },
  LISTINGS: {
    name: "listings",
    path: "/listings",
  },
  LISTING: {
    name: "listing",
    path: "/listing/:id",
  },
  ADDLISTING: {
    name: "add-listing",
    path: "/add-listing",
  },
  FEATUREDPRICING: {
    name: "featured_pricing",
    path: "/featured_pricing",
  },
  PRICING: {
    name: "pricing",
    path: "/pricing",
  },
  PAYMENTSUCCESS: {
    name: "payment-successful",
    path: "/payment-successful",
  },
  PROVIDER: {
    name: "provider",
    path: "/provider",
  },

  SETTINGS: {
    name: "settings",
    path: "/settings",
  },
  PAYMENTMETHODS: {
    name: "payment_methods",
    path: "/payment_methods",
  },
  ADDCARD: {
    name: "add_card",
    path: "/add_card",
  },
  NOTIFICATION: {
    name: "notification",
    path: "/notification",
  },

  TASKDETAILS: {
    name: "task-details",
    path: "/task-details",
  },
  RECENTJOBS: {
    name: "recent-jobs",
    path: "/recent-jobs",
  },
  PRODUCTDETAILS: {
    name: "product-details",
    path: "/provider/:id",
  },
  PROFILECREATION: {
    name: "profile-creation",
    path: "/profile-creation",
  },
  SERVICESREQUESTS: {
    name: "service-requests",
    path: "/service-requests",
  },
  SERVICEPROFILE: {
    name: "service-profile-creation",
    path: "/service-profile-creation",
  },
  FAVOURITES: {
    name: "favourites",
    path: "/favourite",
  },
  CHAT: {
    name: "chat",
    path: "/chat",
  },
  CHATCONTENT: {
    name: "chat",
    path: "/chat/:id",
  },
  // LOGIN: {
  //   name: "login",
  //   path: "/login",
  // },
  // SIGNUP: {
  //   name: "sign-up",
  //   path: "/sign-up",
  // },
  // FORGOTPASSWORD: {
  //   name: "forgot-password",
  //   path: "/forgot-password",
  // },
  // Add other routes here
};
