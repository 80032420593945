import Api from "utils/auth/api";

export const createQuoteFn = async (data: any) => {
  return await Api.post<any>("/quote/", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getJobFn = async () => {
  return await Api.get<any>(`/quote/`).then((res) => res.data);
};
