import React, { useEffect } from "react";
import {  useForm } from "react-hook-form";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import {  IGetProfileType } from "../type";
import { SettingsLayout } from "../layout/SettingsLayout";
import { Tabs } from "flowbite-react";
import { UpdatePersonalProfile } from "./Updates/UpdatePersonalDetails";
import UpdateBusiness from "./Updates/UpdateBusiness";

export const UpdateProfile = () => {
  const { userDetails } = useAuthenticatedUser();

  const methods = useForm<IGetProfileType>({
    mode: "onChange",
    defaultValues: {
      firstname: userDetails?.data?.firstname,
      lastname: userDetails?.data?.lastname,
      aboutMe: userDetails?.data?.about_me,
      phone: userDetails?.data?.phone,
      gender: userDetails?.data?.gender,
      image: userDetails?.data?.profile_picture,
      countryCode: userDetails?.data?.country_code,
      dob: userDetails?.data?.dob,
    },
    // resolver: yupResolver(profileSchema),
  });

  // const { mutate: updateMutate, isPending: isFetching } = useMutation({
  //   mutationFn: updateUserProfile,
  //   onSuccess: async (data) => {
  //     if (data?.success === true) {
  //       queryClient.invalidateQueries({
  //         queryKey: ["user-profile"],
  //       });
  //       notify.success({ message: "user updated successfully" });
  //     }
  //   },
  //   onError: async (err: IErrorType) => {
  //     notify.error({
  //       message: "Error while updating user information",
  //       subtitle: err?.response?.data?.error || "An error occurred",
  //     })
  //   },
  // });

  // const onSubmit = async (payload: IGetProfileType) => {
  //   const formData = new FormData();
  //   formData.append("about_me", payload.aboutMe || '');
  //   formData.append("firstname", payload.firstname || '');
  //   formData.append("lastname", payload.lastname || '');
  //   formData.append("gender", payload.gender || '');
  //   formData.append("dob", payload.dob || '');
  //   formData.append("phone", payload.phone || userDetails?.data?.phone || '');
  //   // formData.append("country_code", payload.countryCode || '');
  //   // formData.append("profile_picture", selectedImage ? await fetchBlobFromUrl(selectedImage) : '');
  //   // if (selectedImage) {
  //   //   const blob = await fetchBlobFromUrl(selectedImage);
  //   //   formData.append("profile_picture", blob);
  //   // }
  //   if (imageFile) {
  //     // If a new image was selected, append the File directly
  //     formData.append("profile_picture", imageFile);
  //   } else if (selectedImage && selectedImage !== userDetails?.data?.profile_picture) {
  //     // If there's a selected image URL that's different from the original profile picture
  //     const blob = await fetchBlobFromUrl(selectedImage);
  //     formData.append("profile_picture", blob);
  //   }

  //   // else {
  //   //   formData.append("profile_picture", selectedImage || '' )
  //   // }
  //   formData.append("country_code", payload.countryCode || userDetails?.data?.country_code || '' );
  //   updateMutate(formData);
  // };

  // const fetchBlobFromUrl = async (url: string): Promise<Blob> => { 
  //   const response = await fetch(url);
  //   return await response.blob();
  // };

  // const { mutate, isPending } = useMutation({
  //   mutationFn: async () => await deleteUserProfile(),
  //   onSuccess: async (data) => {
  //     setShowDeleteModal(false);
  //     queryClient.invalidateQueries({
  //       queryKey: ["user-profile"],
  //     });
  //     notify.success({ message: "user deleted successfully" });
  //   },
  //   onError: async (err) => {
  //     setShowDeleteModal(false);
  //     notify.error({
  //       message: "Error while deleting user information",
  //       subtitle: err?.message,
  //     });
  //   },
  // });

  // const handleDeleteProfile = () => {
  //   mutate();
  //   window.location.reload();
  // };

  // const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file && file.size <= 5 * 1024 * 1024) {
  //     // Check file size (5MB max)
  //     const objectUrl = URL.createObjectURL(file);
  //     setSelectedImage(objectUrl);
  //     setImageFile(file);
  //     methods.setValue("image", objectUrl);
  //   } else {
  //     notify.error({ message: "File is too large. Max size is 5MB." });
  //   }
  // };

  // const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file) {
  //     const validImageTypes = ["image/jpeg", "image/jpg", "image/png"];
  //     if (!validImageTypes.includes(file.type)) {
  //       notify.error({
  //         message:
  //           "Only image files with extensions jpg, jpeg, png are allowed.",
  //       });
  //       return;
  //     }
  //     if (file.size <= 5 * 1024 * 1024) {
  //       // Check file size (5MB max)
  //       const objectUrl = URL.createObjectURL(file);
  //       setImageFile(file); // Store file instead of URL
  //       setSelectedImage(objectUrl);
  //       methods.setValue("image", objectUrl);
  //     } else {
  //       notify.error({ message: "File is too large. Max size is 5MB." });
  //     }
  //   }
  // };

  // const { userDetails } = useAuthenticatedUser();

  useEffect(() => {
    methods.setValue("aboutMe", userDetails?.data?.about_me);
  }, [userDetails?.data]);

  // const handleChangePictureClick = () => {
  //   fileInputRef.current?.click();
  // };

  return (
    <SettingsLayout>
    <Tabs aria-label="Default tabs" className=" mt-6 lg:mt-3 gap-x-3 tabsContainer flex-nowrap" >
      <Tabs.Item active title="Edit Personal Profile" className="focus:ring-0">
       <UpdatePersonalProfile /> 
      </Tabs.Item>
    {userDetails?.data?.service_profile &&  <Tabs.Item  title="Update Business Profile" className="text-red-700">
        <UpdateBusiness />
      </Tabs.Item> 
      }
    </Tabs>
    </SettingsLayout>
   
  );
};