import { Footer } from "@component";
import { CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { SubscribeModal } from "context/settings/services/ServiceModals";
import {
  useGetSubscriptions,
  useSubscribePlan,
} from "hooks/subscription/useGetSubscriptions";
import {  Loader } from "lucide-react";
import Box from '@mui/material/Box';
import React, { useEffect, useRef, useState } from "react";
import { FaTimesCircle , FaCheckCircle } from "react-icons/fa";

import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { notify } from "shared";
import { getPaymentStatus } from "services/paymentApi";

function PaymentSuccess() {
  const [searchParams] = useSearchParams();
  const jwtToken = searchParams.get("jwt_token");
  const navigate = useNavigate()
  const redirect = searchParams.get("redirect")
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState<boolean | null>(null);
  const processedRef = useRef(false);

  // const handlePaymentStatus = async (jwt_token: any) => {
  //   const response = await getPaymentStatus(jwt_token);
  //   if (response?.success === true) {
  //     notify.success({
  //       message: "Payment Successful",
  //       subtitle: response?.message,
  //     });
  //     setLoading(false);
  //     setSuccess(true);
  //   } else if (response?.success === false) {
  //     notify.error({
  //       message: "Error ",
  //       subtitle: response?.error,
  //     });
  //     setLoading(false);
  //     setSuccess(false);
  //   }
  // };

  useEffect(() => {
    let mounted = true;
     const handlePaymentStatus = async (jwt_token: string | null) => {
      if (!jwt_token || processedRef.current) return;
      try {
        const response = await getPaymentStatus(jwt_token);
        if (!mounted) return;
        processedRef.current = true;


         if (response?.success === true) {
          notify.success({
            message: "Payment Successful",
            subtitle: response?.message,
          });
          setSuccess(true);
          if (redirect === 'lead') {
            navigate('/leads')
          }  
          if (redirect === 'listing') {
            navigate('/listings')
          }
        } else {
          notify.error({
            message: "Error ",
            subtitle: response?.error,
          });
          setSuccess(false);
        }
      } catch (error) {
        if (!mounted) return;
        setSuccess(false);
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };
     handlePaymentStatus(jwtToken);
     return () => {
      mounted = false;
    };
  }, [jwtToken]);

  return (
    <div>
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20"></div>
          <div className="flex flex-wrap -m-4 mx-auto">
            {/* // create a card with the payment details add large success icon */}

            <div className="flex flex-col text-center w-full mb-20">
              <div className="flex flex-wrap -m-4 justify-center">
                {loading && !success ? (
                  <div className="flex justify-center items-center ">
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  </div>
                ) : success ? (
                  <div className="p-4">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FaCheckCircle className="text-green-500 text-4xl mb-3" />
                      <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                        Payment Successful
                      </h2>
                    </Box>
                  </div>
                ) : (
                  <div className="p-4">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                       
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FaTimesCircle className="text-red-500 text-4xl mb-3" />
                      <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                        Payment Failed
                      </h2>
                    </Box>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer prop1={"string"} />
    </div>
  );
}

export default PaymentSuccess;
